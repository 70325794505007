import React, { useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import axios from 'axios';
import requests from '../../utils/stls/Request';
import { Auth } from 'aws-amplify';

// ----------------------------------------------------
// MaterialUiのSelect コンポーネント トラン区分の一覧を表示する
// ----------------------------------------------------
const SelectTransType = (
    {
        helperText = "",
        //呼出元にSelect で選択した値を返すためのイベントを定義する
        onChange = (event: any) => { onChange(event); }
    }
) => {
    //変数定義
    const [TransType, setTransType] = React.useState('');
    const [TransTypeList, setTransTypeList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    //Select のMenuItem に追加するトラン区分の一覧を作成する関数
    const getTransTypeList = TransTypeList.map((cat: any) => (
        <MenuItem value={cat.transType}>
            {cat.transType} {cat.transTypeName}
        </MenuItem>
    ))

    //Select変更時のイベント
    const handleChange = (event: SelectChangeEvent) => {
        setTransType(event.target.value);
        onChange(event);
    };

    //WEBApiをコールして、トラン区分の一覧を取得する
    //初回だけ実行したいので第二引数は[]を追加
    useEffect(() => {
        setLoading(true);
        const fetch = async () => {
            //requestを投げるURL作成
            const URLcell = process.env.REACT_APP_APIURL_STLS + requests.p01Mst001KataTransType;
            console.debug(URLcell)
            const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
            axios.get(URLcell,
                {
                    headers: {
                        "X-API-KEY": process.env.REACT_APP_APIKEY_STLS || "",
                        "Authorization": AuthToken || "",
                    },
                }).then((res) => {
                    console.debug(res.data.result)
                    setTransTypeList(res.data.result)
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    setLoading(false);
                })
        }
        fetch()
    }, [])

    // 作成したselect をリターンする
    return (
        <FormControl sx={{ minWidth: 140 }} size="small" error={!!helperText}>
            <InputLabel id="select-transType">トラン区分</InputLabel>
            <Select
                labelId="select-transType"
                id="select-transType"
                value={TransType}
                label="Zaikobasyo"
                onChange={handleChange}
                disabled={loading}
            >
                {getTransTypeList}
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
}

export default SelectTransType