import  {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import axios  from 'axios';
import { Auth } from 'aws-amplify';
import fileDownload from 'js-file-download';

// ----------------------------------------------------
// MaterialUiのButton コンポーネント ファイルダウンロードボタン
// ----------------------------------------------------
type Props = {
    apiParam: Object;
    apiUrl: string;
    apiKey: string;
    DLFileHeader?: string[];
    DLFileHeader2?: Object;
    DLFileName: string;
    buttonName: string;
}

const ButtonCsvDownload = (
    props :Props,
):any => {
    const { Parser } = require('json2csv');
    const fields = props.DLFileHeader === undefined ? props.DLFileHeader2 : props.DLFileHeader;
      
    //変数定義
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    //検索・ボタン押下時の処理 
    const handleClick = (() => {
        if (!loading) {

            setSuccess(false);
            setLoading(true);
            //apiコールする関数を呼び出す
            fetchRequest()

            setSuccess(true);
        }
    })

    //requests.p01Prg001Dataにリクエストする関数。
    const fetchRequest = async () => {
        //requestを投げるURL作成
        const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
        axios.get(props.apiUrl,
            {
                headers: {
                    "X-API-KEY": props.apiKey ,
                    "Authorization": AuthToken || "",
                },
                params: props.apiParam|| ""
            }).then((res) => {
                console.log(res.data.result)
                if (res.data.result.length === 0) {
                  alert('データはありません')
                } else {
                  // CSVファイルのダウンロード
                  const json2csvParser = new Parser({ fields, header: true, withBOM: true });
                  const csvfile = json2csvParser.parse(res.data.result);
                  fileDownload(csvfile, props.DLFileName)
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
    };

    //ボタンチェンジ関数
    const buttonSx = {
        ...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ m: 1, position: 'relative' }}>
                <Button variant="contained"
                    sx={buttonSx}
                    disabled={loading}
                    onClick={() => { handleClick(); }}
                    >
                    {props.buttonName}
                </Button>
                {loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </Box>
        </Box>
    )
}

export default ButtonCsvDownload;