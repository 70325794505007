import TextField from '@mui/material/TextField';

const FromTextField =  ({
  label = "メッセージ",
  value = "",
  helperText = "",
  readonly = false,
  onChange = (event: any) => { onChange(event); }
}) => (
  <TextField id="tfMessage"
    label={label}
    value={value}
    size="small"
    onChange={(event: any) => { onChange(event); }}
    error={!!helperText}
    helperText={helperText}
    fullWidth    
    multiline={true}
    rows={10}
    inputProps={{ readOnly: readonly }}
  />
);

export default FromTextField;