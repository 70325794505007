import React, { useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import axios from 'axios';
import requests from '../../utils/aqua/Request';
import { Auth } from 'aws-amplify';

// ----------------------------------------------------
// MaterialUiのSelect コンポーネント 投入課CDの一覧を表示する
// ----------------------------------------------------
const SelectTounyuka = (
    {
        //呼出元にSelect で選択した値を返すためのイベントを定義する
        onChange = (event: any) => { onChange(event); }
    }
) => {
    //変数定義
    const [Tounyuka, setTounyuka] = React.useState('');
    const [TounyukaList, setTounyukaList] = React.useState([]);

    //Select のMenuItem に追加する在庫場所CDの一覧を作成する関数
    const getTounyukaList = TounyukaList.map((cat: any) => (
        <MenuItem value={cat.cdTounyuka+"-"+cat.cdSeisanline}>
            {cat.cdTounyuka} - {cat.cdSeisanline} | {cat.tounyukaMei}
        </MenuItem>
    ))

    //Select変更時のイベント
    const handleChange = (event: SelectChangeEvent) => {
        setTounyuka(event.target.value);
        onChange(event);
    };

    //WEBApiをコールして、在庫場所CDの一覧を取得する
    //初回だけ実行したいので第二引数は[]を追加
    useEffect(() => {
        const fetch = async () => {
            //requestを投げるURL作成
            const URLcell = process.env.REACT_APP_APIURL_AP00447 + requests.p01Mst001CDTounyuka;
            const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
            axios.get(URLcell,
                {
                    headers: {
                        "X-API-KEY": process.env.REACT_APP_APIKEY_AP00447 || "",
                        "Authorization": AuthToken || "",
                    },
                }).then((res) => {
                    setTounyukaList(res.data.result)
                }).catch(error => {
                    console.log(error);
                })
        }
        fetch()
    }, [])

    // 作成したselect をリターンする
    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="select-zaikobasyo">投入課</InputLabel>
            <Select
                labelId="select-zaikobasyo"
                id="select-zaikobasyo"
                value={Tounyuka}
                label="Tounyuka"
                onChange={handleChange}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {getTounyukaList}
            </Select>
        </FormControl>
    );
}

export default SelectTounyuka