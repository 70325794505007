import { useState, useEffect, useContext } from 'react';
import { DataGrid, GridColDef, GridEventListener, GridActionsCellItem, GridRowParams } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import WarningIcon from '@mui/icons-material/Warning';

import requests from '../../utils/config/Request';
import { Typography } from '@mui/material';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import CommonDialog from '../../pages/config/P01Mst001Permission003';
import {AppContext} from '../../pages/TopPage';

export interface rowInterface {
    id: string;
    Code: string;
    InfoType: string;
    TargetUserServices: string;
    AllUserFlag: string;
    Title: string;
    Message: string;
    StartDate: string;
    EndDate: string;
    AttachmentFile: string;
    VisibleFlag: string;
    Expiration: string;
    Kubun: string;
    UpdateUser: string;
    UpdateUser_ja: string;
    UpdateAt: string;
}

const columns: GridColDef[] = [
    {
        field: 'detailAction',
        headerName: '',
        width: 50,
        type: 'actions', 
        getActions: (params: GridRowParams) => [
            <GridActionsCellItem
                key={params.id}
                label="edit1"
                showInMenu={false}
                icon={params.row.InfoType === 'I' ? <InfoIcon /> : <WarningIcon />}
            />,
        ]
    } as GridColDef,
    { field: 'Code', headerName: 'Code', width: 50, },
    { field: 'InfoType', headerName: '', width: 50, }, 
    { field: 'TargetUserServices', headerName: '', width: 50, },
    { field: 'AllUserFlag', headerName: '', width: 50, },
    { field: 'Title', headerName: 'タイトル', width: 500, },
    { field: 'Message', headerName: 'メッセージ', width: 200, },
    { field: 'StartDate', headerName: '表示開始日', width: 180, },
    { field: 'EndDate', headerName: '表示終了日', width: 100, },
    { field: 'AttachmentFile', headerName: '添付ファイル', width: 100, },
    { field: 'VisibleFlag', headerName: '非表示フラグ', width: 50, },
    { field: 'Expiration', headerName: '有効期限', width: 50, },
    { field: 'Kubun', headerName: '区分', width: 50, },
    { field: 'UpdateUser', headerName: '更新者ID', width: 160, },
    { field: 'UpdateUser_ja', headerName: '更新者名', width: 160, },
    { field: 'UpdateAt', headerName: '更新日時', width: 160, },
];

const InformationDataGrid = () => {
    // *************
    // 変数定義
    // *************
    //Top画面からの引継ぎ
    const {isCheckedPeriod} = useContext(AppContext);
    const {digOpen, setDigOpen} = useContext(AppContext);
    const {loading, setLoading} = useContext(AppContext);

    //検索結果を管理するuseState
    const [post, setPosts] = useState<rowInterface[]>([])

    // ダイアログに渡すデータ
    const [rowData, setRowData] = useState<rowInterface>()
    // ダイアログ用のstate
    const [digEditOpen, setEditDigOpen] = useState(false);
    useEffect(() => {},[digEditOpen])

    //画面初期表示、表示制限フラグの変更時
    useEffect(() => {
        setLoading(true)
        const fetch = async () => {
            //requestを投げるURL作成
            const URLcell = process.env.REACT_APP_APIURL_CONFIG + requests.p01Mst003Information;
            const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
            axios.get(URLcell,
            {
                headers: {
                    "X-API-KEY": process.env.REACT_APP_APIKEY_CONFIG || "",
                    "Authorization": AuthToken || "",
                },
                params: {
                    LimitFlag:  isCheckedPeriod ? 0 : 1, //1: 制限あり(デフォルト) 0: 制限なし(掲載期間を過ぎた情報も表示される)
                  },                
            }).then((res) => {
                console.log(res.data.result);
                const newList: any = res.data.result.map((postx: rowInterface) => {
                    return {
                        "id": postx.Code,
                        "Code": postx.Code,
                        "InfoType": postx.InfoType, 
                        "TargetUserServices": postx.TargetUserServices,
                        "AllUserFlag": postx.AllUserFlag,
                        "Title": postx.Title,
                        "Message": postx.Message,
                        "StartDate": postx.StartDate, "EndDate": postx.EndDate,
                        "AttachmentFile": postx.AttachmentFile, 
                        "VisibleFlag": postx.VisibleFlag,
                        "Expiration": postx.Expiration,
                        "Kubun": postx.Kubun,
                        "UpdateUser": postx.UpdateUser, 
                        "UpdateUser_ja": postx.UpdateUser_ja, "UpdateAt": postx.UpdateAt
                    }}
                  );
                  setPosts(newList)
            }).catch(error => {
                alert(error)
                console.log(error);
            }).finally(() => {
                setLoading(false);
            })
        }
        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCheckedPeriod])

    function insertResult(result : rowInterface) {
        // console.debug('insertList!')
        setDigOpen(false);
        if (result == null) return
        setPosts([...post, {...result, id: result.Code}])     
    }

    function updateResult(result : rowInterface) {
        // console.debug('updateList!')
        setEditDigOpen(false);
        if (result == null) return
        setPosts(
			post.map((dataRow) => {
				if (dataRow.Code === result.Code) {
					return {...result, id: result.Code};
				} else {
					return dataRow;
				}
			})
		);
    }

    function deleteResult(result : rowInterface) {
        // console.debug('deleteList!')
        setEditDigOpen(false);
        if (result == null) return
        setPosts(post.filter(({ Code }) => Code !== result.Code))
    }

    //詳細ダイアログを読込表示
    const handleRowClick: GridEventListener<"rowClick"> = (params) => {
        // console.debug('handleRowClick')
        setRowData(params.row)
        setEditDigOpen(true)
    }

    return (
        <div>
            <Typography variant="h6" component="h2">
                インフォメーション 
            </Typography>
            <Box sx={{ height: 450, width: '100%' }}>
                <DataGrid 
                    // 非表示項目設定
                    columnVisibilityModel={{
                        Id: false,
                        InfoType: false,
                        Code: false,
                        TargetUserServices: false,
                        AllUserFlag: false,
                        Message: false,
                        EndDate: false,
                        AttachmentFile: false,
                        VisibleFlag: false,
                        Expiration: false,
                        Kubun: false,
                        UpdateUser: false,
                        UpdateUser_ja: false,
                        UpdateAt: false,
                    }}
                    density="compact"
                    rows={post}
                    columns={columns}
                    rowsPerPageOptions={[20, 50, 100]}
                    disableSelectionOnClick={loading}
                    experimentalFeatures={{ newEditingApi: true }}
                    onRowClick={handleRowClick}
                />
            </Box>
            <CommonDialog
                open={digOpen ? digOpen : digEditOpen }
                rowParam={digOpen ? null : JSON.stringify(rowData)}
                onInsert={insertResult}
                onUpdate={updateResult} 
                onDelete={deleteResult}
                onClose={() => digOpen ? setDigOpen(false) : setEditDigOpen(false)}
            />
        </div>
    );
};

export default InformationDataGrid;

