import { Route, Routes } from 'react-router-dom';
import MstItem from '../../pages/stls/P01Mst001Buhin001';
import MstLoc from '../../pages/stls/P01Mst001Buhin002';
import MstStock from '../../pages/stls/P01Mst001Buhin003';
import MstVender from '../../pages/stls/P01Mst001Buhin004';
import MstItemprice from '../../pages/stls/P01Mst001Buhin005';
import KataKataban from '../../pages/stls/P01Mst001Kata001';
import KataColor from '../../pages/stls/P01Mst001Kata002';
import KataFactory from '../../pages/stls/P01Mst001Kata003';
import KataStock from '../../pages/stls/P01Mst001Kata004';
import KataTransaction from '../../pages/stls/P01Mst001Kata005';

export default function RoutesStls() {
  return (
    <Routes>
        <Route path="/stls/MstItem" element={<MstItem/>} />
        <Route path="/stls/MstLoc" element={<MstLoc/>} />
        <Route path="/stls/MstStock" element={<MstStock/>} />
        <Route path="/stls/MstVender" element={<MstVender/>} />
        <Route path="/stls/MstItemprice" element={<MstItemprice/>} />
        <Route path="/stls/KataKataban" element={<KataKataban/>} />
        <Route path="/stls/KataColor" element={<KataColor/>} />
        <Route path="/stls/KataFactory" element={<KataFactory/>} />
        <Route path="/stls/KataStock" element={<KataStock/>} />
        <Route path="/stls/KataTransaction" element={<KataTransaction/>} />
    </Routes>
    );
};