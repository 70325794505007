import React, { useState } from 'react';
import requests from '../../utils/aqua/Request';
import GenericTemplate from '../../templates/GenericTemplate';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import moment from 'moment';
import MUIDataTable from "mui-datatables";
import FromTextFieldTonyukanriNo from '../../components/TextField/TextFieldTonyukanriNo';
import FromTextFieldGenpinno from '../../components/TextField/TextFieldGenpinno';
import FromDesktopDatePickerTounyubiStart from '../../components/DesktopDatePicker/DesktopDatePickerTounyubiStart';
import FromDesktopDatePickerTounyubiEnd from '../../components/DesktopDatePicker/DesktopDatePickerTounyubiEnd';
import ButtonFileDownload from '../../components/ButtonFileDownload';
import SelectSetsudanCell from '../../components/Select/SelectSetsudanCell';
import RadioAcceptance from '../../components/Radio/RadioAcceptance';
import ButtonSearch from '../../components/Button/ButtonSearch';
import ButtonDialog from '../../components/Button/ButtonDialog';

const P01Prg001Kata002: React.FC = () => {
  //変数定義
  const [post, setPosts] = useState([])
  //投入日(始点)
  const [startdtbtyx, setstartDtbyt] = useState<Date | undefined>(new Date());
  //投入日(終点)
  const [enddtbtyx, setendDtbyt] = useState<Date | undefined>(new Date());
  //切断日
  const [setudandtbtyx, setsetudanDtbyt] = useState<Date | null>(null);
  //現品票番号
  const [genpinnox, setGenpinno] = useState('');
  //製造番号
  const [seibanx, setSeiban] = useState('');
  //切断セル
  const [gaityunox, setgaityuno] = useState('');
  //未受入のみ
  const [selectedValue, setSelectedValue] = React.useState('true');


  // api 用のパラメータを設定する
  const apiUrl = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Prg001Data)
  const apiKey = String(process.env.REACT_APP_APIKEY_AP00447)
  const apiParam = {
    dtbty: moment(startdtbtyx).format("YYYY-MM-DD"),
    enddtbty: moment(enddtbtyx).format("YYYY-MM-DD"),
    seudandtbty: Number(setudandtbtyx) === 0 ? null : moment(setudandtbtyx).format("YYYY-MM-DD"),
    seiban: seibanx,
    ukeirenomicheck: selectedValue,
    idgenpinno: genpinnox,
    gaityusaki: gaityunox,
  };

  const column = [
    { name: "renban", label: "No", },
    { name: "seiban", label: "投入管理No", },
    { name: "koujimei", label: "工事名", },
    { name: "dtbty", label: "投入日", },
    { name: "dtcut", label: "切断日", },
    { name: "ctcell", label: "セル", },
    { name: "idgenpinhyo", label: "現品票", },
    { name: "idgenpinedaban", label: "枝番", },
    { name: "kataban", label: "型番", },
    { name: "busu", label: "本数", },
    { name: "ukeirenitiji", label: "受入日時", },
    { name: "ukeirerackno", label: "ラックNo", },
    { name: "ukeirecode", label: "受入CD", },
  ];

  return (
    <GenericTemplate title="" >
      <Stack spacing={1} direction="column" >
        <Stack spacing={2} direction="row">
          {/*  投入予定日(スタート) */}
          <FromDesktopDatePickerTounyubiStart
            value={startdtbtyx}
            onChange={(newDtbty: any) => { setstartDtbyt(newDtbty); }}
          />
          {/*  ～ */}
          <div>
            <p style={{ textAlign: "center" }}> ～ </p>
          </div>
          {/*  投入予定日(エンド) */}
          <FromDesktopDatePickerTounyubiEnd
            value={enddtbtyx}
            onChange={(newDtbty: any) => { setendDtbyt(newDtbty); }}
          />
          {/*  切断日 */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="切断日"
              inputFormat="yyyy-MM-dd"
              value={setudandtbtyx}
              mask=""
              onChange={(setudandtbty) => {
                setsetudanDtbyt(setudandtbty);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            /> 
          </LocalizationProvider>

          </Stack>
        <Stack spacing={2} direction="row">
          <FromTextFieldTonyukanriNo onChange={(event) => setSeiban(event.target.value)} />
          <FromTextFieldGenpinno onChange={(event) => setGenpinno(event.target.value)} />
          <SelectSetsudanCell onChange={(event) => setgaityuno(event.target.value)} />
          {/* 受入ラジオボタン */}
          <RadioAcceptance setSelectedValue={setSelectedValue}  />

          <ButtonSearch
            apiParam={apiParam}
            apiUrl={apiUrl}
            apiKey={apiKey}
            getPost={setPosts}
          />
          <ButtonDialog
            buttonName="CSV出力"
            dialogUrl="/aqua/PrgHdtdataDownload"
            dialogTarget="hdtBuzaiLogOutput"
            dialogSize="top=100,left=100,width=400,height=400"
          />
        </Stack>
        <div style={{ height: '50%', width: '100%', }}>
          <MUIDataTable
            title=""
            data={post}
            columns={column}
            options={{
              selectableRows: 'none',
              rowsPerPage: 20,
              viewColumns: false,
              rowsPerPageOptions: [20, 50, 100],
              sort: false,
              print: false,
              setTableProps: () => {
                return {
                  padding: 'none'
                };
              },
            }}
          />
        </div>
        <Stack spacing={1} direction="row">
          <div style={{ flexGrow: 1 }}></div>
          <ButtonFileDownload name="ラック番号" url="/pdf/aqua/racknumber.pdf" filename="racknumber.pdf" />
          <ButtonFileDownload name="下敷き" url="/pdf/aqua/shogosheet.pdf" filename="shogosheet.pdf" />
        </Stack>
      </Stack>
    </GenericTemplate>
  )
}

export default P01Prg001Kata002