import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { Auth } from 'aws-amplify';

// ----------------------------------------------------
// MaterialUiのButton コンポーネント 検索ボタン
// ----------------------------------------------------
type Props = {
    apiParam: Object;
    apiUrl: string;
    apiKey: string;
    onClick?: (event: React.MouseEvent<HTMLInputElement>) => void
    result: any;
    name?: string
    message?: string
    disabled: boolean
}

const ButtonUpdate = (
    props: Props,
) => {

    //変数定義
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    //ボタン押下時の処理 
    const handleClick = (() => {
        if ((props.message === undefined) || (window.confirm(props.message))) {
            if (!loading) {
                setSuccess(false);
                setLoading(true);
                //apiコールする関数を呼び出す
                fetchRequest()
                setSuccess(true);
            }
        }

    })

    //postリクエストする関数。
    const fetchRequest = async () => {
        //requestを投げるURL作成
        const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
        axios.post(props.apiUrl,
            props.apiParam,
            {
                headers: {
                    "X-API-KEY": props.apiKey,
                    "Authorization": AuthToken || "",
                },
            }).then((res) => {
                //成功したら Posts に result を格納。
                if (res.data.result.length === 0) {
                    alert('データはありません')
                }else{
                    console.log(res.data.result);
                    props.result(res.data.result)
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
        };

    //ボタンチェンジ関数
    const buttonSx = {
        '&:hover': {
        bgcolor: green[500],
        },
        '&:active': {
        bgcolor: green[800],
        }
    };


    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ m: 1, position: 'relative' }}>
                <Button variant="contained"
                    sx={buttonSx}
                    disabled={(loading || props.disabled)}
                    onClick={() => { handleClick(); }}
                >
                    {props.name}
                </Button>
                {loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </Box>
        </Box>
    )
}

export default ButtonUpdate;
