export const chkHalfWidth = (chkValue: string): string => {
    if (chkValue.match(/^[\x20-\x7e]*$/)) {
        return "";
    } else {
        return "半角入力してください"
    }
}
export const chkHalfWidthKeta = (chkValue: string, chkKeta: Number, addmsg: string): string => {
    if (!(chkValue.match(/^[\x20-\x7e]*$/))) {
        return addmsg + "半角入力してください"
    }
    if (chkValue.length>chkKeta) {
        return addmsg + String(chkKeta) + "桁以下です"
    }
    return "";
}

export const chkNumber = (chkValue: string): string => {
    if (chkValue.match(/^[0-9]*$/)) {
        return "";
    } else {
        return "数値入力してください"
    }
}

export const chkEmpty = (chkValue: string): string => {
    if (chkValue.trim().length === 0) {
        return "入力してください"
    } else {
        return "";
    }
}

export const chkEmptyDate = (chkValue: Date | undefined): string => {
    if (chkValue === null) {
        return "入力してください"
    } else {
        return "";
    }
}

export const chkDate = (chkValue: Date | undefined): string => {
    if (chkValue === undefined || chkValue === null) {
        return "入力してください";
    }
    if (isNaN(chkValue.getDate())) {
        return "不正な日付です"
    } else {
        return "";
    }
}

export const chkDateNull = (chkValue: Date | undefined): string => {
    if (chkValue === undefined || chkValue === null) {
        return "";
    }
    if (isNaN(chkValue.getDate())) {
        return "不正な日付です"
    } else {
        return "";
    }
}

