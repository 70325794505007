import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import GenericTemplate from '../../templates/GenericTemplate';
import requests from '../../utils/aqua/Request';
import SelectLineCode from '../../components/Select/SelectLineCode';
import ButtonSearch from '../../components/Button/ButtonSearch';
import { P01Put001Konpo005 } from '../aqua/P01Put001Konpo005';

interface infoInterface {
  kurabi: string;
  chuken: string;
  konpsu: string;
  tmstmp: string;
  uplhzk: string;
  dataList: rowInterface[];
}
interface rowInterface {
  id: number;
  chkkon: string;
  chkbun: string;
  chkcen: string;
  chuban: string;
  kurabi: string;
  kjcode: string;
  kansua: string;
  kansu: string;
  konpsu: string;
  chokus: string;
  bskonpsu: string;
  bskurabi: string;
  saisu: string;
  hsoblk: string;
  denpno: string;
  deneda: string;
  okurij: string;
  tmstmp: string;
  dspchokus?: string;
  dspchkcen?: string;
  dspkons?: string;
  dspchkkon?: string;
  dspchkbun?: string;
  dspsaisu?: string;
  dspkura?: string;
}
const columns: GridColDef[] = [
  { field: 'dspchokus', headerName: '直', width: 60, },
  { field: 'dspchkcen', headerName: '遅延', width: 60, align: 'center', },
  { field: 'hsoblk', headerName: '方面', width: 60, },
  { field: 'chuban', headerName: '注文番号', width: 120, },
  { field: 'dspkons', headerName: '梱包数'  , width: 100, align: 'right', },
  { field: 'dspchkkon', headerName: '梱変', width: 100, align: 'center', },
  { field: 'dspchkbun', headerName: '分納', width: 100, align: 'center', },
  { field: 'dspsaisu', headerName: '才数', width: 100, align: 'right', },
  { field: 'denpno', headerName: '出伝No', width: 120, },
  { field: 'deneda', headerName: '枝', width: 60, },
  { field: 'okurij', headerName: '送状No', width: 100, },
  { field: 'dspkura', headerName: 'S1指日', width: 100, },
  { field: 'kjcode', headerName: 'KJ', width: 60, },
  { field: 'tmstmp', headerName: '更新', width: 200, },
];

const P01Put001St001: React.FC = () => {
  // *************
  // 変数定義
  // *************
  //検索結果を管理するuseState
  const [post, setPosts] = useState<infoInterface[]>([])

  // 検索条件をセットする変数
  const [lineCode, setLineCode] = useState(''); 
  const [kurabi, setKurabi] = useState(''); //倉入日
  const [chuken, setChuken] = useState(''); //件数
  const [konpsu, setKonpsu] = useState(''); //梱包数
  const [tmstmp, setTmstmp] = useState(''); //最終更新
  const [dataList, setDataList] = useState<rowInterface[]>([])
  // ダイアログ用のstate
  const [digOpen, setDigOpen] = useState(false);
  const [cyumonno, setCyumonno] = useState('');
  
  // *************
  // 定数定義
  // *************
  // api 用のパラメータを設定する
  const apiKey = String(process.env.REACT_APP_APIKEY_AP00447)
  // APIのget 時のパラメータを指定
  const apiGetUrl = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Put002KuraiCheckList)
  const apiGetParam = {};

  // lineCodeの値が変化した時
  useEffect(() => {
    if (post.length === 0) {
      setKurabi('')
      setChuken('')
      setKonpsu('')
      setTmstmp('')
      setDataList([])
    } else {
      setKurabi(post[0].kurabi)
      setChuken(post[0].chuken)
      setKonpsu(post[0].konpsu)
      setTmstmp(post[0].tmstmp)
      if (post[0].dataList === undefined || post[0].dataList.length === 0){
        setDataList([])
        alert('データはありません')
      } else {
        setDataList(
          post[0].dataList.map((dataRow: rowInterface, index: number) => {
            return {...dataRow, 
              id: index + 1,
              dspchokus: dataRow.chokus === "1" ? "直送" : "",
              dspchkcen: dataRow.chkcen !== "0" ? dataRow.bskurabi === "00000000" ? "？" : "◎" : "",
              dspkons: dataRow.konpsu !== dataRow.kansu ? dataRow.kansu + " / " + dataRow.konpsu : dataRow.kansu,
              dspchkkon: dataRow.chkkon !== "0" && Number(dataRow.bskonpsu) > 0 ? dataRow.bskonpsu + "→" + dataRow.konpsu : "",
              dspchkbun: dataRow.chkbun !== "0" ? "残 " + (Number(dataRow.konpsu) - Number(dataRow.kansua)) : "", 
              dspsaisu: dataRow.saisu === null || Number(dataRow.saisu) === 0 ? "" : dataRow.saisu,
              dspkura: dataRow.kurabi !== dataRow.bskurabi ? dataRow.bskurabi === "00000000" ? "ナシ" : dataRow.bskurabi : "",
            };
          })
        )
        console.debug(dataList)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport               
          csvOptions={{
          utf8WithBom: true,
        }}
      />
      </GridToolbarContainer>
    )
  }

  return (
    <GenericTemplate title="倉入チェックリスト" >
      <Stack spacing={1} direction="column" >
        <Stack spacing={2} direction="row">
          <SelectLineCode
            allFlg={true}
            callerPath={requests.p01Put001CDLineChecklist}
            onChange={(event) => setLineCode(event.target.value)} 
          />
          {/* 検索・ボタン */}
          <ButtonSearch
            apiParam={apiGetParam}
            apiUrl={apiGetUrl.replace('{cdline}', lineCode)}
            apiKey={apiKey}
            getPost={setPosts}
            disabled={lineCode===""}
          />
        </Stack>

        <Stack spacing={2} direction="row">
          <div>倉入日：{kurabi}</div>
          <div>件数：{chuken}</div>
          <div>梱包数：{konpsu}</div>
          <div>最終更新：{tmstmp}</div>
        </Stack>
        {/* DataGrid */}
        <div style={{ height: '100%', width: '100%', }}>
          <Box sx={{ height: 600, width: '100%' }}>
            <DataGrid
              density="compact"
              rows={dataList} 
              columns={columns}
              rowsPerPageOptions={[20, 50, 100]}
              components={{
                Toolbar: CustomToolbar
              }}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              onRowClick={(selRow) => {
                // console.log(selRow)
                setCyumonno(selRow.row.chuban)
                setDigOpen(true)
              }}
              showCellRightBorder
              />
          </Box>
        </div>
      </Stack>
      <P01Put001Konpo005
        open={digOpen}
        rowParam={cyumonno}
        onClose={() => setDigOpen(false)}
      />
    </GenericTemplate>
  )
}

export default P01Put001St001