import React, { useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import requests from '../../utils/stls/Request';
import GenericTemplate from '../../templates/GenericTemplate';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import MUIDataTable from "mui-datatables";
import FromTextFieldColor from '../../components/TextField/TextFieldColor';
import { chkHalfWidth, chkEmpty } from '../../common/chkTextValue';
import {Auth} from 'aws-amplify';
import moment from 'moment';

const P01Prg002Kata001: React.FC = () => {
  //変数定義
  const [post, setPosts] = useState([])
  //色コード
  const [color, setColor] = useState('');
  const [colorError, setColorError] = useState('');

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  //requests.p01Mst001KataColorにリクエストする関数。
  const fetchRequest = async() => {
    setPosts([])

    //requestを投げるURL作成
    const URL = process.env.REACT_APP_APIURL_STLS + requests.p01Mst001KataColor + '/' + color
    const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    axios.get(URL,
      {
        headers: {
          "X-API-KEY": process.env.REACT_APP_APIKEY_STLS || "",
          "Authorization": AuthToken || "",
        },
      }).then((res) => {
        //成功したら Posts に result を格納。
        setPosts(res.data.result)
        if (res.data.result.length === 0) {
          alert('データはありません')
        }
        console.debug(res.data.result)
      }).catch(error => {
        console.log(error);
        alert('検索に失敗しました')
      }).finally(() => {
        setLoading(false);
      });
  };

  //検索・ボタン押下時の処理 
  const handleClick = (() => {
    let errMsg = ""
    //未入力チェック
    errMsg = chkEmpty(color)
    if (errMsg.length > 0) {
      setColorError(errMsg)
      alert('検索条件を入力してください');
      return;
    }
    //半角文字チェック 
    errMsg = chkHalfWidth(color)
    if (errMsg.length > 0) {
      setColorError(errMsg)
      alert(errMsg);
      return;
    }

    if (!loading) {
      setSuccess(false);
      setLoading(true);
      //apiコールする関数を呼び出す
      fetchRequest()
      setSuccess(true);
    }
  })

  //入力値チェック処理：色コード
  const chkColor = (event: any) => {
    let val = event.target.value
    setColorError(chkHalfWidth(val))
    setColor(val)
  }

  //日付をスラッシュ編集
  function dateEditDisplay(val: string) {
    return val.length === 8 ? moment(val).format("YYYY/MM/DD") : val;
  }
  
  const column = [
    { name: "color", label: "色コード",
      options: {
        setCellProps: () => ({ style: { position: "sticky", left: 0, background: "white", zIndex: 120 } }),
        setCellHeaderProps: () => ({ style: { position: "sticky", left: 0, background: "white", zIndex: 121 } })
      }
    },
    { name: "color_name", label: "色名称", },
    { name: "old_color_tomikei", label: "旧色コード富軽", },
    { name: "old_color_tomikei_fabric", label: "旧色コード富軽生地", },
    { name: "secondary_mark", label: "2次処理マーク", },
    { name: "base_color", label: "下地色", },
    { name: "coating_place_01", label: "皮膜場所01", },
    { name: "coating_place_02", label: "皮膜場所02", },
    { name: "coating_place_03", label: "皮膜場所03", },
    { name: "coating_place_04", label: "皮膜場所04", },
    { name: "coating_place_05", label: "皮膜場所05", },
    { name: "coating_place_06", label: "皮膜場所06", },
    { name: "coating_place_07", label: "皮膜場所07", },
    { name: "coating_place_08", label: "皮膜場所08", },
    { name: "coating_place_09", label: "皮膜場所09", },
    { name: "coating_place_10", label: "皮膜場所10", },
    { name: "create_date", label: "作成日",  
      options: {
        customBodyRender:(cellValue: string) => {return dateEditDisplay(cellValue)}
      }
    },
    { name: "create_user", label: "作成者", },
    { name: "update_date", label: "更新日",  
      options: {
        customBodyRender:(cellValue: string) => {return dateEditDisplay(cellValue)}
      }
    },
    { name: "update_user", label: "更新者", },
  ];

  return (
    <GenericTemplate title="">
      <Stack spacing={1} direction="column" >
        <Stack spacing={2} direction="row">
          {/* 色コード */}
          <FromTextFieldColor onChange={chkColor}
            helperText={colorError}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ m: 1, position: 'relative' }}>
              <Button variant="contained"
                sx={buttonSx}
                disabled={loading}
                onClick={() => { handleClick(); }}>
                検索
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
          </Box>
        </Stack>
        <div style={{ height: '50%', width: '100%' }}>
          <MUIDataTable
            title="色コード(stls)"
            data={post}
            columns={column}
            options={{
              selectableRows: 'none',
              rowsPerPage: 20,
              viewColumns: false,
              rowsPerPageOptions: [20, 50, 100],
              sort: false,
              print: false,
              setTableProps: () => {
                return {
                  // padding: 'none',
                  size: "small",
                  style: { whiteSpace: "nowrap" },
                };
              },
            }}
          />
        </div>
      </Stack>
    </GenericTemplate>
  )
}

export default P01Prg002Kata001