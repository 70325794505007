import React, {  useState, useRef } from 'react';
import GenericTemplate from '../../templates/GenericTemplate';
import Stack from '@mui/material/Stack';
import ReactToPrint from 'react-to-print';
import requests from '../../utils/aqua/Request';
import Button from '@mui/material/Button';
import moment from 'moment';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import FromDesktopDatePickerTounyubi from '../../components/DesktopDatePicker/DesktopDatePickerTounyubi';
import FromTextFieldTonyukanriNo from '../../components/TextField/TextFieldTonyukanriNo';
import FromTextFieldSyusekiNo from '../../components/TextField/TextFieldSyusekiNo';
import FromTextFieldKoujimei from '../../components/TextField/TextFieldKoujimei';
import SelectKumitateKahan from '../../components/Select/SelectKumitatekahan';
import TableContainer from '@mui/material/TableContainer';
import MUIDataTable from "mui-datatables";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ButtonSearch from '../../components/Button/ButtonSearch';

const P01Prg001Kata004: React.FC = () => {

  //変数定義
  //投入日
  const [startdtbtyx, setstartDtbyt] = useState<Date | undefined>(new Date());
  //投入管理No
  const [seibanx, setSeiban] = useState('');
  //集積No
  const [shusekiNox, setShusekiNo] = useState('');
  //reacttoprintのコンポーネント
  const componentRef = useRef(null);
  //組立課班をAPIにpostする変数
  const [kahanx, setkahan] = useState('');
  //集積No
  const [koujimeix, setKoujimei] = useState('');
  //出荷完了予定日
  const [dtbshkyx, setdtbshky] = useState<Date | null>(null);
  //getメソッドより受け取る変数
  const [post, setPosts] = useState<postArr[]>([]);

  //getメソッドより受け取る変数の型定義
  type postArr = {
    dtbty: string,
    seiban: string,
    buzaiarray: buzaiarraytype[],
    dtbshky: string,
    koujiname: string,
    kumitatekahan: string,
    shusekino: string,
  };

  type buzaiarraytype = {
    ctcell: string,
    genpinno: string,
    hyoiro: string,
    kataban: string,
    pn: string,
    saisetu: string,
    sunpo: string,
    ukeirerackno: string,
    yousetu: string,
  };

  // api 用のパラメータを設定する
  const apiUrl = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Prg001senbetufusoku)
  const apiKey = String(process.env.REACT_APP_APIKEY_AP00447)
  const apiParam = {
    dtbty: moment(startdtbtyx).format("YYYY-MM-DD"),
    tonyukanrino: seibanx,
    shusekino: shusekiNox,
    kumitatekahan: kahanx,
    koujimei: koujimeix,
    dtbshky: Number(dtbshkyx) === 0 ? null : moment(dtbshkyx).format("YYYY-MM-DD")
  };

  const column = [
    { name: "pn", label: "PN", },
    { name: "genpinno", label: "現品票No", },
    { name: "kataban", label: "形番", },
    { name: "sunpo", label: "部材長", },
    { name: "yousetu", label: "溶接", },
    { name: "saisetu", label: "再切", },
    { name: "hyoiro", label: "色", },
    { name: "ctcell", label: "切断セル", },
    { name: "ukeirerackno", label: "ラックNo", },
  ];

  class ComponentToPrint extends React.Component {
    render() {
      //印刷日を取得
      const now = new Date();
      const printdate = (now.getFullYear() + "年" + (now.getMonth() + 1) + "月" + now.getDate() + "日");
      return (
        <div className='pdf_page'>
          {post.map((text, index) =>
            <div className='pdf_page'>
              <Grid container direction="column" alignItems="center">
                <Grid item xs={12}>
                  選別不足材
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  投入日　　：{moment(startdtbtyx).format("YYYY-MM-DD")}
                </Grid>
                <Grid item xs={4}>
                  組立課・班：{text.kumitatekahan}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  投入管理No：{text.seiban}
                </Grid>
                <Grid item xs={8}>
                  工事名　　：{text.koujiname}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  集積No　　：{text.shusekino}
                </Grid>
                <Grid item xs={8}>
                  出荷予定日：{text.dtbshky}
                </Grid>
              </Grid>

              {/* テーブル項目名 */}
              <div className='table_line'>
                <div className='block_number'>No</div>
                <div className='block_pn'>PN</div>
                <div className='block_genpinno'>現品票No</div>
                <div className='block_kataban'>型番</div>
                <div className='block_sunpo'>部材長</div>
                <div className='block_yousetu'>溶接</div>
                <div className='block_saisetu'>再切</div>
                <div className='block_hyoiro'>色</div>
                <div className='block_ctcell'>切断セル</div>
                <div className='block_ukeirerackno'>ラックNo</div>
              </div>
              {text.buzaiarray.map((data, index) =>
                <div>
                  {/* 1ページ目の改ページ */}
                  {index !== 0 && index === 42 &&
                    <div className='pdf_next_page_t'>
                      <div className='pdf_page_kai'></div>
                      <div className='table_line'>
                        <div className='block_number'>No</div>
                        <div className='block_pn'>PN</div>
                        <div className='block_genpinno'>現品票No</div>
                        <div className='block_kataban'>型番</div>
                        <div className='block_sunpo'>部材長</div>
                        <div className='block_yousetu'>溶接</div>
                        <div className='block_saisetu'>再切</div>
                        <div className='block_hyoiro'>色</div>
                        <div className='block_ctcell'>切断セル</div>
                        <div className='block_ukeirerackno'>ラックNo</div>
                      </div>
                    </div>
                  }
                  {/* 2ページ目の改ページ */}
                  {index === 88 &&
                    <div className='pdf_page_kai'>
                      <div className='pdf_next_page_t'></div>
                      <div className='table_line'>
                        <div className='block_number'>No</div>
                        <div className='block_pn'>PN</div>
                        <div className='block_genpinno'>現品票No</div>
                        <div className='block_kataban'>型番</div>
                        <div className='block_sunpo'>部材長</div>
                        <div className='block_yousetu'>溶接</div>
                        <div className='block_saisetu'>再切</div>
                        <div className='block_hyoiro'>色</div>
                        <div className='block_ctcell'>切断セル</div>
                        <div className='block_ukeirerackno'>ラックNo</div>
                      </div>
                    </div>
                  }
                  {/* 3ページ目以降の改ページ */}
                  {index > 93 && index % 45 === 0 &&
                    <div className='pdf_page_kai'>
                      <div className='pdf_next_page_t'></div>
                      <div className='table_line'>
                        <div className='block_number'>No</div>
                        <div className='block_pn'>PN</div>
                        <div className='block_genpinno'>現品票No</div>
                        <div className='block_kataban'>型番</div>
                        <div className='block_sunpo'>部材長</div>
                        <div className='block_yousetu'>溶接</div>
                        <div className='block_saisetu'>再切</div>
                        <div className='block_hyoiro'>色</div>
                        <div className='block_ctcell'>切断セル</div>
                        <div className='block_ukeirerackno'>ラックNo</div>
                      </div>
                    </div>
                  }
                  <div className='block_number'>{index + 1}</div>
                  <div className='block_pn'>{data.pn}</div>
                  <div className='block_genpinno'>{data.genpinno}</div>
                  <div className='block_kataban'>{data.kataban}</div>
                  <div className='block_sunpo'>{data.sunpo}</div>
                  <div className='block_yousetu'>{data.yousetu}</div>
                  <div className='block_saisetu'>{data.saisetu}</div>
                  <div className='block_hyoiro'>{data.hyoiro}</div>
                  <div className='block_ctcell'>{data.ctcell}</div>
                  <div className='block_ukeirerackno'>{data.ukeirerackno}</div>
                </div>
              )}
              <div className='pdf_pagecount_footer'>ページ数：{index + 1 + '/' + post.length}</div>
              <div className='pdf_id_footer'>ap00447-aa03</div>
              <div className='pdf_printdate_footer'>印刷日：{printdate}</div>
              <div className='pdf_next_page'></div>
            </div>
          )}
          <style>
            {`
              .pdf_page {
                size: A4;
                position: relative;
                padding: 10px;
                font-family: MS Gothic;
              }
              .pdf_page_kai {
                padding-top: 20px;
              }
            	.table_line {
                display: table;
                width: 750px;
              }
              .block_number {
                display: table-cell;
                border-bottom: 1px solid;
                width: 40px;
                text-align: right;
                font-size: 14px;
              }
              .block_pn {
                display: table-cell;
                border-bottom: 1px solid;
                width: 40px;
                text-align: right;
                font-size: 14px;
              }
              .block_genpinno {
                display: table-cell;
                border-bottom: 1px solid;
                width: 100px;
                text-align: right;
                font-size: 14px;
              }
              .block_kataban {
                display: table-cell;
                border-bottom: 1px solid;
                width: 90px;
                text-align: right;
                font-size: 14px;
              }
              .block_sunpo {
                display: table-cell;
                border-bottom: 1px solid;
                width: 90px;
                text-align: right;
                font-size: 14px;
              }
              .block_yousetu {
                display: table-cell;
                border-bottom: 1px solid;
                width: 90px;
                text-align: right;
                font-size: 14px;
              }
              .block_saisetu {
                display: table-cell;
                border-bottom: 1px solid;
                width: 50px;
                text-align: right;
                font-size: 14px;
              }
              .block_hyoiro {
                display: table-cell;
                border-bottom: 1px solid;
                width: 50px;
                text-align: right;
                font-size: 14px;
              }
              .block_ctcell {
                display: table-cell;
                border-bottom: 1px solid;
                width: 100px;
                text-align: right;
                font-size: 14px;
              }
              .block_ukeirerackno {
                display: table-cell;
                border-bottom: 1px solid;
                width: 100px;
                text-align: right;
                font-size: 14px;
              }
              .pdf_next_page {
                break-before: page;
                border: none;
              }
              .pdf_next_page_t {
                break-before: page;
              }
              .pdf_next_page_last {
                break-before: auto;
                break-after: auto;
              }
              .pdf_footer {
                position: absolute;
                bottom: 0px;
              }
              .pdf_pagecount_footer {
                fontsize: 50px;
                text-align: center;
                position: absolute;
                bottom: 25px;              
              }
              .pdf_id_footer {
                fontsize: 50px;
                text-align: center;
                position: absolute;
                bottom: 25px;
                left: 320px;
              }
              .pdf_printdate_footer {
                fontsize: 50px;
                text-align: center;
                position: absolute;
                bottom: 25px;
                left: 590px;
              }
            `}
          </style>
        </div>
      );
    }
  }

  return (
    <GenericTemplate title="" >
      <Stack spacing={1} direction="column" >
        <Stack spacing={2} direction="row">
          {/*  投入予定日 */}
          <FromDesktopDatePickerTounyubi
            value={startdtbtyx}
            onChange={(newDtbty: any) => { setstartDtbyt(newDtbty); }}
          />
          {/*  製造番号 */}
          <FromTextFieldTonyukanriNo onChange={(event) => setSeiban(event.target.value)} />
          {/*  集積No */}
          <FromTextFieldSyusekiNo onChange={(event) => setShusekiNo(event.target.value)} />
        </Stack>
        <Stack spacing={2} direction="row">
          {/*  組立課班 */}
          <SelectKumitateKahan onChange={(event) => setkahan(event.target.value)} />
          {/*  工事名 */}
          <FromTextFieldKoujimei onChange={(event) => setKoujimei(event.target.value)} />
          {/*  出荷完了予定日 */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="出荷完了予定日"
              inputFormat="yyyy-MM-dd"
              value={dtbshkyx}
              mask=""
              onChange={(setudandtbty) => {
                setdtbshky(setudandtbty);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
          {/* 羂索・ボタン */}
          <ButtonSearch
            apiParam={apiParam}
            apiUrl={apiUrl}
            apiKey={apiKey}
            getPost={setPosts}
          />
          <ReactToPrint
            trigger={() => (
              <Button>
                印刷
              </Button>
            )}
            content={() => componentRef.current}
            documentTitle={'選別不足材'}
          />
          <div style={{ display: 'none' }}>
            <ComponentToPrint ref={componentRef} />
          </div>
        </Stack>
      </Stack>
      <Container maxWidth='md'>
        <Card>
          {post.map((text) =>
            <CardContent sx={{ margin: 1, border: 1 }} >
              <div style={{ textAlign: 'left' }}>
                <Grid container>
                  <Grid item xs={4}>
                    投入日　 　：{moment(startdtbtyx).format("YYYY-MM-DD")}
                  </Grid>
                  <Grid item xs={4}>
                    組立課・班：{text.kumitatekahan}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4}>
                    投入管理No：{text.seiban}
                  </Grid>
                  <Grid item xs={8}>
                    工事名　　：{text.koujiname}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4}>
                    集積No　　：{text.shusekino}
                  </Grid>
                  <Grid item xs={4}>
                    出荷予定日：{text.dtbshky}
                  </Grid>
                </Grid>
              </div>
              <TableContainer component={Paper}>
                <div style={{ height: '50%', width: '100%', }}>
                  <MUIDataTable
                    title=""
                    data={text.buzaiarray}
                    columns={column}
                    options={{
                      selectableRows: 'none',
                      rowsPerPage: 20,
                      viewColumns: false,
                      rowsPerPageOptions: [20, 50, 100],
                      sort: false,
                      print: false,
                      download: false,
                      setTableProps: () => {
                        return {
                          padding: 'none'
                        };
                      },
                    }}
                  />
                </div>
              </TableContainer>
            </CardContent>
          )}
        </Card>
      </Container>
    </GenericTemplate>
  )
}

export default P01Prg001Kata004