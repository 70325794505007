import { useState, useEffect } from 'react';
import { DataGrid, GridColDef, } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { gj10Interface } from '../../pages/aqua/P01Put001Konpo005';

type Props = {
    dataList: gj10Interface[]
}

const columns: GridColDef[] = [
    { field: 'hinmei', headerName: '品名', width: 200, },
    { field: 'iro', headerName: '色', width: 80, },
    { field: 'setsu', headerName: 'セット数', width: 80, align: 'right', },
    { field: 'kihntp', headerName: '基本タイプ', width: 100, },
    { field: 'series', headerName: 'シリーズ', width: 100, },
    { field: 'madsy1', headerName: '窓種１', width: 60, },
    { field: 'madkt1', headerName: '窓型１', width: 60, },
    { field: 'katte1', headerName: '勝手１', width: 60, },
    { field: 'maguc1', headerName: '間口１', width: 60, },
    { field: 'mesia1', headerName: '召合１', width: 60, },
    { field: 'nakbn1', headerName: '中骨１', width: 60, },
    { field: 'sitwk1', headerName: '下枠１', width: 60, },
    { field: 'yobi01', headerName: '予備１', width: 60, },
    { field: 'sonot1', headerName: '其他１', width: 60, },
    { field: 'madsy2', headerName: '窓種２', width: 60, },
    { field: 'madkt2', headerName: '窓型２', width: 60, },
    { field: 'katte2', headerName: '勝手２', width: 60, },
    { field: 'maguc2', headerName: '間口２', width: 60, },
    { field: 'mesia2', headerName: '召合２', width: 60, },
    { field: 'nakbn2', headerName: '中骨２', width: 60, },
    { field: 'sitwk2', headerName: '下枠２', width: 60, },
    { field: 'yobi02', headerName: '予備２', width: 60, },
    { field: 'sonot2', headerName: '其他２', width: 60, },
    { field: 'madsy3', headerName: '窓種３', width: 60, },
    { field: 'madkt3', headerName: '窓型３', width: 60, },
    { field: 'katte3', headerName: '勝手３', width: 60, },
    { field: 'maguc3', headerName: '間口３', width: 60, },
    { field: 'mesia3', headerName: '召合３', width: 60, },
    { field: 'nakbn3', headerName: '中骨３', width: 60, },
    { field: 'sitwk3', headerName: '下枠３', width: 60, },
    { field: 'yobi03', headerName: '予備３', width: 60, },
    { field: 'sonot3', headerName: '其他３', width: 60, },
    { field: 'mumea', headerName: '無目Ａ', width: 60, },
    { field: 'mumeb', headerName: '無目Ｂ', width: 60, },
    { field: 'kozknm', headerName: '工図完了', width: 100, },
    { field: 'kozhem', headerName: '工図変更', width: 100, },
    { field: 'koztan', headerName: '工図担当', width: 100, },
];

const DataGridPutGj10 = (props: Props,) => {
    // *************
    // 変数定義
    // *************
    //検索結果を管理するuseState
    const [post, setPosts] = useState<gj10Interface[]>([])

    //画面初期表示
    useEffect(() => {
        setPosts(
            props.dataList.map((dataRow: gj10Interface) => {
				return {...dataRow, 
                    kozknm: (dataRow.kozknm === null ? '' : dataRow.kozknm) + ' ' + (dataRow.kozknb === null ? '' : dataRow.kozknb),
                    kozhem: (dataRow.kozhem === null ? '' : dataRow.kozhem) + ' ' + (dataRow.kozhnb === null ? '' : dataRow.kozhnb)
                    };
			})
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <Typography variant="h6" component="h2">
                【ＧＪ１０】
            </Typography>
            <Box sx={{ height: 100, width: '100%' }}>
                <DataGrid 
                    density="compact"
                    rows={post}
                    columns={columns}
                    hideFooter={true}
                    experimentalFeatures={{ newEditingApi: true }}
                    showCellRightBorder
                />
            </Box>
        </div>
    );
};

export default DataGridPutGj10;

