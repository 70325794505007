import React, { useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import requests from '../../utils/stls/Request';
import GenericTemplate from '../../templates/GenericTemplate';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import MUIDataTable from "mui-datatables";
import FromTextFieldZaiko from '../../components/TextField/TextFieldKojoCd';
import FromTextFieldHinmokuNo from '../../components/TextField/TextFieldHinmokuCd';
import FromTextFieldSuryo from '../../components/TextField/TextFieldSuryo';
import { chkHalfWidth, chkNumber, chkEmpty } from '../../common/chkTextValue';
import {Auth} from 'aws-amplify';

const P01Prg001Kata003: React.FC = () => {
  //変数定義
  const [post, setPosts] = useState([])
  //在庫場所
  const [zaiko, setZaiko] = useState('');
  const [zaikoError, setZaikoError] = useState("");
  //品目番号
  const [hinban, setHinban] = useState('');
  const [hinbanError, setHinbanError] = useState('');
  //現在庫
  const [genZaiko, setGenZaiko] = useState('');
  const [genZaikoError, setGenZaikoError] = useState('');

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  //requests.p01Prg001Dataにリクエストする関数。
  const fetchRequest = async() => {
    setPosts([])

    //requestを投げるURL作成
    const URL = process.env.REACT_APP_APIURL_STLS + requests.p01Mst001Stock + '/' + zaiko
    const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    axios.get(URL,
      {
        headers: {
          "X-API-KEY": process.env.REACT_APP_APIKEY_STLS || "",
          "Authorization": AuthToken || "",
        },
        params: {
          item: hinban,           //品目番号
          qty_on_hand: genZaiko,  //現在庫
        }
      }).then((res) => {
        //成功したら Posts に result を格納。
        setPosts(res.data.result)
        if (res.data.result.length === 0) {
          alert('データはありません')
        }
        console.debug(res.data.result)
      }).catch(error => {
        console.log(error);
        alert('検索に失敗しました')
      }).finally(() => {
        setLoading(false);
      });
  };

  //検索・ボタン押下時の処理 
  const handleClick = (() => {
    let dispErrMsg = ""
    //未入力チェック
    let errMsg1 = chkEmpty(zaiko)
    let errMsg2 = chkEmpty(hinban)
    if (errMsg1.length > 0 && errMsg2.length > 0) {
      setZaikoError(errMsg1)
      setHinbanError(errMsg2)
      dispErrMsg = '検索条件を入力してください'

    } else if (errMsg1.length === 0 && errMsg2.length > 0) {
      //半角文字チェック 
      let errMsg = chkHalfWidth(zaiko)
      setZaikoError(errMsg)
      setHinbanError('')
      dispErrMsg = dispErrMsg.length > 0 ? dispErrMsg : errMsg

    } else if (errMsg1.length > 0 && errMsg2.length === 0) {
      //半角文字チェック 
      let errMsg = chkHalfWidth(hinban)
      setZaikoError('')
      setHinbanError(errMsg)
      dispErrMsg = dispErrMsg.length > 0 ? dispErrMsg : errMsg

    }

    //数値チェック 
    let errMsg = chkNumber(genZaiko)
    setGenZaikoError(errMsg)
    dispErrMsg = dispErrMsg.length > 0 ? dispErrMsg : errMsg

    if (dispErrMsg.length > 0) {
      alert(dispErrMsg);
      return;
    }

    if (!loading) {
      setSuccess(false);
      setLoading(true);
      //apiコールする関数を呼び出す
      fetchRequest()
      setSuccess(true);
    }
  })

  //入力値チェック処理：在庫場所
  const chkZaiko = (event: any) => {
    let val = event.target.value
    setZaikoError(chkHalfWidth(val))
    setZaiko(val)
  }

  //入力値チェック処理：品目番号
  const chkHinban = (event: any) => {
    let val = event.target.value
    setHinbanError(chkHalfWidth(val))
    setHinban(val)
  }

  //入力値チェック処理：現在庫
  const chkGenZaiko = (event: any) => {
    let val = event.target.value
    setGenZaikoError(chkNumber(val))
    setGenZaiko(val)
  }

  const column = [
    { name: "loc", label: "工場コード",
      options: {
        setCellProps: () => ({ style: { position: "sticky", left: 0, width: 100, background: "white", zIndex: 100 } }),
        setCellHeaderProps: () => ({ style: { position: "sticky", left: 0, width: 100, background: "white", zIndex: 101 } })
      }
    },
    { name: "company", label: "工場名　　　　　　　　　　　　", 
      options: {
        setCellProps: () => ({ style: { position: "sticky", left: 103, width: 400, background: "white", zIndex: 102 } }),
        setCellHeaderProps: () => ({ style: { position: "sticky", left: 103, width: 400, background: "white", zIndex: 103 } })
      }
    },
    { name: "item", label: "品目番号",
      options: {
        setCellProps: () => ({ style: { position: "sticky", left: 347, background: "white", zIndex: 103 } }),
        setCellHeaderProps: () => ({ style: { position: "sticky", left:347, background: "white", zIndex: 105 } })
      }
    },
    { name: "qty_on_hand", label: "現在庫", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "mounting_operation_div", label: "取付工程区分", },
    { name: "stock_specifications_div", label: "在庫仕様区分", },
    { name: "storage_bins1", label: "棚番１", },
    { name: "automatic_order", label: "自動発注", },
    { name: "order_method", label: "発注方式", },
    { name: "cycle1", label: "サイクル１", },
    { name: "cycle2", label: "サイクル２", },
    { name: "lead_time", label: "リードタイム",
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "safety_stock", label: "安全在庫", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "minimum_unit", label: "最小単位",
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "qty_per_carton", label: "入数", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "maximum_order", label: "最大発注量",       
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "consumption_per_day", label: "消費／日",       
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "container", label: "容器", },
    { name: "box_number", label: "箱数",
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "payout_classification", label: "工程処理（払出区分）", },
    { name: "delivery_place", label: "納入場所２", },
    { name: "special_cardboard_order", label: "特寸段ボール発注", },
    { name: "step_number", label: "手番", },
    { name: "label_presence_mark", label: "ラベル有無マーク", },
    { name: "update_date", label: "更新日", },
    { name: "count_date1", label: "棚卸日１", },
    { name: "qty_orderd", label: "発注残", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "without_serial_no_receipt", label: "当月実績製番無し入庫", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "without_serial_no_issue1", label: "当月実績製番無し出庫１", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "without_serial_no_issue2", label: "当月実績製番無し出庫２", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "without_serial_annual_consumption", label: "当月実績製番無し年間消費実績", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "with_serial_no_wip_stock", label: "当月実績製番有り仕掛在庫", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "with_serial_no_receipt", label: "当月実績製番有り入庫", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "with_serial_no_issue1", label: "当月実績製番有り出庫１", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "with_serial_no_issue2", label: "当月実績製番有り出庫２", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
   
    // { name: "storage_bins2", label: "棚番２", },
    // { name: "storage_bins3", label: "棚番３", },
    // { name: "storage_bins4", label: "棚番４", },
    // { name: "storage_bins5", label: "棚番５", },
    // { name: "storage_bins6", label: "棚番６", },
    // { name: "natural_decrease_rate", label: "減耗率", },
    // { name: "qty_reserve_waiting", label: "引当待ち", },
    // { name: "qty_reserved", label: "引当済み", },
    // { name: "qty_ship_waiting", label: "出荷待ち数", },
    // { name: "without_serial_annual_purchase", label: "当月実績製番無し年間仕入実績", },
    // { name: "with_serial_no_reserve_a", label: "当月実績製番有り予備Ａ", },
    // { name: "with_serial_no_reserve_b", label: "当月実績製番有り予備Ｂ", },
    // { name: "count_date2", label: "棚卸日２", },
    // { name: "generated_seq_of_the_day", label: "当日発生連番", },
    // { name: "stock_save_mark", label: "在庫セーブマーク", },
    // { name: "maker_storage_bins", label: "メーカー棚番", },
    // { name: "annual_purchase_check", label: "年間仕入チェック", },
    // { name: "annual_consumption_check", label: "年間消費チェック", },
    // { name: "terminal_id", label: "ターミナルＩＤ", },
    // { name: "reserved3", label: "予備３", },
    // { name: "item_linkage_div", label: "項目連動方式区分", },
  ];

  return (
    <GenericTemplate title="" >
      <Stack spacing={1} direction="column" >
        <Stack spacing={2} direction="row">
          {/* 在庫場所 */}
          <FromTextFieldZaiko onChange={chkZaiko}
            label={'在庫場所'}
            helperText={zaikoError}
          />
          {/* 品目番号 */}
          <FromTextFieldHinmokuNo onChange={chkHinban}
            helperText={hinbanError}
          />
          {/* 現在庫 */}
          <FromTextFieldSuryo onChange={chkGenZaiko}
            label={'現在庫（以上）'}
            helperText={genZaikoError}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ m: 1, position: 'relative' }}>
              <Button variant="contained"
                sx={buttonSx}
                disabled={loading}
                onClick={() => { handleClick(); }}>
                検索
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
          </Box>
        </Stack>
        <div style={{ height: '50%', width: '100%', }}>
          <MUIDataTable
            title="品目保管場所(stls)"
            data={post}
            columns={column}
            options={{
              selectableRows: 'none',
              rowsPerPage: 20,
              viewColumns: false,
              rowsPerPageOptions: [20, 50, 100],
              sort: false,
              print: false,
              setTableProps: () => {
                return {
                  // padding: 'none',
                  size: 'small',
                  style: { whiteSpace: "nowrap" }
                };
              },
            }}
          />
        </div>
      </Stack>
    </GenericTemplate>
  )
}

export default P01Prg001Kata003