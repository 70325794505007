import { useState, useEffect } from 'react';
import { DataGrid, GridColDef, } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';
import { sabis1Interface } from '../../pages/aqua/P01Put001Konpo005';

type Props = {
    dataList: sabis1Interface[]
}

const columns: GridColDef[] = [
    { field: 'djshohn', headerName: '商品名', width: 400, },
    { field: 'djmado01', headerName: '窓種１', width: 200, },
    { field: 'djmado02', headerName: '窓種２', width: 200, },
    { field: 'dctokiro', headerName: '特寸色', width: 60, },
    { field: 'dduke', headerName: '受注日', width: 100, },
    { field: 'drtseis', headerName: 'L/T', width: 60, align: 'right', },
    { field: 'ddseizir', headerName: '製造依頼日', width: 100, },
    { field: 'ddsysiji', headerName: '出荷指示日', width: 100, },
    { field: 'dddnoki', headerName: '指定納期', width: 100, },
    { field: 'dsuke', headerName: '受注数量', width: 100, align: 'right', },
    { field: 'dsukemih', headerName: '未引当数量', width: 100, align: 'right', },
    { field: 'dssyuka', headerName: '出荷済数量', width: 100, align: 'right', },
    { field: 'dctokui', headerName: '得意先CD', width: 100, },
    { field: 'djtokui', headerName: '得意先名', width: 300, },
    { field: 'dikiji', headerName: '記事', width: 300, },
    { field: 'dcsline', headerName: 'ライン1', width: 80, },
    { field: 'dcsline2', headerName: 'ライン2', width: 80, },
    { field: 'diprog', headerName: 'プログラム', width: 100, },
    { field: 'dibikou', headerName: '備考', width: 100, },
    { field: 'dbumuzu', headerName: '図面', width: 60, },
    { field: 'dbsfuka', headerName: '生産負荷MK', width: 100, },
    { field: 'dcseries', headerName: 'シリーズ', width: 100, },
    { field: 'dcmado', headerName: '窓種', width: 100, },
    { field: 'dkrl01', headerName: 'RL区分1', width: 100, },
    { field: 'dkrl02', headerName: 'RL区分2', width: 100, },
    { field: 'dcmadotp01', headerName: '窓種タイプ1', width: 100, },
    { field: 'dcmadotp02', headerName: '窓種タイプ2', width: 100, },
    { field: 'dcopta01', headerName: 'オプションA01', width: 120, },
    { field: 'dcopta02', headerName: 'オプションA02', width: 120, },
    { field: 'dcopta03', headerName: 'オプションA03', width: 120, },
    { field: 'dcopta04', headerName: 'オプションA04', width: 120, },
    { field: 'dcopta05', headerName: 'オプションA05', width: 120, },
    { field: 'dcopta06', headerName: 'オプションA06', width: 120, },
    { field: 'dcopta07', headerName: 'オプションA07', width: 120, },
    { field: 'dcopta08', headerName: 'オプションA08', width: 120, },
    { field: 'dcopta09', headerName: 'オプションA09', width: 120, },
    { field: 'dcopta10', headerName: 'オプションA10', width: 120, },
    { field: 'dcoptb01', headerName: 'オプションB01', width: 120, },
    { field: 'dcoptb02', headerName: 'オプションB02', width: 120, },
    { field: 'dcoptb03', headerName: 'オプションB03', width: 120, },
    { field: 'dcoptb04', headerName: 'オプションB04', width: 120, },
    { field: 'dcoptb05', headerName: 'オプションB05', width: 120, },
    { field: 'dcoptb06', headerName: 'オプションB06', width: 120, },
    { field: 'dcoptb07', headerName: 'オプションB07', width: 120, },
    { field: 'dcoptb08', headerName: 'オプションB08', width: 120, },
    { field: 'dcoptb09', headerName: 'オプションB09', width: 120, },
    { field: 'dcoptb10', headerName: 'オプションB10', width: 120, },
    { field: 'dcteisu01', headerName: '寸法01', width: 100, },
    { field: 'dcteisu02', headerName: '寸法02', width: 100, },
    { field: 'dcteisu03', headerName: '寸法03', width: 100, },
    { field: 'dcteisu04', headerName: '寸法04', width: 100, },
    { field: 'dcteisu05', headerName: '寸法05', width: 100, },
    { field: 'dcteisu06', headerName: '寸法06', width: 100, },
    { field: 'dcteisu07', headerName: '寸法07', width: 100, },
    { field: 'dcteisu08', headerName: '寸法08', width: 100, },
    { field: 'dcteisu09', headerName: '寸法09', width: 100, },
    { field: 'dcteisu10', headerName: '寸法10', width: 100, },
    { field: 'dcteisu11', headerName: '寸法11', width: 100, },
    { field: 'dcteisu12', headerName: '寸法12', width: 100, },
];

const DataGridPutSabis1 = (props: Props,) => {
    // *************
    // 変数定義
    // *************
    //検索結果を管理するuseState
    const [post, setPosts] = useState<sabis1Interface[]>([])

    //画面初期表示
    useEffect(() => {
        // console.debug(props.dataList)
        setPosts(
            props.dataList.map((dataRow: sabis1Interface) => {
				return {...dataRow, 
                    dcteisu01: (dataRow.dcteisu01 === null ? '' : dataRow.dcteisu01) + ' ' + (dataRow.dlsunpo01 === null ? '' : dataRow.dlsunpo01),
                    dcteisu02: (dataRow.dcteisu02 === null ? '' : dataRow.dcteisu02) + ' ' + (dataRow.dlsunpo02 === null ? '' : dataRow.dlsunpo02),
                    dcteisu03: (dataRow.dcteisu03 === null ? '' : dataRow.dcteisu03) + ' ' + (dataRow.dlsunpo03 === null ? '' : dataRow.dlsunpo03),
                    dcteisu04: (dataRow.dcteisu04 === null ? '' : dataRow.dcteisu04) + ' ' + (dataRow.dlsunpo04 === null ? '' : dataRow.dlsunpo04),
                    dcteisu05: (dataRow.dcteisu05 === null ? '' : dataRow.dcteisu05) + ' ' + (dataRow.dlsunpo05 === null ? '' : dataRow.dlsunpo05),
                    dcteisu06: (dataRow.dcteisu06 === null ? '' : dataRow.dcteisu06) + ' ' + (dataRow.dlsunpo06 === null ? '' : dataRow.dlsunpo06),
                    dcteisu07: (dataRow.dcteisu07 === null ? '' : dataRow.dcteisu07) + ' ' + (dataRow.dlsunpo07 === null ? '' : dataRow.dlsunpo07),
                    dcteisu08: (dataRow.dcteisu08 === null ? '' : dataRow.dcteisu08) + ' ' + (dataRow.dlsunpo08 === null ? '' : dataRow.dlsunpo08),
                    dcteisu09: (dataRow.dcteisu09 === null ? '' : dataRow.dcteisu09) + ' ' + (dataRow.dlsunpo09 === null ? '' : dataRow.dlsunpo09),
                    dcteisu10: (dataRow.dcteisu10 === null ? '' : dataRow.dcteisu10) + ' ' + (dataRow.dlsunpo10 === null ? '' : dataRow.dlsunpo10),
                    dcteisu11: (dataRow.dcteisu11 === null ? '' : dataRow.dcteisu11) + ' ' + (dataRow.dlsunpo11 === null ? '' : dataRow.dlsunpo11),
                    dcteisu12: (dataRow.dcteisu12 === null ? '' : dataRow.dcteisu12) + ' ' + (dataRow.dlsunpo12 === null ? '' : dataRow.dlsunpo12),
                };
			})
        );
        // setPosts(props.dataList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <Stack direction="row" alignItems="baseline">
                <Typography variant="h6" component="h2">
                    【SABIS1注残】
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                    ※：一日前の状態です 
                </Typography>
            </Stack>
            <Box sx={{ height: 100, width: '100%' }}>
                <DataGrid 
                    density="compact"
                    rows={post}
                    columns={columns}
                    hideFooter={true}
                    experimentalFeatures={{ newEditingApi: true }}
                    showCellRightBorder
                />
            </Box>
        </div>
    );
};

export default DataGridPutSabis1;

