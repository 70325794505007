import React, { useState } from 'react';
import Box from '@mui/material/Box';
import requests from '../../utils/config/Request';
import GenericTemplate from '../../templates/GenericTemplate';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import ButtonSearch from '../../components/Button/ButtonSearch';
import ButtonLeft from '../../components/Button/ButtonFuncUpdate';
import ButtonRight from '../../components/Button/ButtonFuncUpdate';
import ButtonFileDownload from '../../components/ButtonFileDownload';
import SelectUserServices from '../../components/Select/SelectUserServices';
import { Tooltip } from '@mui/material';

function not(a: readonly roleInterface[], b: readonly roleInterface[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly roleInterface[], b: readonly roleInterface[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

interface userInterface {
  userId: string;
  userName_ja: string;
  attachedRole: roleInterface[];
  notAttachedRole: roleInterface[];
  arrRole: roleInterface[];
}
interface roleInterface {
  role: string;
  description: string;
}
interface updateInterface {
  userId: string;
  userName_ja: string;
  attachedRole: string[];
}

const P01Mst001Permission001: React.FC = () => {
  // *************
  // 変数定義
  // *************
  //ユーザサービス
  const [userServices, setUserServices] = useState(''); 
  const [searchUserServices, setSearchUserServices] = useState(''); 
  //ユーザ情報リスト
  const [userList, setUserList] = useState<userInterface[]>([]);
  //ユーザID
  const [userId, setUserId] = useState('')
  //ユーザ名
  const [userName_ja, setUserName_ja] = useState('')
  //ユーザ設定済権限(右)
  const [attachedRole, setAttachedRole] = useState<roleInterface[]>([]);
  //ユーザ未設定権限(左)
  const [notAttachedRole, setNotAttachedRole] = useState<roleInterface[]>([]);
  //権限チェックボックスリスト
  const [checked, setChecked] = useState<roleInterface[]>([]);
  //権限一覧の左右別のチェックボックスリスト
  const leftChecked = intersection(checked, notAttachedRole);
  const rightChecked = intersection(checked, attachedRole);

  // *************
  // 定数定義
  // *************
  // api 用のパラメータを設定する
  const apiKey = String(process.env.REACT_APP_APIKEY_CONFIG)
  // APIのget 時のパラメータを指定(ユーザ一覧)
  const apiGetUrl = String(process.env.REACT_APP_APIURL_CONFIG + requests.p01Mst001User)
  const apiGetParam = {};
  // APIのput 時のパラメータを指定
  const apiPutUrl = String(process.env.REACT_APP_APIURL_CONFIG + requests.p01Mst001User + '/' + userId)
  const apiPutParamL = {attachedRole: attachedRole.concat(leftChecked).map(({role}) => {return (role);})};
  const apiPutParamR = {attachedRole: not(attachedRole, rightChecked).map(({role}) => {return (role);})};

  //検索ボタン押下時のデータ編集
  const setUserInfoList = (params:userInterface[]) => {
    //画面初期化
    setSearchUserServices(userServices)
    setUserId('')
    setUserName_ja('');
    setAttachedRole([]);
    setNotAttachedRole([]);
    setChecked([]);   //チェックボックス初期化  
    //取得データ設定
    const newList: any = params.map((row: userInterface) => {
      return {
          "userId": row.userId,
          "userName_ja": row.userName_ja,
          "attachedRole": row.attachedRole,
          "notAttachedRole": row.notAttachedRole,
          "arrRole": row.attachedRole.concat(row.notAttachedRole)
      }}
    );    
    setUserList(newList);
  };

  //画面下左部のユーザ一覧を作成
  const getUsersList = userList.map((row: userInterface) => (
    <ListItem disablePadding key={row.userId} >
        <ListItemButton onClick={(event) => handleUserSelected(row)}>
          <ListItemText primary={row.userId}/>
        </ListItemButton>
    </ListItem>
  ))
  //画面下左部のユーザ一覧を選択した場合、選択したユーザ情報を画面上に表示
  const handleUserSelected = (row: userInterface) => {
    setUserId(row.userId);
    setUserName_ja(row.userName_ja);
    const sortAttachedRole = [...row.attachedRole];
    setAttachedRole(sortAttachedRole);
    const sortNotAttachedRole = [...row.notAttachedRole]
    setNotAttachedRole(sortNotAttachedRole);
    setChecked([]);   //チェックボックス初期化
  }
  //画面下右部の権限一覧を作成（未設定、設定済）
  const getGrantList = (items: readonly roleInterface[]) => (
    <Paper sx={{ width: 400, height: 330, overflow: 'auto' }}>
      {items != null && 
      <List dense component="div" role="list">
        {items.map((item: roleInterface) => {
          const labelId = `transfer-list-item-${item.role}-label`;

          return (
            <Tooltip title={item.description}>
            <ListItem
              key={item.role}
              role="listitem"
              button
              onClick={handleToggle(item)}
            >
              <ListItemIcon>
                <Checkbox 
                  checked={checked.findIndex(({role}) => role === item.role) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={item.role} />
            </ListItem>
            </Tooltip>
          );
        })}
      </List>}
    </Paper>
  );
  //画面下右部の権限一覧のチェック変更（未設定、設定済）
  const handleToggle = (item: roleInterface) => () => {
    const currentIndex = checked.findIndex(({role}) => role === item.role);
    const newChecked = [...checked];
    
    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  //「＞」ボタン処理  
  const handleCheckedRight = () => {
    const moveToRight = intersection(notAttachedRole, leftChecked);
    if (attachedRole.length === 0) {
      setAttachedRole(moveToRight);
    } else {
      setAttachedRole(attachedRole.concat(moveToRight));
    }
    setNotAttachedRole(not(notAttachedRole, leftChecked));
    setChecked(not(checked, leftChecked));
    return true
  };
  //「＜」ボタン処理
  const handleCheckedLeft = () => {
    const moveToLeft = intersection(attachedRole, rightChecked);
    if (notAttachedRole.length === 0 ) {
      setNotAttachedRole(moveToLeft);
    } else {
      setNotAttachedRole(notAttachedRole.concat(moveToLeft));
    }
    setAttachedRole(not(attachedRole, rightChecked));
    setChecked(not(checked, rightChecked));
    return true
  };

  const updateResult = (result : updateInterface) => {
    // console.debug('updateList!')
    if (result == null) return
    setUserList(
      userList.map((dataRow:userInterface) => {
        if (dataRow.userId === result.userId) {
          return {...result, 
                  attachedRole:
                    dataRow.arrRole.filter(({ role }) => result.attachedRole.indexOf(role) !== -1),
                  notAttachedRole:
                    dataRow.arrRole.filter(({ role }) => result.attachedRole.indexOf(role) === -1),
                  arrRole:dataRow.arrRole 
                };
        } else {
          return dataRow;
        }
      })
    );
  }

  return (
    <GenericTemplate title="ユーザ管理" >
      <Stack spacing={2} divider={<Divider orientation="horizontal" flexItem />} direction="column" >
        <Stack spacing={2} direction="row">
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <SelectUserServices onChange={(event) => setUserServices(event.target.value)}
              selected={userServices}
            />
          </FormControl>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* 検索・ボタン */}
            <ButtonSearch
              apiParam={apiGetParam}
              apiUrl={apiGetUrl.replace('{userServices}', userServices)}
              apiKey={apiKey}
              getPost={setUserInfoList}
              disabled={userServices===''}
            />
          </Box>
        </Stack>
        <Stack spacing={2} divider={<Divider orientation="vertical" flexItem />} direction="row">
          <FormControl sx={{ minWidth: 180 }} size="small" >
              <InputLabel id="list-users"/>
              <Box sx={{ height: 490, overflow: 'auto' }}>
                <List id="list-users">{getUsersList}</List>
              </Box>
          </FormControl>                 
          <Stack spacing={2} direction="column" >
            <Stack spacing={2} direction="row">ユーザ：{userId} {userName_ja}</Stack>
            <Stack spacing={2} direction="row">
              <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item>未設定権限：{getGrantList(notAttachedRole)}</Grid>
                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    <ButtonLeft
                      apiParam={{ ...apiPutParamL }}
                      apiUrl={apiPutUrl.replace('{userServices}', searchUserServices)}
                      apiKey={apiKey}
                      disabled={leftChecked.length === 0}
                      result={updateResult}
                      name="＞"
                      beforFunc={handleCheckedRight}   //更新前処理
                      size="small"
                    />
                    <ButtonRight
                      apiParam={{ ...apiPutParamR }}
                      apiUrl={apiPutUrl.replace('{userServices}', searchUserServices)}
                      apiKey={apiKey}
                      disabled={rightChecked.length === 0}
                      result={updateResult}
                      name="＜"
                      beforFunc={handleCheckedLeft}   //更新前処理
                      size="small"
                    />
                  </Grid>
                </Grid>
                <Grid item>設定済権限：{getGrantList(attachedRole)}</Grid>
              </Grid>
            </Stack>            
            <div>※：変更内容はログイン時に反映されます。ログイン中の場合は一旦ログアウトしてください。</div>
            <div>
            <ButtonFileDownload name="操作マニュアル" url="/pdf/config/manual/Userkanri.pdf" filename="Userkanri.pdf" />
              このサイトの操作マニュアルをダウンロードします
            </div>
          </Stack>
        </Stack>
      </Stack>
    </GenericTemplate>
  )
}

export default P01Mst001Permission001