import { Route, Routes } from 'react-router-dom';
import MstStructurematerial from '../../pages/monoss/P01Mst001Buhin001';
import MstCostByHonbu from '../../pages/monoss/P01Mst101Hanbai001';
import MstCostByLine from '../../pages/monoss/P01Mst101Hanbai002';

export default function RoutesMonoss() {
  return (
    <Routes>
        <Route path="/monoss/MstStructurematerial" element={<MstStructurematerial/>} />
        <Route path="/monoss/MstCostByHonbu" element={<MstCostByHonbu/>} />
        <Route path="/monoss/MstCostByLine" element={<MstCostByLine/>} />
    </Routes>
    );
};