import React, { useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import requests from '../../utils/stls/Request';
import GenericTemplate from '../../templates/GenericTemplate';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import MUIDataTable from "mui-datatables";
import DesktopDatePickerCommon from '../../components/DesktopDatePicker/DesktopDatePickerCommonErrDisp';
import SelectTransType from '../../components/Select/SelectTransType';
import CheckBoxTransDate from '../../components/Checkbox/CheckBoxOne';
import FromTextFieldKojoCd from '../../components/TextField/TextFieldKojoCd';
import FromTextFieldKataban from '../../components/TextField/TextFieldKataban';
import FromTextFieldColor from '../../components/TextField/TextFieldColor';
import FromTextFieldTeisyaku from '../../components/TextField/TextFieldTeisyaku';
import FromTextFieldWorkNo from '../../components/TextField/TextFieldWorkNo';
import FromTextFieldVoucherNum from '../../components/TextField/TextFieldVoucherNum';
import { chkHalfWidth, chkNumber, chkEmptyDate, chkDate, chkEmpty } from '../../common/chkTextValue';
import {Auth} from 'aws-amplify';
import moment from 'moment';

const P01Prg002Kata001: React.FC = () => {
  //変数定義
  const [post, setPosts] = useState([])
  //トラン区分をAPIにpostする変数
  const [transType, setTransType] = useState('');
  const [transTypeError, setTransTypeError] = useState('');
  //トラン日付
  const [transDate, setTransDate] = useState<Date | undefined>(new Date());
  const [transDateError, setTransDateError] = useState('');
  //年月検索チェックボックス
  const [isChecked, setIsChecked] = useState(false);
  //工場コード
  const [kojoCd, setKojoCd] = useState('');
  const [kojoCdError, setKojoCdError] = useState('');
  //形番
  const [kataban, setKataban] = useState('');
  const [katabanError, setKatabanError] = useState('');
  //色コード
  const [color, setColor] = useState('');
  const [colorError, setColorError] = useState('');
  //定尺
  const [teisyaku, setTeisyaku] = useState('');
  const [teisyakuError, setTeisyakuError] = useState('');
  //ワークNo
  const [workNo, setWorkNo] = useState('');
  const [workNoError, setWorkNoError] = useState('');
  //受注伝票番号
  const [voucherNum, setVoucherNum] = useState('');
  const [voucherNumError, setVoucherNumError] = useState('');

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  //requests.p01Mst001KataStockにリクエストする関数。
  const fetchRequest = async() => {
    setPosts([])

    //requestを投げるURL作成
    const URL = process.env.REACT_APP_APIURL_STLS + requests.p01Mst001KataTransaction + '/' + transType
    const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    console.log(transDate)
    axios.get(URL,
      {
        headers: {
          "X-API-KEY": process.env.REACT_APP_APIKEY_STLS || "",
          "Authorization": AuthToken || "",
        },
        params: {
          transDate:  isChecked ? moment(transDate).format("YYYYMM") : moment(transDate).format("YYYYMMDD"),//トラン日付
          loc:        kojoCd,     //工場コード
          kataban:    kataban,    //形番
          color:      color,      //色コード
          teisyaku:   teisyaku,   //定尺
          workNo:     workNo,     //ワークNo
          voucherNum: voucherNum, //受注伝票番号
        },
        }).then((res) => {
        //成功したら Posts に result を格納。
        console.log(res.request.responseURL)
        setPosts(res.data.result)
        if (res.data.result.length === 0) {
          alert('データはありません')
        }
        console.debug(res.data.result)
      }).catch(error => {
        console.log(error);
        alert('検索に失敗しました')
      }).finally(() => {
        setLoading(false);
      });
  };

  //検索・ボタン押下時の処理 
  const handleClick = (() => {
    let errMsg1 = ""
    let errMsg2 = ""

    //未入力チェック
    errMsg1 = chkEmpty(transType)
    errMsg2 = chkEmptyDate(transDate)
    setTransTypeError(errMsg1)
    setTransDateError(errMsg2)
    if (errMsg1.length > 0 || errMsg2.length > 0) {
      alert('検索条件を入力してください');
      return;
    }
    //半角文字チェック：トラン日付 
    errMsg1 = chkDate(transDate)
    if (errMsg1.length > 0) {
      setTransDateError(errMsg1)
      alert(errMsg1);
      return;
    }
    //半角文字チェック：工場コード 
    errMsg1 = chkHalfWidth(kojoCd)
    if (errMsg1.length > 0) {
      setKojoCdError(errMsg1)
      alert(errMsg1);
      return;
    }
    //半角文字チェック：形番 
    errMsg1 = chkHalfWidth(kataban)
    if (errMsg1.length > 0) {
      setKatabanError(errMsg1)
      alert(errMsg1);
      return;
    }
    //半角文字チェック：色コード
    errMsg1 = chkHalfWidth(color)
    if (errMsg1.length > 0) {
      setColorError(errMsg1)
      alert(errMsg1);
      return;
    }
    //数値チェック：定尺
    errMsg1 = chkNumber(teisyaku)
    if (errMsg1.length > 0) {
      setTeisyakuError(errMsg1)
      alert(errMsg1);
      return;
    }
    //半角文字チェック：ワークNo
    errMsg1 = chkHalfWidth(workNo)
    if (errMsg1.length > 0) {
      setWorkNoError(errMsg1)
      alert(errMsg1);
      return;
    }
    //半角文字チェック：受注伝票番号
    errMsg1 = chkHalfWidth(voucherNum)
    if (errMsg1.length > 0) {
      setVoucherNumError(errMsg1)
      alert(errMsg1);
      return;
    }

    if (!loading) {
      setSuccess(false);
      setLoading(true);
      //apiコールする関数を呼び出す
      fetchRequest()
      setSuccess(true);
    }
  })

  //入力値チェック処理：トラン区分
  const chkTransType = (event: any) => {
    let val = event.target.value
    setTransTypeError('')
    setTransType(val)
  }
  //入力値チェック処理：工場コード
  const chkKojoCd = (event: any) => {
    let val = event.target.value
    setKojoCdError(chkHalfWidth(val))
    setKojoCd(val)
  }
  //入力値チェック処理：形番
  const chkKataban = (event: any) => {
    let val = event.target.value
    setKatabanError(chkHalfWidth(val))
    setKataban(val)
  }
  //入力値チェック処理：色コード
  const chkColor = (event: any) => {
    let val = event.target.value
    setColorError(chkHalfWidth(val))
    setColor(val)
  }
  //入力値チェック処理：定尺
  const chkTeisyaku = (event: any) => {
    let val = event.target.value
    setTeisyakuError(chkNumber(val))
    setTeisyaku(val)
  }
  //入力値チェック処理：ワークNo
  const chkWorkNo = (event: any) => {
    let val = event.target.value
    setWorkNoError(chkHalfWidth(val))
    setWorkNo(val)
  }
  //入力値チェック処理：受注伝票番号
  const chkVoucherNum = (event: any) => {
    let val = event.target.value
    setVoucherNumError(chkHalfWidth(val))
    setVoucherNum(val)
  }

  //日付をスラッシュ編集
  function dateEditDisplay(val: string) {
    return val.length === 8 ? moment(val).format("YYYY/MM/DD") : val;
  }
  //数値をカンマ編集
  function commaEditDisplay(val: any) {
    return Number(val).toLocaleString(undefined, { maximumFractionDigits: 3 });
  }  
  //数値をカンマ編集、小数点以下２桁
  function commaEditDisplayZero2(val: any) {
    return Number(val).toLocaleString(undefined, { maximumFractionDigits: 3, minimumFractionDigits: 2 });
  }  

  const column = [
    { name: "trans_num", label: "トラン番号",
      options: {
        setCellProps: () => ({ style: { position: "sticky", left: 0, width: 100, background: "white", zIndex: 100, textAlign:"right" } }),
        setCellHeaderProps: () => ({ style: { position: "sticky", left: 0, width: 100, background: "white", zIndex: 101, textAlign:"right" } })
      }
    },
    { name: "trans_type", label: "トラン区分", 
      options: {
        setCellProps: () => ({ style: { position: "sticky", left: 103, width: 80, background: "white", zIndex: 102 } }),
        setCellHeaderProps: () => ({ style: { position: "sticky", left: 103, width: 80, background: "white", zIndex: 103 } })
      }
    },
    { name: "trans_type_name", label: "トラン区分名", },
    { name: "trans_date", label: "トラン日付",  
      options: {
        customBodyRender:(cellValue: string) => {return dateEditDisplay(cellValue)}
      }
    },
    { name: "loc", label: "保管場所", },
    { name: "mold_material", label: "形番", },
    { name: "color", label: "色コード", },
    { name: "fixed_dimensions", label: "定尺", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "material", label: "材質", },
    { name: "heat_treatment", label: "熱処理", },
    { name: "qty", label: "本数", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"}),
        customBodyRender:(cellValue: any) => {return commaEditDisplay(cellValue)}
      }
    },
    { name: "weight", label: "重量", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"}),
        customBodyRender:(cellValue: any) => {return commaEditDisplayZero2(cellValue)}
      }
    },
    { name: "voucher_num", label: "伝票番号", },
    { name: "management_dept", label: "管理部署", },
    { name: "management_dept_name", label: "管理部署名", },
    { name: "cost_div", label: "原価区分", },
    { name: "cost_div_name", label: "原価区分名", },
    { name: "counter_loc", label: "相手先保管場所", },
    { name: "counter_color", label: "相手先色コード", },
    { name: "counter_management_dept", label: "相手先管理部署", },
    { name: "counter_management_dept_name", label: "相手先管理部署名", },
    { name: "counter_cost_div", label: "相手先原価区分", },
    { name: "counter_cost_div_name", label: "相手先原価区分名", },
    { name: "special_order_mark", label: "特注マーク", },
    { name: "return_trans_num", label: "戻しトラン番号", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "inch", label: "インチ", },
    { name: "packing", label: "荷姿", },
    { name: "packing_name", label: "荷姿名", },
    { name: "work_no", label: "ワークＮｏ", },
    { name: "order_voucher_num", label: "受注伝票番号", },
    { name: "order_voucher_branch ", label: "受注伝票枝番", },
    { name: "dept", label: "部門", },
    { name: "commodity_code", label: "商品コード", },
    { name: "pre_div", label: "プレ区分", },
    { name: "extrusion_place", label: "押出場所", },
    { name: "update_date", label: "更新日",  
      options: {
        customBodyRender:(cellValue: string) => {return dateEditDisplay(cellValue)}
      }
    },
    { name: "update_user", label: "更新者", },
    { name: "create_date", label: "作成日",  
      options: {
        customBodyRender:(cellValue: string) => {return dateEditDisplay(cellValue)}
      }
    },
    { name: "create_user", label: "作成者", },
  ];

  return (
    <GenericTemplate title="">
      <Stack spacing={1} direction="column" >
        <Stack spacing={2} direction="row">
          {/*  トラン区分 */}
          <SelectTransType onChange={chkTransType} 
            helperText={transTypeError}
          />
          {/*  トラン日付 */}
          <DesktopDatePickerCommon
            label="トラン日付"
            value={transDate}
            helperText={transDateError}
            onChange={(newDate: any) => { setTransDateError(chkDate(newDate));
                                          setTransDate(newDate); }}
          />
          <CheckBoxTransDate 
            label="年月検索" 
            onChange={() => setIsChecked(prevState => !prevState)}
          />
        </Stack>
        <Stack spacing={2} direction="row">
          {/* 工場コード */}
          <FromTextFieldKojoCd onChange={chkKojoCd}
            label="保管場所"
            helperText={kojoCdError}
          />
          {/* 形番 */}
          <FromTextFieldKataban onChange={chkKataban}
            helperText={katabanError}
          />
          {/* 色コード */}
          <FromTextFieldColor onChange={chkColor}
            helperText={colorError}
          />
          {/* 定尺 */}
          <FromTextFieldTeisyaku onChange={chkTeisyaku}
            helperText={teisyakuError}
          />
          {/* ワークNo */}
          <FromTextFieldWorkNo onChange={chkWorkNo}
            helperText={workNoError}
          />
          {/* 受注伝票番号 */}
          <FromTextFieldVoucherNum onChange={chkVoucherNum}
            helperText={voucherNumError}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ m: 1, position: 'relative' }}>
              <Button variant="contained"
                sx={buttonSx}
                disabled={loading}
                onClick={() => { handleClick(); }}>
                検索
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
          </Box>
        </Stack>
        <div style={{ height: '50%', width: '100%' }}>
          <MUIDataTable
            title="形材実績明細(stls)"
            data={post}
            columns={column}
            options={{
              selectableRows: 'none',
              rowsPerPage: 20,
              viewColumns: false,
              rowsPerPageOptions: [20, 50, 100],
              sort: false,
              print: false,
              setTableProps: () => {
                return {
                  // padding: 'none',
                  size: "small",
                  style: { whiteSpace: "nowrap" },
                };
              },
            }}
          />
        </div>
      </Stack>
    </GenericTemplate>
  )
}

export default P01Prg002Kata001