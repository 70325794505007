import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import GenericTemplate from '../../templates/GenericTemplate';
import requests from '../../utils/aqua/Request';
import FromTextFieldCyumonno from '../../components/TextField/TextFieldCyumonno12';
import ButtonSearch from '../../components/Button/ButtonSearch';
import DataGridPutOrders from '../../components/DataGrid/DataGridPutOrders';
import DataGridPutSabis1 from '../../components/DataGrid/DataGridPutSabis1';
import DataGridPutGj10 from '../../components/DataGrid/DataGridPutGj10';
import DataGridPutComp from '../../components/DataGrid/DataGridPutComp';
import DataGridPutBuzai from '../../components/DataGrid/DataGridPutBuzai';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ButtonCancel from '../../components/Button/ButtonAction';
import { chkHalfWidth } from '../../common/chkTextValue';
import {Auth} from 'aws-amplify';
import axios from 'axios';
import { green, grey } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';

interface infoInterface {
  orderList: orderInterface[];
  sabis1List: sabis1Interface[];
  gj10List: gj10Interface[];
  compList: compInterface[];
  buzaiList: buzaiInterface[];
}
export interface orderInterface {
  id: number;
  kanrno: string;
  tonykj: string;
  tonybi: string;
  leadtm: string;
  shksjb: string;
  setsu: string;
  henkob: string;
  kurabi: string;
  juchub: string;
  ldtmot: string;
  shksjm: string;
  rank: string;
  tnline: string;
  comupd: string;
  nokimmk: string;
  sitnok: string;
  sykbs1: string;
  sykbcy: string;
  commnt: string;
}
export interface sabis1Interface {
  id: number;
  djshohn: string;
  dikiji: string;
  dddnoki: string;
  ddsysiji: string;
  djmado01: string;
  djmado02: string;
  dduke: string;
  drtseis: string;
  ddseizir: string;
  dctokiro: string;
  dctokui: string;
  djtokui: string;
  dcsline: string;
  dcsline2: string;
  diprog: string;
  dibikou: string;
  dbumuzu: string;
  dbsfuka: string;
  dcseries: string;
  dcmado: string;
  dkrl01: string;
  dkrl02: string;
  dcmadotp01: string;
  dcmadotp02: string;
  dcopta01: string;
  dcopta02: string;
  dcopta03: string;
  dcopta04: string;
  dcopta05: string;
  dcopta06: string;
  dcopta07: string;
  dcopta08: string;
  dcopta09: string;
  dcopta10: string;
  dcoptb01: string;
  dcoptb02: string;
  dcoptb03: string;
  dcoptb04: string;
  dcoptb05: string;
  dcoptb06: string;
  dcoptb07: string;
  dcoptb08: string;
  dcoptb09: string;
  dcoptb10: string;
  dcteisu01: string;
  dlsunpo01: string;
  dcteisu02: string;
  dlsunpo02: string;
  dcteisu03: string;
  dlsunpo03: string;
  dcteisu04: string;
  dlsunpo04: string;
  dcteisu05: string;
  dlsunpo05: string;
  dcteisu06: string;
  dlsunpo06: string;
  dcteisu07: string;
  dlsunpo07: string;
  dcteisu08: string;
  dlsunpo08: string;
  dcteisu09: string;
  dlsunpo09: string;
  dcteisu10: string;
  dlsunpo10: string;
  dcteisu11: string;
  dlsunpo11: string;
  dcteisu12: string;
  dlsunpo12: string;
  dsuke: string;
  dsukemih: string;
  dssyuka: string;
}
export interface gj10Interface {
  id: number;
  iro: string;
  setsu: string;
  kihntp: string;
  series: string;
  madsy1: string;
  madkt1: string;
  katte1: string;
  maguc1: string;
  mesia1: string;
  nakbn1: string;
  sitwk1: string;
  yobi01: string;
  sonot1: string;
  madsy2: string;
  madkt2: string;
  katte2: string;
  maguc2: string;
  mesia2: string;
  nakbn2: string;
  sitwk2: string;
  yobi02: string;
  sonot2: string;
  madsy3: string;
  madkt3: string;
  katte3: string;
  maguc3: string;
  mesia3: string;
  nakbn3: string;
  sitwk3: string;
  yobi03: string;
  sonot3: string;
  mumea: string;
  mumeb: string;
  kozknm: string;
  kozknb: string;
  kozhem: string;
  kozhnb: string;
  koztan: string;
  hinmei: string;  
}
export interface compInterface {
  id: number;
  renban: string;
  kuraireday: string;
  knpkanryodaytime: string;
  plitenumbs: string;
  tonyday: string;
  bikou1: string;
}
export interface buzaiInterface {
  id: number;
  kataban: string;
  tategumei: string;
  iro: string;
  setudan_sunpo: string;
  cd_basho1: string;
  grp_no_buzai: string;
  setsu: string;
  tanisu : string;
}

type dialogProps = {
  onClose?: any;
  open?: boolean;
  rowParam?: any;
}

export function P01Put001Konpo005(props: dialogProps){
  // *************
  // プロパティの受け取り
  // *************
  const {
    onClose,
    open,
    rowParam,
  } = props;

  // *************
  // 変数定義
  // *************
  //検索結果を管理するuseState
  const [post, setPosts] = useState<infoInterface[]>([])
  const [orderList, setOrderList] = useState<orderInterface[]>([])
  const [sabis1List, setSabis1List] = useState<sabis1Interface[]>([])
  const [gj10List, setGj10List] = useState<gj10Interface[]>([])
  const [compList, setCompList] = useState<compInterface[]>([])
  const [buzaiList, setBuzaiList] = useState<buzaiInterface[]>([])
  // 検索条件をセットする変数
  const [cyumonno, setCyumonno] = useState('');
  const [cyumonnoError, setCyumonnoError] = useState('');
  
  const [loading, setLoading] = useState(false);

  // *************
  // 定数定義
  // *************
  // api 用のパラメータを設定する
  const apiKey = String(process.env.REACT_APP_APIKEY_AP00447)
  // APIのget 時のパラメータを指定
  const apiGetUrl = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Put001PackingCompSearch)
  const apiGetParam = {};

  // 画面初期表示時
  useEffect(() => {
    setCyumonno(rowParam)
    setOrderList([])
    setSabis1List([])
    setGj10List([])
    setCompList([])
    setBuzaiList([])
    if (open === true) {
      const fetch = async () => {
        setLoading(true);
        // console.debug(apiGetUrl2)
        //requestを投げるURL作成
        const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
        axios.get(apiGetUrl.replace('{cyumonno}', rowParam),
        {
          headers: {
            "X-API-KEY": apiKey,
            "Authorization": AuthToken || "",
          },
        }).then((res) => {
          // 成功したら result を格納。
          setPosts(res.data.result)
          console.log(res.data.result)
        }).catch(error => {
          console.log(error);
          alert('エラーが発生しました。再度検索して下さい。');
        }).finally(() => {
          setLoading(false);
        });
      }
      fetch()
  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // 検索データ編集
  useEffect(() => {
    if (post === null || post === undefined || post.length === 0) {
      setOrderList([])
      setSabis1List([])
      setGj10List([])
      setCompList([])
      setBuzaiList([])
    } else {
      if (post[0].orderList === undefined || post[0].orderList.length === 0){
        setOrderList([])
      } else {
        setOrderList(post[0].orderList.map((dataRow: orderInterface, index: number) => {
          return {...dataRow, 
            id: index + 1,
          };
        }))
      }
      if (post[0].sabis1List === undefined || post[0].sabis1List.length === 0){
        setSabis1List([])
      } else {
        setSabis1List(post[0].sabis1List.map((dataRow: sabis1Interface, index: number) => {
          return {...dataRow, 
            id: index + 1,
          };
        }))
      }
      if (post[0].gj10List === undefined || post[0].gj10List.length === 0){
        setGj10List([])
      } else {
        setGj10List(post[0].gj10List.map((dataRow: gj10Interface, index: number) => {
          return {...dataRow, 
            id: index + 1,
          };
        }))
      }
      if (post[0].compList === undefined || post[0].compList.length === 0){
        setCompList([])
      } else {
        setCompList(post[0].compList.map((dataRow: compInterface, index: number) => {
          return {...dataRow, 
            id: index + 1,
          };
        }))
      }
      if (post[0].buzaiList === undefined || post[0].buzaiList.length === 0){
        setBuzaiList([])
      } else {
        setBuzaiList(post[0].buzaiList.map((dataRow: buzaiInterface, index: number) => {
          return {...dataRow, 
            id: index + 1,
          };
        }))
      }
      if ((post[0].orderList === undefined || post[0].orderList.length === 0)
        && (post[0].sabis1List === undefined || post[0].sabis1List.length === 0)
        && (post[0].gj10List === undefined || post[0].gj10List.length === 0)
        && (post[0].compList === undefined || post[0].compList.length === 0)
        && (post[0].buzaiList === undefined || post[0].buzaiList.length === 0)
        ) {
          alert('データはありません')
      }
    }
  }, [post]);

  const P01Put001Detail = () => {
    return (
      <Box>
        <Stack spacing={1} direction="column" >
          <DataGridPutOrders 
            dataList={orderList}  
          />
          <DataGridPutSabis1
            dataList={sabis1List} 
          />
          <DataGridPutGj10
            dataList={gj10List}   
          />
          <Stack spacing={2} direction="row">
              <DataGridPutComp
                dataList={compList}   
              />
              <DataGridPutBuzai
                dataList={buzaiList}   
              />
          </Stack>
        </Stack>
      </Box>
    )
  }
  
  if (open == null) {
    return (
      <GenericTemplate title="梱包完了検索" >
        <Stack spacing={1} direction="column" >
          <Stack spacing={2} direction="row">
            {/* 注文番号 */}
            <FromTextFieldCyumonno 
              helperText={cyumonnoError}
              onChange={(event) => {setCyumonnoError(chkHalfWidth(event.target.value));
                                    setCyumonno(event.target.value)}} />
            {/* 検索・ボタン */}
            <ButtonSearch
              apiParam={apiGetParam}
              apiUrl={apiGetUrl.replace('{cyumonno}', cyumonno)}
              apiKey={apiKey}
              getPost={setPosts}
              disabled={cyumonno===undefined||cyumonnoError!=="" }
              />
          </Stack>
          <P01Put001Detail/>
        </Stack>
      </GenericTemplate>
    )
  } else {
    return (
      <Dialog 
        open={open}
        maxWidth="xl"
        fullWidth={true}>
        <DialogTitle>
          梱包明細照会
        </DialogTitle>
        <DialogContent sx={{ bgcolor: loading?grey[200]:"-moz-initial" }}  >
          注文番号：{cyumonno}
          <P01Put001Detail/>
        </DialogContent>
        {loading && (
          <CircularProgress
            size={50}
            sx={{
              color: green[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
        <DialogActions>
          <ButtonCancel
              onAction={onClose}
              name="閉じる"
              />
        </DialogActions>
      </Dialog>
  )
  }
}

export default P01Put001Konpo005