import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Stack } from '@mui/material';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import GenericTemplate from '../../templates/GenericTemplate';
import requests from '../../utils/aqua/Request';
import SelectKojoCode from '../../components/Select/SelectKojoCode';
import ButtonSearch from '../../components/Button/ButtonSearch';
import { P01Put001Konpo005 } from '../aqua/P01Put001Konpo005';

interface infoInterface {
  chuken: string;
  trokbi: string;
  uplhzk: string;
  dataList: rowInterface[];
}
interface rowInterface {
  id: number;
  chuban: string;
  hsoblk: string;
  syhnnm: string;
  sykosu: string;
  konpsu: string;
  saisu: string;
  denpno: string;
  deneda: string;
  okurij: string;
  kjcode: string;
  tonykj: string;
}
const columns: GridColDef[] = [
  { field: 'hsoblk', headerName: '方面', width: 60, },
  { field: 'chuban', headerName: '注文番号', width: 120, },
  { field: 'konpsu', headerName: '梱包数', width: 100, align: 'right', },
  { field: 'sykosu', headerName: '出庫数', width: 100, align: 'right', },
  { field: 'saisu', headerName: '才数', width: 100, align: 'right', },
  { field: 'denpno', headerName: '出伝No', width: 120, },
  { field: 'deneda', headerName: '枝', width: 60, },
  { field: 'okurij', headerName: '送状No', width: 100, },
  { field: 'syhnnm', headerName: '商品名', width: 400, },
  { field: 'kjcode', headerName: '工場', width: 80, },
  { field: 'tonykj', headerName: '投入', width: 80, },
];

const P01Put001St002: React.FC = () => {
  // *************
  // 変数定義
  // *************
  //検索結果を管理するuseState
  const [post, setPosts] = useState<infoInterface[]>([])

  // 検索条件をセットする変数
  const [kojoCode, setKojoCode] = useState(''); 
  const [chuken, setChuken] = useState(''); //注番件数
  const [trokbi, setTrokbi] = useState(''); //出荷指示更新
  const [uplhzk, setUplhzk] = useState(''); //納品情報更新
  const [dataList, setDataList] = useState<rowInterface[]>([])
  // ダイアログ用のstate
  const [digOpen, setDigOpen] = useState(false);
  const [cyumonno, setCyumonno] = useState('');
  
  // *************
  // 定数定義
  // *************
  // api 用のパラメータを設定する
  const apiKey = String(process.env.REACT_APP_APIKEY_AP00447)
  // APIのget 時のパラメータを指定
  const apiGetUrl = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Put002MikurairiList)
  const apiGetParam = {};

  // kojoCodeの値が変化した時
  useEffect(() => {
    if (post.length === 0) {
      setChuken('')
      setTrokbi('')
      setUplhzk('')
      setDataList([])
    } else {
      setChuken(post[0].chuken)
      setTrokbi(post[0].trokbi)
      setUplhzk(post[0].uplhzk)
      if (post[0].dataList === undefined || post[0].dataList.length === 0){
        setDataList([])
        alert('データはありません')
      } else {
        setDataList(post[0].dataList.map((dataRow: rowInterface, index: number) => {
          return {...dataRow, 
            id: index + 1,
          };
        }))
      }
    }
  }, [post]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport               
          csvOptions={{
          utf8WithBom: true,
        }}
      />
      </GridToolbarContainer>
    )
  }

  return (
    <GenericTemplate title="未倉入一覧" >
      <Stack spacing={1} direction="column" >
        <Stack spacing={2} direction="row">
          <SelectKojoCode
            onChange={(event) => setKojoCode(event.target.value)} 
          />
          {/* 検索・ボタン */}
          <ButtonSearch
            apiParam={apiGetParam}
            apiUrl={apiGetUrl.replace('{cdkojyo}', kojoCode)}
            apiKey={apiKey}
            getPost={setPosts}
            disabled={kojoCode===""}
          />
        </Stack>

        <Stack spacing={2} direction="row">
          <div>倉入日：{moment(new Date()).format("YYYYMMDD")}</div>
          <div>注番件数：{chuken}</div>
          <div>出荷指示更新：{trokbi}</div>
          <div>納品情報更新：{uplhzk}</div>
        </Stack>
        {/* DataGrid */}
        <div style={{ height: '100%', width: '100%', }}>
          <Box sx={{ height: 600, width: '100%' }}>
            <DataGrid
              density="compact"
              rows={dataList} 
              columns={columns}
              rowsPerPageOptions={[20, 50, 100]}
              components={{
                Toolbar: CustomToolbar
              }}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              onRowClick={(selRow) => {
                // console.log(selRow)
                setCyumonno(selRow.row.chuban)
                setDigOpen(true)
              }}
              showCellRightBorder
              />
          </Box>
        </div>
      </Stack>
      <P01Put001Konpo005
        open={digOpen}
        rowParam={cyumonno}
        onClose={() => setDigOpen(false)}
      />
    </GenericTemplate>
  )
}

export default P01Put001St002