import { useState, useEffect } from 'react';
import { DataGrid, GridColDef, } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';
import { orderInterface } from '../../pages/aqua/P01Put001Konpo005';

type Props = {
    dataList: orderInterface[]
}

const columns: GridColDef[] = [
    { field: 'kanrno', headerName: '管理番号', width: 80, },
    { field: 'tonykj', headerName: '投入工場', width: 80, },
    { field: 'tonybi', headerName: '投入日', width: 100, },
    { field: 'leadtm', headerName: 'L/T', width: 60, align: 'right', },
    { field: 'shksjb', headerName: '出荷指示日', width: 100, },
    { field: 'setsu', headerName: '数量', width: 100, align: 'right', },
    { field: 'henkob', headerName: '変更日', width: 100, },
    { field: 'kurabi', headerName: '倉入日', width: 100, },
    { field: 'juchub', headerName: '受注日', width: 100, },
    { field: 'ldtmot', headerName: 'L/T_元', width: 60, align: 'right', },
    { field: 'shksjm', headerName: '出荷指示日_元', width: 120, },
    { field: 'rank', headerName: 'ランク', width: 60, },
    { field: 'tnline', headerName: 'TONYULINE', width: 100, },
    { field: 'comupd', headerName: 'コメント更新', width: 160, },
    { field: 'nokimmk', headerName: '納期指定マーク', width: 120, },
    { field: 'sitnok', headerName: '指定納期', width: 100, },
    { field: 'sykbs1', headerName: '出荷指示日_S1', width: 120, },
    { field: 'sykbcy', headerName: 'サイクル出荷日', width: 120, },
];

const DataGridPutOrders = (props: Props,) => {
    // *************
    // 変数定義
    // *************
    //検索結果を管理するuseState
    const [post, setPosts] = useState<orderInterface[]>([])
    const [comment, setCommentPosts] = useState('')

    //画面初期表示
    useEffect(() => {
        // console.debug(props.dataList)
        if (props.dataList.length > 0) {
            setPosts(props.dataList);
            setCommentPosts(props.dataList[0].commnt);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <Stack direction="row" alignItems="baseline">
                <Typography variant="h6" component="h2">
                    【T_受注】
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                <div>コメント：{comment}</div>
                </Typography>
            </Stack>
            <Box sx={{ height: 100, width: '100%' }}>
                <DataGrid 
                    density="compact"
                    rows={post}
                    columns={columns}
                    hideFooter={true}
                    experimentalFeatures={{ newEditingApi: true }}
                    showCellRightBorder
                />
            </Box>
        </div>
    );
};

export default DataGridPutOrders;

