import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { green } from '@mui/material/colors';

// ----------------------------------------------------
// MaterialUiのButton コンポーネント ダイアログ表示ボタン
// ----------------------------------------------------
type Props = {
    buttonName: string;
    dialogUrl: string;
    dialogTarget: string;
    dialogSize: string;
}

const ButtonDialog = (
    props: Props,
): any => {

    //変数定義
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    //ボタン押下時の処理 
    const handleClick = (() => {
        if (!loading) {
            setSuccess(false);
            setLoading(true);
            //apiコールする関数を呼び出す
            window.open(props.dialogUrl, props.dialogTarget,props.dialogSize)
            setSuccess(true);
            setLoading(false);
        }
    })
    const buttonSx = {
        ...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ m: 1, position: 'relative' }}>
                <Button variant="contained"
                    sx={buttonSx}
                    disabled={loading}
                    onClick={() => { handleClick(); }}>
                    {props.buttonName}
                </Button>
            </Box>
        </Box>
    )
}

export default ButtonDialog;