import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';

// ----------------------------------------------------
// MaterialUiのDesktopDatapker コンポーネント 
// ----------------------------------------------------
const FromDesktopDatePicker = ({
  label = "",
  value = new Date(),
  onChange = (event: any) => { onChange(event); }
}) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DesktopDatePicker
      label={label === "" ? "日付" : label}
      inputFormat="yyyy-MM-dd"
      value={Number(value) === 0 ? null : value}
      mask=""
      onChange={v => {
        onChange(v);
      }}
      renderInput={(params) => <TextField {...params} size="small" />}
    />
  </LocalizationProvider>
);

export default FromDesktopDatePicker;