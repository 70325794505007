import React, { useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import requests from '../../utils/stls/Request';
import GenericTemplate from '../../templates/GenericTemplate';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import MUIDataTable from "mui-datatables";
import FromTextFieldTorihikisakicd from '../../components/TextField/TextFieldTorihikisakicd';
import FromTextFieldTorihikisakimei from '../../components/TextField/TextFieldTorihikisakimei';
import { chkHalfWidth, chkEmpty } from '../../common/chkTextValue';
import {Auth} from 'aws-amplify';

const P01Prg001Kata004: React.FC = () => {
  //変数定義
  const [post, setPosts] = useState([])
  //取引先コード
  const [torihikicd, setTorihikicd] = useState('');
  const [torihikicdError, setTorihikicdError] = useState('');
  //取引先名
  const [torihikimei, setTorihikimei] = useState('');
  const [torihikimeiError, setTorihikimeiError] = useState('');

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  //requests.p01Mst001Itemにリクエストする関数。
  const fetchRequest = async() => {
    setPosts([])

    //requestを投げるURL作成
    const URL = process.env.REACT_APP_APIURL_STLS + requests.p01Mst001Vendor + '/' + torihikicd
    const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    axios.get(URL,
      {
        headers: {
          "X-API-KEY": process.env.REACT_APP_APIKEY_STLS || "",
          "Authorization": AuthToken || "",
        },
        params: {
          torihikimei: torihikimei,  //取引先名
        },
      }).then((res) => {
        //成功したら Posts に result を格納。
        setPosts(res.data.result)
        if (res.data.result.length === 0) {
          alert('データはありません')
        }
        console.debug(res.data.result)
      }).catch(error => {
        console.log(error);
        alert('検索に失敗しました')
      }).finally(() => {
        setLoading(false);
      });
  };

  //検索・ボタン押下時の処理 
  const handleClick = (() => {
    let errMsg1 = ""
    let errMsg2 = ""
    //未入力チェック
    errMsg1 = chkEmpty(torihikicd)
    errMsg2 = chkEmpty(torihikimei)
    if (errMsg1.length > 0 && errMsg2.length > 0) {
      setTorihikicdError(errMsg1)
      setTorihikimeiError(errMsg2)
      alert('検索条件を入力してください');
      return;
    }
    //半角文字チェック 
    let errMsg = chkHalfWidth(torihikicd)
    setTorihikicdError('')
    setTorihikimeiError('')
    if (errMsg.length > 0) {
      setTorihikicdError(errMsg)
      alert(errMsg);
      return;
    }

    if (!loading) {
      setSuccess(false);
      setLoading(true);
      //apiコールする関数を呼び出す
      fetchRequest()
      setSuccess(true);
    }
  })

  //入力値チェック処理：取引先コード
  const chkTorihikicd = (event: any) => {
    let val = event.target.value
    setTorihikicdError(chkHalfWidth(val))
    setTorihikicd(val)
  }

  //入力値チェック処理：取引先名
  const chkTorihikimei = (event: any) => {
    let val = event.target.value
    //setTorihikimeiError(chkEmpty(val))
    setTorihikimeiError('')
    setTorihikimei(val)
  }

  const column = [
    { name: "vendor", label: "取引先コード",
      options: {
        setCellProps: () => ({ style: { position: "sticky", left: 0, background: "white", zIndex: 100 } }),
        setCellHeaderProps: () => ({ style: { position: "sticky", left: 0, background: "white", zIndex: 101 } })
      }
    },
    { name: "vend_name_kanji", label: "取引先名（漢字）", },
    { name: "vend_name_kana", label: "取引先名（カナ）", },
    { name: "vend_abbreviation", label: "仕入先略称", },
    { name: "trade_start_date", label: "取引開始日", },
    { name: "trade_stop_date", label: "取引停止日", },
    { name: "confirmed_date", label: "情報確認日", },
    { name: "approval_date", label: "認可区分",
      options: {
        hint: ('△：未認可 １：認可 ２：停止')
      }
    },
    { name: "vend_station", label: "仕入先ステーション", },
    { name: "representative", label: "代表者", },
    { name: "person_in_charge", label: "担当者", },
    { name: "head_office_postalcode", label: "本社郵便番号", },
    { name: "head_office_address", label: "本社住所", },
    { name: "head_office_tel", label: "本社ＴＥＬ", },
    { name: "head_office_fax", label: "本社ＦＡＸ", },
    { name: "store_postalcode", label: "店所郵便番号", },
    { name: "store_address", label: "店所住所", },
    { name: "store_tel", label: "店所ＴＥＬ", },
    { name: "store_fax", label: "店所ＦＡＸ", },
    { name: "offset_type", label: "相殺区分",
      options: {
        hint: ('１：有償売切り ２：手形回収・使用高相殺')
      }
    },
    
    // { name: "industry_classification", label: "業種分類", },
    // { name: "founding_date", label: "創立日", },
    // { name: "capital_stock", label: "資本金", },
    // { name: "number_of_employees", label: "社員数", },
    // { name: "annual_turnover_start_date", label: "前期開始日", },
    // { name: "annual_turnover_end_date", label: "前期終了日", },
    // { name: "annual_turnover_money", label: "前期年商額", },
    // { name: "pre_annual_turnover_start_date", label: "前々期開始日", },
    // { name: "pre_annual_turnover_end_date", label: "前々期終了日", },
    // { name: "pre_annual_turnover_money", label: "前々期年商額", },
    // { name: "tax_type", label: "税種区分",
    //   options: {
    //     hint: ('０：課税業者 １：簡易 ２：免税 ３：非課税')
    //   }
    // },
    // { name: "share", label: "占有率", },
    // { name: "basic_agreement_no", label: "基本契約Ｎｏ", },
    // { name: "pay_type1", label: "支払区分1", },
    // { name: "pay_type2", label: "支払区分2", },
    // { name: "pay_type3", label: "支払区分3", },
    // { name: "pay_type4", label: "支払区分4", },
    // { name: "pay_type5", label: "支払区分5", },
    // { name: "pay_type6", label: "支払区分6", },
    // { name: "pay_type7", label: "支払区分7", },
    // { name: "pay_type8", label: "支払区分8", },
    // { name: "pay_type9", label: "支払区分9", },
    // { name: "pay_type10", label: "支払区分10", },
    // { name: "tax_calc_purchase", label: "税計算・仕入", },
    // { name: "tax_disp_purchase", label: "税表示・仕入", },
    // { name: "tax_odd_purchase", label: "税端数・仕入", },
    // { name: "tax_calc_issue", label: "税計算・支給", },
    // { name: "tax_disp_issue", label: "税表示・支給", },
    // { name: "tax_odd_issue", label: "税端数・支給", },
    // { name: "reserve2", label: "予備２", },
    // { name: "terminal_use_type", label: "端末使用区分", },
    // { name: "terminal_use_start_date", label: "端末使用開始日", },
    // { name: "data_transmission_type", label: "データ伝送区分", },
    // { name: "data_transmission_start_date", label: "データ伝送開始日", },
  ];

  return (
    <GenericTemplate title="" >
      <Stack spacing={1} direction="column" >
        <Stack spacing={2} direction="row">
          {/* 取引先コード */}
          <FromTextFieldTorihikisakicd onChange={chkTorihikicd}
            helperText={torihikicdError}
          />
          {/* 取引先名 */}
          <FromTextFieldTorihikisakimei onChange={chkTorihikimei}
            helperText={torihikimeiError}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ m: 1, position: 'relative' }}>
              <Button variant="contained"
                sx={buttonSx}
                disabled={loading}
                onClick={() => { handleClick(); }}>
                検索
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
          </Box>
        </Stack>
        <div style={{ height: '50%', width: '100%', }}>
          <MUIDataTable
            title="購入先(stls)"
            data={post}
            columns={column}
            options={{
              selectableRows: 'none',
              rowsPerPage: 20,
              viewColumns: false,
              rowsPerPageOptions: [20, 50, 100],
              sort: false,
              print: false,
              setTableProps: () => {
                return {
                  // padding: 'none',
                  size: "small",
                  style: { whiteSpace: "nowrap" }
                };
              },
            }}
          />
        </div>
      </Stack>
    </GenericTemplate>
  )
}

export default P01Prg001Kata004