import TextField from '@mui/material/TextField';

const FromTextField =  ({
  label = "色コード",
  helperText = "",
  onChange = (event: any) => { onChange(event); }
}) => (
  <TextField id="tfColor"
    label={label}
    size="small"
    inputProps={{ maxLength: 4 }}
    onChange={(event: any) => { onChange(event); }}
    error={!!helperText}
    helperText={helperText}
  />
);

export default FromTextField;