import React from 'react';
import Radio from '@mui/material/Radio';

// ----------------------------------------------------
// MaterialUiの Radio コンポーネント 
// ----------------------------------------------------
const RadioAcceptance = (
    props: any
) => {    
    //未受入のみ
    const [selectedValue, setSelectedValue] = React.useState('true');

    const handleChangex = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
        props.setSelectedValue(event.target.value);
    };

    return (
        <div>
            <text>
                <Radio
                    checked={selectedValue === 'true'}
                    onChange={handleChangex}
                    value="true"
                    name="未受入のみ"
                    inputProps={{ 'aria-label': 'TRUE' }}
                />
                未受入のみ
            </text>
            <text>
                <Radio
                    checked={selectedValue === 'false'}
                    onChange={handleChangex}
                    value="false"
                    name="全て"
                    inputProps={{ 'aria-label': 'FALSE' }}
                />
                全て
            </text>
        </div>
    );
}
    ;

export default RadioAcceptance;

