import { Route, Routes } from 'react-router-dom';
import MstUser from '../../pages/config/P01Mst001Permission001';
import MstRole from '../../pages/config/P01Mst001Permission002';
import MstInfo from '../../pages/config/P01Mst001Permission003';

export default function RoutesConfig() {
  return (
    <Routes>
        <Route path="/config/MstUser" element={<MstUser/>} />
        <Route path="/config/MstRole" element={<MstRole/>} />
        <Route path="/config/MstInfo" element={<MstInfo/>} />
    </Routes>
    );
};