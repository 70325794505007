import React, { useState, useRef } from 'react';
import GenericTemplate from '../../templates/GenericTemplate';
import Stack from '@mui/material/Stack';
import ReactToPrint from 'react-to-print';
import Box from '@mui/material/Box';
import requests from '../../utils/aqua/Request';
import Button from '@mui/material/Button';
import moment from 'moment';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import FromDesktopDatePickerTounyubi from '../../components/DesktopDatePicker/DesktopDatePickerTounyubi';
import SelectKumitateKahan from '../../components/Select/SelectKumitatekahan';
import Grid from '@mui/material/Grid';
import ButtonSearch from '../../components/Button/ButtonSearch';

const P01Prg001Kata003: React.FC = () => {

  //変数定義
  //投入日
  const [startdtbtyx, setstartDtbyt] = useState<Date | undefined>(new Date());
  //reacttoprintのコンポーネント
  const componentRef = useRef(null);
  //組立課班をAPIにpostする変数
  const [kahanx, setkahan] = useState('');
  //getメソッドより受け取る変数
  const [post, setPosts] = useState<postArr[]>([]);

  //getメソッドより受け取る変数の型定義
  type postArr = {
    kumitatekahan: string,
    racklist: rackNotype[],
  };

  type rackNotype = {
    rackNo: string,
  };

  // api 用のパラメータを設定する
  const apiUrl = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Prg001lacklist)
  const apiKey = String(process.env.REACT_APP_APIKEY_AP00447)
  const apiParam = {
    dtbty: moment(startdtbtyx).format("YYYY-MM-DD"),
    kumitatekahan: kahanx,
  };

  class ComponentToPrint extends React.Component {
    render() {
      //印刷日を取得
      const now = new Date();
      const printdate = (now.getFullYear() + "年" + (now.getMonth() + 1) + "月" + now.getDate() + "日");
      return (
        <div className='pdf_page'>
          {post.map((text, index) =>
            <div className='pdf_page'>
              <div style={{ textAlign: 'center', fontSize: '50px', paddingTop: '10px' }}>選別台車集計</div>
              <div style={{ fontSize: '50px', left: '20px' }}>投入日　　：{moment(startdtbtyx).format("YYYY-MM-DD")}</div>
              <div style={{ fontSize: '50px' }}>組立課・班：{text.kumitatekahan}</div>
              <hr className='pdf_hr_page' />
              <div style={{ fontSize: '50px' }}>ラックNo</div>
              <Box sx={{ display: 'flex' }}>
                <Grid container alignItems="center">
                  {text.racklist.map((data) =>
                    <Box component="span" sx={{ padding: 1, fontSize: '52px' }}>
                      {data}
                    </Box>
                  )}
                </Grid>
              </Box>
              <div className='pdf_pagecount_footer'>ページ数：{index + 1 + '/' + post.length}</div>
              <div className='pdf_id_footer'>ap00447-aa02</div>
              <div className='pdf_printdate_footer'>印刷日：{printdate}</div>
              <div className='pdf_next_page'></div>
            </div>
          )}
          <style>
            {`
                .pdf_page {
                  position: relative;
                  size: A4;
                  margin-right: 10px;
                  margin-left: 10px;
                }
                .pdf_next_page {
                  break-before: page;
                  border: none;
                }
                .pdf_hr_page {
                  height: 5px;
                  background-color: black;
                  border: none;
                  right: 20px;
                }
                .pdf_pagecount_footer {
                  fontsize: 50px;
                  text-align: center;
                  position: absolute;
                  bottom: 20px;
                }
                .pdf_id_footer {
                  fontsize: 50px;
                  text-align: center;
                  position: absolute;
                  bottom: 20px;
                  left: 320px;
                }
                .pdf_printdate_footer {
                  fontsize: 50px;
                  text-align: center;
                  position: absolute;
                  bottom: 20px;
                  right: 0px;
                }
              `}
          </style>
        </div>
      );
    }
  }

  return (
    <GenericTemplate title="" >
      <Stack spacing={1} direction="column" >
        <Stack spacing={2} direction="row">
          {/*  投入予定日 */}
          <FromDesktopDatePickerTounyubi
            value={startdtbtyx}
            onChange={(newDtbty: any) => { setstartDtbyt(newDtbty); }}
          />
          {/*  組立課班 */}
          <SelectKumitateKahan onChange={(event) => setkahan(event.target.value)} />
          {/* 羂索・ボタン */}
          <ButtonSearch
            apiParam={apiParam}
            apiUrl={apiUrl}
            apiKey={apiKey}
            getPost={setPosts}
          />
          <ReactToPrint
            trigger={() => (
              <Button>
                印刷
              </Button>
            )}
            content={() => componentRef.current}
            documentTitle={'選別台車集計'}
          />
          <div style={{ display: 'none' }}>
            <ComponentToPrint ref={componentRef} />
          </div>
        </Stack>
      </Stack>
      <Container maxWidth='md'>
        <Card sx={{ minWidth: 150 }}>
          {post.map((text) =>
            <CardContent sx={{ margin: 1, border: 1 }} key={text.kumitatekahan}>
              <Typography gutterBottom variant="h2" component="div">
                組立課・班
              </Typography>
              <Typography gutterBottom variant="h2" component="div">
                {text.kumitatekahan}
              </Typography>
              <Typography gutterBottom variant="h2" component="div">
                ラックNo
              </Typography>
              <Box sx={{ display: 'flex' }}>
                <Grid container>
                  {text.racklist.map((data) =>
                    <Box component="span" sx={{ padding: 2, fontSize: '52px', }}>
                      {data}
                    </Box>
                  )}
                </Grid>
              </Box>
            </CardContent>
          )}
        </Card>
      </Container>

    </GenericTemplate>
  )
}

export default P01Prg001Kata003