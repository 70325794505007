import React from 'react';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import FactoryIcon from '@mui/icons-material/Factory';
import { Link } from "react-router-dom";
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

// グローバル変数
let reversedArr: string[] = [];

export default function SidebarMonoss() {
  // イベントで利用する変数
  let selectnodeid: string[] = [];

  const nodeselect = (event: React.SyntheticEvent, nodeid: Array<string>) => {
    // ゲットしたnodeidを配列の変数へpush
    selectnodeid = nodeid
    // 配列の変数をmap関数で上下逆にする
    reversedArr = selectnodeid.map((_, i, a) => a[a.length - 1 - i])
  };

  return (
    <div>
      <TreeView
        aria-label="title"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultParentIcon={<FactoryIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        onNodeToggle={nodeselect}
        defaultExpanded={reversedArr}
      >
        <TreeItem nodeId="1" label="monos生産">
          {/* TreeItemに追加時はnodeIdを一意にする */}
          <TreeItem nodeId="2" label="マスター">
            <TreeItem nodeId="3" label="部品">
              <List component="div" disablePadding>
                <Link to="/monoss/MstStructurematerial" style={{ textDecoration: 'none', color: 'black' }}>
                  <ListItemText primary="構成材" secondary="ma01" />
                </Link>
              </List>
            </TreeItem>
            {/* REACT_APP_FEACHERFLAG_202309R087_01 */}
            {process.env.REACT_APP_FEACHERFLAG_202309R087_01 === "true" &&            
            <TreeItem nodeId="4" label="販売管理">
              <List component="div" disablePadding>
                <Link to="/monoss/MstCostByHonbu" style={{ textDecoration: 'none', color: 'black' }}>
                  <ListItemText primary="本部別原価センター" secondary="mb01" />
                </Link>
                <Link to="/monoss/MstCostByLine" style={{ textDecoration: 'none', color: 'black' }}>
                  <ListItemText primary="ライン別原価センター" secondary="mb02" />
                </Link>
              </List>
            </TreeItem>
            }
          </TreeItem>
        </TreeItem>
      </TreeView>
    </div>
  );
}