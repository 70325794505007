import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import GenericTemplate from '../../templates/GenericTemplate';
import requests from '../../utils/aqua/Request';
import { userAttributes } from '../../App';
import FromTextFieldBukkenNo from '../../components/TextField/TextBukkenNo';
import FromTextFieldSyusekiNo from '../../components/TextField/TextFieldSyusekiNo';
import ButtonSearch from '../../components/Button/ButtonSearch';
import ButtonUpdate from '../../components/Button/ButtonUpdate';
import ButtonPrint from '../../components/Button/ButtonPrint';
import ReportBa01R02 from '../../components/Report/aqua/ReportBa01R02';
import ReportBa01R03 from '../../components/Report/aqua/ReportBa01R03';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Modal from '@material-ui/core/Modal';

interface rowInterface {
  id: string;
  rownum: number;
  BukkenNo: string;
  TonyuKanriNo: string;
  Kojimei: string;
  SyusekiNo: string;
  KbKensa: string;
  Madoban: string;
  KumitateUnit: string;
  Setsu: string;
  Kanryosu: string;
  CheckedDate: string;
  FirstQaDate: string;
  FirstQa_ja: string;
  SecondQaDate: string;
  SecondQa_ja: string;
  Kamikotei: string;
  GKns01: string; GKns02: string; GKns03: string; GKns04: string; GKns05: string; GKns06: string; GKns07: string; GKns08: string; GKns09: string; GKns10: string;
  KKns01: string; KKns02: string; KKns03: string; KKns04: string; KKns05: string; KKns06: string; KKns07: string;
  SKns01: string; SKns02: string; SKns03: string; SKns04: string; SKns05: string; SKns06: string; SKns07: string; SKns08: string; SKns09: string; SKns10: string;
  Yojo: string; Hantei: string; Biko: string | null;
}

interface authResultInterface {
  authorizeType: string;
  totalCount: number;
  success: {
    count: number;
    records?: {
      id: string;
      rownum: number;
      authorizedDate: string;
      authorizer_ja: string;
    }[];
  }
  fail: {
    count: number;
    records?: {
      id: string;
      rownum: number;
      authorizedDate: string;
      authorizer_ja: string;
    }[];
  }
}

const P01Ins001Ase001: React.FC = () => {
  //*************
  //変数定義
  //*************
  //検索結果を管理するuseState
  const [post, setPosts] = useState<rowInterface[]>([])
  //検索結果よりdatagridに表示しているデータを管理するuseState
  const [dataGridData, setDataGridData] = useState<rowInterface[]>([])
  //MUIDatagridのフィルタを管理するuseState
  const [selectionModel, setSelectionModel] = React.useState<rowInterface[]>([]);
  //更新結果を管理するuseState
  const [authResult, setAuthResult] = useState<authResultInterface>(
    {
      authorizeType: "",
      totalCount: 0,
      success: { count: 0 },
      fail: { count: 0 }
    }
  )
  //datagridのチェックボックスを管理するuseState
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  //datagridのチェックボックスのチェックしたかどうか管理するuseState
  const [selectCheck, setSelectCheck] = React.useState(false);
  //更新権限をチェックする(Sagyoshien1stApprover, Sagyoshien2ndApprover ロールが付与されていれば、更新可能)
  const userAtt = useContext(userAttributes)
  //担当者権限
  let regexp1 = /InspectionReportPreparer/
  const InspectionReportPreparer = regexp1.test((userAtt as any).stinqRoles["STINQAQUA"])
  //責任者権限
  let regexp2 = /InspectionReportManager/
  const InspectionReportManager = regexp2.test((userAtt as any).stinqRoles["STINQAQUA"])
  //検索条件をセットする変数
  const [BukkenNo, setBukkenNo] = useState(""); //物件番号
  const [SyusekiNo, setSyusekiNo] = useState(""); //集積番号
  const [kojimei, setKojimei] = useState(""); //工事名
  //datagridのeditableを管理するステート変数
  const [editableState, setEditableState] = useState(true);
  //モーダルウィンドウを開いた際のステート変数
  const [open, setOpen] = useState(false);
  //モーダルウィンドウのスタイル変数
  const style = { position: 'absolute' as 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 800, bgcolor: 'background.paper', p: 4};
  //API 用のパラメータを設定する
  const apiKey = String(process.env.REACT_APP_APIKEY_AP00447)
  //APIのget 時のパラメータを指定
  const apiGetUrl1 = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Ins003List) 
  const apiGetParam = {
    BukkenNo: BukkenNo,
    SyusekiNo: SyusekiNo
  };
  // APIのput 時のパラメータを指定
  const apiPutUrl1 = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Ins003Auth)
  const apiPutUrl2 = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Ins003unAuth)
  const apiPutUrl4 = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Ins003ManegerUnAuth)
  const apiPutParam = {
    requests: selectionModel.map((dataGridRow) => {
      return {
        "BukkenNo": dataGridRow.BukkenNo,
        "SyusekiNo": dataGridRow.SyusekiNo,
        "Madoban": dataGridRow.Madoban,
        "KumitateUnit": dataGridRow.KumitateUnit,
      }
    })
  };
  const apiPutUrl3 = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Ins003Change)
  const apiPutParam3 = {
    requests: selectionModel.map((dataGridRow) => {
      return {
        "BukkenNo": dataGridRow.BukkenNo,
        "SyusekiNo": dataGridRow.SyusekiNo,
        "Madoban": dataGridRow.Madoban,
        "KumitateUnit": dataGridRow.KumitateUnit,
        "Kamikotei": dataGridRow.Kamikotei,
        "CheckedDate": dataGridRow.CheckedDate,
        "GKns01": dataGridRow.GKns01, "GKns02": dataGridRow.GKns02, "GKns03": dataGridRow.GKns03, "GKns04": dataGridRow.GKns04, "GKns05": dataGridRow.GKns05, "GKns06": dataGridRow.GKns06, "GKns07": dataGridRow.GKns07, "GKns08": dataGridRow.GKns08, "GKns09": dataGridRow.GKns09, "GKns10": dataGridRow.GKns10,
        "KKns01": dataGridRow.KKns01, "KKns02": dataGridRow.KKns02, "KKns03": dataGridRow.KKns03, "KKns04": dataGridRow.KKns04, "KKns05": dataGridRow.KKns05, "KKns06": dataGridRow.KKns06, "KKns07": dataGridRow.KKns07,
        "SKns01": dataGridRow.SKns01, "SKns02": dataGridRow.SKns02, "SKns03": dataGridRow.SKns03, "SKns04": dataGridRow.SKns04, "SKns05": dataGridRow.SKns05, "SKns06": dataGridRow.SKns06, "SKns07": dataGridRow.SKns07, "SKns08": dataGridRow.SKns08, "SKns09": dataGridRow.SKns09, "SKns10": dataGridRow.SKns10,
        "Yojo": dataGridRow.Yojo,
        "Hantei": dataGridRow.Hantei,
        "Biko": dataGridRow.Biko
      }
    })
  };

  const [filter, setFilter] = React.useState<any>([
    [{ columnField: '', operatorValue: '', value: '' }]
  ]);
  //表示対象・セレクトのuseState
  const [selected, setSelected] = React.useState("1");
  const selectChange = (SelectChangeEvent: any) => {
    setSelected(SelectChangeEvent.target.value);
    if (SelectChangeEvent.target.value === 1) {
      setFilter([{ columnField: '', operatorValue: '', value: '' }])
    } else if (SelectChangeEvent.target.value === 2) {
      setFilter([{ columnField: 'FirstQaDate', operatorValue: 'isEmpty' }])
    } else if (SelectChangeEvent.target.value === 3) {
      setFilter([{ columnField: 'FirstQaDate', operatorValue: 'isNotEmpty' }])
    } else if (SelectChangeEvent.target.value === 4) {
      setFilter([{ columnField: 'SecondQaDate', operatorValue: 'isEmpty' }])
    } else {
      setFilter([{ columnField: 'SecondQaDate', operatorValue: 'isNotEmpty' }])
    }
  };

  //検査項目表示・チェッカーのuseState
  const [checked_InspectionItem, setChecked_InspectionItem] = React.useState(false);
  const handleChange_InspectionItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked_InspectionItem(event.target.checked);
    if (event.target.checked) {
      setChecked_ReportItem(false)
    }
  };

  //成績書項目表示・チェッカーのuseState
  const [checked_ReportItem, setChecked_ReportItem] = React.useState(false);
  const handleChange_ReportItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked_ReportItem(event.target.checked);
    if (event.target.checked) {
      setChecked_InspectionItem(false)
    }
  };

  //成績書項目表示と検査項目表示のチェッカーの判定
  function chkChecked() {
    return checked_InspectionItem || checked_ReportItem ? true : false
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
      </GridToolbarContainer>
    )
  }
  // 帳票のタイトルを変数セット
  const DocTitle = moment(new Date()).format("YYYY-MM-DD") + '_完成品検査成績書';
  const componentRef = useRef(null);
  const componentSelRef = useRef(null);
  const componentRefCW = useRef(null);
  const componentSelRefCW = useRef(null);

  const columns: GridColDef[] = [
    { field: 'BukkenNo', headerName: '物件番号', width: 150, },
    { field: 'SyusekiNo', headerName: '集積番号', width: 100, },
    { field: 'TonyuKanriNo', headerName: '投入管理番号', width: 120, },
    { field: 'Kojimei', headerName: '工事名', width: 200, },
    { field: 'Madoban', headerName: '窓番/部材番号', width: 120, },
    { field: 'CheckedDate', headerName: '組立検査日', width: 120, editable: editableState},
    { field: 'FirstQaDate', headerName: '担当者承認日', width: 120, },
    { field: 'FirstQa_ja', headerName: '担当者', width: 110, },
    { field: 'SecondQaDate', headerName: '責任者承認日', width: 120, },
    { field: 'SecondQa_ja', headerName: '責任者', width: 110, },
    { field: 'Kamikotei', headerName: '上工程品質確認', width: 110, editable: editableState},
    { field: 'SKns01', headerName: '幅上（枠の内のり）', width: 110, editable: editableState, },
    { field: 'SKns02', headerName: '幅下（枠の内のり）', width: 110, editable: editableState, },
    { field: 'SKns03', headerName: '高さ左（枠の内のり）', width: 110, editable: editableState, },
    { field: 'SKns04', headerName: '高さ右（枠の内のり）', width: 110, editable: editableState, },
    { field: 'SKns05', headerName: '寸法の差（枠対辺内のり）', width: 110, editable: editableState, },
    { field: 'SKns06', headerName: '枠見込み', width: 110, editable: editableState, },
    { field: 'SKns07', headerName: '取付位置（無目・中骨）', width: 110, editable: editableState, },
    { field: 'SKns08', headerName: '隙間寸法幅（障子と枠）', width: 110, editable: editableState, },
    { field: 'SKns09', headerName: '隙間寸法高さ（障子と枠）', width: 110, editable: editableState, },
    { field: 'SKns10', headerName: '取付位置（施錠部品）', width: 110, editable: editableState, },
    { field: 'GKns01', headerName: '色調', width: 110, editable: editableState, },
    { field: 'GKns02', headerName: 'カエリ（キズ）', width: 110, editable: editableState, },
    { field: 'GKns03', headerName: '隙間（目違い）', width: 110, editable: editableState, },
    { field: 'GKns04', headerName: '取付状態（補助補強材）', width: 110, editable: editableState, },
    { field: 'GKns05', headerName: 'かみ合せ（シーラー）', width: 110, editable: editableState, },
    { field: 'GKns06', headerName: '状態（シーリング）', width: 110, editable: editableState, },
    { field: 'GKns07', headerName: '裏打ち状態', width: 110, editable: editableState, },
    { field: 'GKns08', headerName: '防火戸証紙', width: 110, editable: editableState, },
    { field: 'GKns09', headerName: 'BL証紙', width: 110, editable: editableState, },
    { field: 'GKns10', headerName: 'その他ラベル', width: 110, editable: editableState, },
    { field: 'KKns01', headerName: '施錠開閉状態', width: 110, editable: editableState, },
    { field: 'KKns02', headerName: '勝手確認', width: 110, editable: editableState, },
    { field: 'KKns03', headerName: 'タイト状態', width: 110, editable: editableState, },
    { field: 'KKns04', headerName: 'かかりしろ（建具状態）', width: 110, editable: editableState, },
    { field: 'KKns05', headerName: '機能状態（金具・部品）', width: 110, editable: editableState, },
    { field: 'KKns06', headerName: '確認（水抜状態）', width: 110, editable: editableState, },
    { field: 'KKns07', headerName: '角度・斜辺（曲げ）', width: 110, editable: editableState, },
    { field: 'Yojo', headerName: '養生', width: 110, editable: editableState, },
    { field: 'Hantei', headerName: '判定', width: 110, editable: editableState, },
    { field: 'Biko', headerName: '処置', width: 110, editable: editableState, },
  ];
  
  //datagridのRowをクリックした際のイベント
  const handleRowClick = (params: any) => {
    //クリックした行のインデックスを取得
    const clickedRowIndex = dataGridData.findIndex(row => row.id === params.row.id);
    //クリックした行の責任者が承認されていない、責任者の権限の場合は編集できるようにする
    (post[clickedRowIndex].SecondQa_ja === null || post[clickedRowIndex].SecondQa_ja === '') && InspectionReportManager === false ? setEditableState(true) : setEditableState(false)
  };

  // authResult Stateが更新された際にDOMの書き換え処理を実行する
  useEffect(() => {
    //datagridのチェックボックス用のuseStateを初期化
    setSelectedIds(['']);
    if (authResult.success.count > 0) {
      const newList: any = dataGridData.map((postx: rowInterface) => {
        // 更新対象をキー(物件番号#集積番号#窓番#組み立てユニット)でフィルターする
        const updateTarget: any = authResult.success.records !== undefined && authResult.success.records.filter(value => {
          return value.id === postx.id
        })
        //更新対象が存在する場合、承認日、承認者日本語を更新する
        if (updateTarget.length) {
          return {
            "id": postx.id,
            "rownum": postx.rownum,
            "BukkenNo": postx.BukkenNo, "Kojimei": postx.Kojimei, "SyusekiNo": postx.SyusekiNo,
            "TonyuKanriNo": postx.TonyuKanriNo,
            "KbKensa": postx.KbKensa,
            "Madoban": postx.Madoban,
            "KumitateUnit": postx.KumitateUnit,
            "Setsu": postx.Setsu, "Kanryosu": postx.Kanryosu,
            "CheckedDate": postx.CheckedDate,
            "FirstQaDate": authResult.authorizeType === 'FirstAuthorize' ? updateTarget[0].authorizedDate : postx.FirstQaDate,
            "FirstQa_ja": authResult.authorizeType === 'FirstAuthorize' ? updateTarget[0].authorizer_ja : postx.FirstQa_ja,
            "SecondQaDate": authResult.authorizeType === 'SecondAuthorize' ? updateTarget[0].authorizedDate : postx.SecondQaDate,
            "SecondQa_ja": authResult.authorizeType === 'SecondAuthorize' ? updateTarget[0].authorizer_ja : postx.SecondQa_ja,
            "Kamikotei": postx.Kamikotei,
            "GKns01": postx.GKns01, "GKns02": postx.GKns02, "GKns03": postx.GKns03, "GKns04": postx.GKns04, "GKns05": postx.GKns05, "GKns06": postx.GKns06, "GKns07": postx.GKns07, "GKns08": postx.GKns08, "GKns09": postx.GKns09, "GKns10": postx.GKns10,
            "KKns01": postx.KKns01, "KKns02": postx.KKns02, "KKns03": postx.KKns03, "KKns04": postx.KKns04, "KKns05": postx.KKns05, "KKns06": postx.KKns06, "KKns07": postx.KKns07,
            "SKns01": postx.SKns01, "SKns02": postx.SKns02, "SKns03": postx.SKns03, "SKns04": postx.SKns04, "SKns05": postx.SKns05, "SKns06": postx.SKns06, "SKns07": postx.SKns07, "SKns08": postx.SKns08, "SKns09": postx.SKns09, "SKns10": postx.SKns10,
            "Yojo": postx.Yojo,
            "Hantei": postx.Hantei,
            "Biko": postx.Biko
          }
        } else {
          return postx
        }
      }
      );
      setDataGridData(newList)
    }
  }, [authResult]);

  //postが更新
  //datagridのrowsを表示、更新結果を管理するuseStateを初期化
  useEffect(() => {
    //datagridのrows用のuseStateをpostで書き換え
    setDataGridData(post);
    //更新結果を管理するuseStateを初期化
    setAuthResult({
      authorizeType: "",
      totalCount: 0,
      success: { count: 0 },
      fail: { count: 0 }
    })
  }, [post]);

  //datagridのチェックが更新
  //チェックされたデータがない場合にselectCheckをfalse、ある場合にtrueにする
  useEffect(() => {
    selectionModel.length === 0 ? setSelectCheck(false) : setSelectCheck(true)
  }, [selectionModel]);

  //TODO 入力値チェック
  const handleProcessRowUpdate = (newRow:rowInterface, oldRow:rowInterface) => {
    //変更なし
    if (JSON.stringify(newRow)===JSON.stringify(oldRow)) {
      return newRow;
    }

    //外観検査、機能検査用
    const oneToChecked = function (val:any) { return val === '1' || val === '✔' ? '✔' : '／'; }
    //外観検査(ラベル)用
    const seisuToVal = function (val:any) { return val.match(/^([1-9]\d*|0)$/) && val > 0 ? val : '／'; }
    //寸法検査用1
    const numberToVal1 = function (val:any) {return val.match(/^(\+2\.5|\+2\.0|\+1\.5|\+1\.0|\+0\.5|0|-0\.5|-1\.0|-1\.5|-2\.0|-2\.5)$/) ? val : '／'; }
    //寸法検査用2
    const numberToVal2 = function (val:any) {return val.match(/^(4\.0|3\.5|3\.0|2\.5|2\.0|1\.5|1\.0|0\.5|0|)$/) ? val : '／'; }
    //寸法検査用3
    const numberToVal3 = function (val:any) {return val.match(/^(\+1\.0|\+0\.5|0|-0\.5|-1\.0)$/) ? val : '／'; }
    //組立検査日用
    const validateDateFormat = function(val:any)
    {
      //正規表現を使って形式のチェック
      if (/^\d{4}-\d{2}-\d{2}$/.test(val)) {
        //ハイフンを除去した数値のみの文字列を作成
        const numOnly = val.replace(/-/g, '')
        if (/^\d+$/.test(numOnly)) {
          return val
        }
      }
      return ''
    }

    newRow.CheckedDate = validateDateFormat(newRow.CheckedDate)

    newRow.Kamikotei = oneToChecked(newRow.Kamikotei)
    newRow.GKns01 = oneToChecked(newRow.GKns01)
    newRow.GKns02 = oneToChecked(newRow.GKns02)
    newRow.GKns03 = oneToChecked(newRow.GKns03)
    newRow.GKns04 = oneToChecked(newRow.GKns04)
    newRow.GKns05 = oneToChecked(newRow.GKns05)
    newRow.GKns06 = oneToChecked(newRow.GKns06)
    newRow.GKns07 = oneToChecked(newRow.GKns07)
    newRow.GKns08 = seisuToVal(newRow.GKns08)
    newRow.GKns09 = seisuToVal(newRow.GKns09)
    newRow.GKns10 = oneToChecked(newRow.GKns10)

    newRow.KKns01 = oneToChecked(newRow.KKns01)
    newRow.KKns02 = oneToChecked(newRow.KKns02)
    newRow.KKns03 = oneToChecked(newRow.KKns03)
    newRow.KKns04 = oneToChecked(newRow.KKns04)
    newRow.KKns05 = oneToChecked(newRow.KKns05)
    newRow.KKns06 = oneToChecked(newRow.KKns06)
    newRow.KKns07 = oneToChecked(newRow.KKns07)

    newRow.SKns01 = numberToVal1(newRow.SKns01)
    newRow.SKns02 = numberToVal1(newRow.SKns02)
    newRow.SKns03 = numberToVal1(newRow.SKns03)
    newRow.SKns04 = numberToVal1(newRow.SKns04)
    newRow.SKns05 = numberToVal2(newRow.SKns05)
    newRow.SKns06 = numberToVal1(newRow.SKns06)
    newRow.SKns07 = numberToVal3(newRow.SKns07)
    newRow.SKns08 = numberToVal3(newRow.SKns08)
    newRow.SKns09 = numberToVal3(newRow.SKns09)
    newRow.SKns10 = numberToVal3(newRow.SKns10)
    
    newRow.Yojo = oneToChecked(newRow.Yojo)
    newRow.Hantei = newRow.Hantei === '1' || newRow.Hantei === '〇' ? '〇' : '／'
    
    const updateSelectionModel = (newRow: any) => {
      setSelectionModel(prevSelectionModel => {
        const updatedModel = prevSelectionModel.map((item) => {
          if (newRow.id === item.id) {
            return newRow;
          }
          return item;
        });
        return updatedModel;
      });
    };

    updateSelectionModel(newRow)

    //変更内容を反映
    setDataGridData(
      dataGridData.map((dataRow) => {
        if (dataRow.id === newRow.id) {
          return newRow;
        } else {
          return dataRow;
        }
      })
    )
    return newRow;
  };

  
  //印刷ボタンを押した際のイベント
  const printButtonClick = () => {
    setKojimei('')
    setOpen(true);
  }

  //モーダルウィンドウを閉じた際のイベント
  const modalClose = () => {
    setKojimei('')
    setOpen(false);
  }

  //工事名を編集した際のイベント
  const kojimeiChange = (event: any) => {
    setKojimei(event.target.value)

    //datagridでチェックしたデータがある場合
    if (selectCheck === true)
    {
      //セレクトされたデータを管理しているステート変数を修正する
      const updatedSelection = selectionModel.map((item) => ({
        ...item,
        Kojimei: event.target.value,
      }));
      setSelectionModel(updatedSelection);
    }
    else
    {
      //datagridのデータを管理しているステート変数を修正する
      const updatedSelection = dataGridData.map((item) => ({
        ...item,
        Kojimei: event.target.value,
      }));
      setDataGridData(updatedSelection);
    }
  }

  // let columnVisibilityModel = {
  //       Kamikotei: checked_InspectionItem,
  //       SKns01: chkChecked(), SKns02: chkChecked(), SKns03: chkChecked(), SKns04: chkChecked(), SKns05: chkChecked(), SKns06: chkChecked(), SKns07: checked_InspectionItem, SKns08: checked_InspectionItem, SKns09: checked_InspectionItem, SKns10: checked_InspectionItem,
  //       GKns01: chkChecked(), GKns02: chkChecked(), GKns03: chkChecked(), GKns04: checked_InspectionItem, GKns05: chkChecked(), GKns06: chkChecked(), GKns07: checked_InspectionItem, GKns08: chkChecked(), GKns09: chkChecked(), GKns10: chkChecked(),
  //       KKns01: chkChecked(), KKns02: checked_InspectionItem, KKns03: chkChecked(), KKns04: chkChecked(), KKns05: chkChecked(), KKns06: checked_InspectionItem, KKns07: checked_InspectionItem,
  //       Yojo: checked_InspectionItem, Hantei: chkChecked(), Biko: checked_InspectionItem,  
  //     }

  // if (dataGridData[0].KbKensa === '2')
  // {
  //   columnVisibilityModel = {
  //     Kamikotei: checked_InspectionItem,
  //     SKns01: chkChecked(), SKns02: checked_InspectionItem, SKns03: checked_InspectionItem, SKns04: checked_InspectionItem, SKns05: checked_InspectionItem, SKns06: checked_InspectionItem, SKns07: checked_InspectionItem, SKns08: checked_InspectionItem, SKns09: checked_InspectionItem, SKns10: checked_InspectionItem,
  //     GKns01: chkChecked(), GKns02: chkChecked(), GKns03: checked_InspectionItem, GKns04: checked_InspectionItem, GKns05: checked_InspectionItem, GKns06: chkChecked(), GKns07: checked_InspectionItem, GKns08: checked_InspectionItem, GKns09: checked_InspectionItem, GKns10: checked_InspectionItem,
  //     KKns01: checked_InspectionItem, KKns02: checked_InspectionItem, KKns03: chkChecked(), KKns04: checked_InspectionItem, KKns05: checked_InspectionItem, KKns06: checked_InspectionItem, KKns07: checked_InspectionItem,
  //     Yojo: checked_InspectionItem, Hantei: chkChecked(), Biko: checked_InspectionItem,  
  //   }
  // }
  // else
  // {
  //   columnVisibilityModel = {
  //     Kamikotei: checked_InspectionItem,
  //     SKns01: chkChecked(), SKns02: chkChecked(), SKns03: chkChecked(), SKns04: chkChecked(), SKns05: chkChecked(), SKns06: chkChecked(), SKns07: checked_InspectionItem, SKns08: checked_InspectionItem, SKns09: checked_InspectionItem, SKns10: checked_InspectionItem,
  //     GKns01: chkChecked(), GKns02: chkChecked(), GKns03: chkChecked(), GKns04: checked_InspectionItem, GKns05: chkChecked(), GKns06: chkChecked(), GKns07: checked_InspectionItem, GKns08: chkChecked(), GKns09: chkChecked(), GKns10: chkChecked(),
  //     KKns01: chkChecked(), KKns02: checked_InspectionItem, KKns03: chkChecked(), KKns04: chkChecked(), KKns05: chkChecked(), KKns06: checked_InspectionItem, KKns07: checked_InspectionItem,
  //     Yojo: checked_InspectionItem, Hantei: chkChecked(), Biko: checked_InspectionItem,  
  //   }
  // }



  return (
    <GenericTemplate title="組立品質承認" >
      <Stack spacing={1} direction="column" >
        <Stack spacing={2} direction="row">
          <FromTextFieldBukkenNo
            onChange={(event) => setBukkenNo(event.target.value)}
          />
          <FromTextFieldSyusekiNo
            onChange={(event) => setSyusekiNo(event.target.value)}
          />
          {/* 検索・ボタン */}
          <ButtonSearch
            apiParam={apiGetParam}
            apiUrl={apiGetUrl1}
            apiKey={apiKey}
            getPost={setPosts}
          />
          {/* 更新・ボタン */}
          {(InspectionReportPreparer || InspectionReportManager) &&
          <ButtonUpdate
            apiParam={{ ...apiPutParam3, authorizeType: InspectionReportPreparer ? 'FirstAuthorize' : 'SecondAuthorize' }}
            apiUrl={apiPutUrl3}
            apiKey={apiKey}
            disabled={false}
            result={setAuthResult}
            message="変更した検査項目を更新します"
            name="更新"
          />}
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="select-label">表示対象</InputLabel>
            <Select
              labelId='select-label'
              id="simple-select-helper"
              value={selected}
              onChange={selectChange}
            >
              <MenuItem value={1}>全て</MenuItem>
              <MenuItem value={2}>担当者承認-未</MenuItem>
              <MenuItem value={3}>担当者承認-済</MenuItem>
              <MenuItem value={4}>責任者承認-未</MenuItem>
              <MenuItem value={5}>責任者承認-済</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={<Switch
              checked={checked_ReportItem}
              onChange={handleChange_ReportItem}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            } label="成績書項目表示" 
          />
          <FormControlLabel
            control={<Switch
              checked={checked_InspectionItem}
              onChange={handleChange_InspectionItem}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            } label="検査項目表示" 
          />
          {/* 完成品検査成績書レイアウト */}
          <div style={{ display: 'none' }}>
            {selectCheck ? (
              <ReportBa01R02 
                reportData={selectionModel.filter(value => value.KbKensa !== '2')} 
                ref={componentSelRef} 
              />
            ) : (
              <ReportBa01R02 
                reportData={dataGridData.filter(value => value.KbKensa !== '2')} 
                ref={componentRef} 
              />
            )}
          </div>
          {/* 完成品検査成績書ＣＷレイアウト */}
          <div style={{ display: 'none' }}>
            {selectCheck ? (
              <ReportBa01R03 
                reportData={selectionModel.filter(value => value.KbKensa === '2')} 
                ref={componentSelRefCW} 
              />
            ) : (
              <ReportBa01R03 
                reportData={dataGridData.filter(value => value.KbKensa === '2')} 
                ref={componentRefCW} 
              />
            )}
          </div>
          {/* 印刷・ボタン */}
          {/* <ButtonPrint
            printComponentRef={selectionModel.length === 0 ? dataGridData.filter(value => value.KbKensa === '2').length === 0 ? componentRef : componentRefCW : selectionModel.filter(value => value.KbKensa === '2').length === 0 ? componentSelRef : componentSelRefCW}
            documentTitle={DocTitle}
          /> */}
          <Button
            variant='text'
            onClick={printButtonClick}
          >
            印刷
          </Button>
          <Modal
            open={open}
            onClose={modalClose}
          >
            <Box sx={style}>
              <TextField id="outlined-basic" label="工事名入力" variant="outlined" style={{width: 725}} value={kojimei} onChange={kojimeiChange} />
              <p>
                {/* 印刷・ボタン */}
                <ButtonPrint
                  //printComponentRef={dataGridData.filter(value => value.KbKensa === '2').length === 0 ? componentRef : componentRefCW }
                  printComponentRef={selectionModel.length === 0 ? dataGridData.filter(value => value.KbKensa === '2').length === 0 ? componentRef : componentRefCW : selectionModel.filter(value => value.KbKensa === '2').length === 0 ? componentSelRef : componentSelRefCW}
                  documentTitle={DocTitle}
                />
              </p>
            </Box>
          </Modal>
        </Stack>

        {/* 承認者権限を保持しているユーザのみ表示されるstack */}
        {(InspectionReportPreparer || InspectionReportManager) &&
          <Stack spacing={2} direction="row">
            {InspectionReportPreparer &&
              <Card sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ fontSize: 14 }} gutterBottom>
                    担当者
                  </Typography>
                  {/* 担当者承認・ボタン */}
                  <ButtonUpdate
                    apiParam={{ ...apiPutParam, authorizeType: 'FirstAuthorize' }}
                    apiUrl={apiPutUrl1}
                    apiKey={apiKey}
                    disabled={!InspectionReportPreparer}
                    result={setAuthResult}
                    message="選択されたレコードを承認します"
                    name="承認"
                  />
                  {/* 担当者認取消・ボタン */}
                  <ButtonUpdate
                    apiParam={{ ...apiPutParam, authorizeType: 'FirstAuthorize' }}
                    apiUrl={apiPutUrl2}
                    apiKey={apiKey}
                    disabled={!InspectionReportPreparer}
                    result={setAuthResult}
                    message="選択されたレコードの承認を取消します"
                    name="取消"
                  />
                </Box>
              </Card>
            }
            {InspectionReportManager &&
              <Card sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ fontSize: 14 }} gutterBottom>
                    責任者
                  </Typography>
                    {/* 責任者検索・ボタン */}
                  <ButtonSearch
                    apiParam={apiGetParam}
                    apiUrl={apiPutUrl4}
                    apiKey={apiKey}
                    getPost={setPosts}
                  />
                  {/* 責任者承認・ボタン */}
                  <ButtonUpdate
                    apiParam={{ ...apiPutParam, authorizeType: 'SecondAuthorize' }}
                    apiUrl={apiPutUrl1}
                    apiKey={apiKey}
                    disabled={!InspectionReportManager}
                    result={setAuthResult}
                    message="選択されたレコードを承認します"
                    name="承認"
                  />
                  {/* 責任者承認取消・ボタン */}
                  <ButtonUpdate
                    apiParam={{ ...apiPutParam, authorizeType: 'SecondAuthorize' }}
                    apiUrl={apiPutUrl2}
                    apiKey={apiKey}
                    disabled={!InspectionReportManager}
                    result={setAuthResult}
                    message="選択されたレコードの承認を取消します"
                    name="取消"
                  />
                </Box>
              </Card>
            }

            {/* 更新処理の成功時のメッセージ */}
            {authResult.success.count !== 0 &&
              <div>
                <Alert severity="info">
                  更新を完了しました({authResult.success.count}件)。
                </Alert>
              </div>
            }
            {/* 更新処理の失敗時のメッセージ */}
            {authResult.fail.count !== 0 &&
              <div>
                <Alert severity="warning">
                  更新に失敗しました({authResult.fail.count}件)。結果を確認してください。
                </Alert>
              </div>
            }
          </Stack>
        }
        {/* DataGrid */}
        <div style={{ height: '100%', width: '100%', }}>
          <Box sx={{ height: 600, width: '100%' }}>
            <DataGrid
              columnVisibilityModel={{
                Kamikotei: checked_InspectionItem,
                SKns01: chkChecked(), SKns02: chkChecked(), SKns03: chkChecked(), SKns04: chkChecked(), SKns05: chkChecked(), SKns06: chkChecked(), SKns07: checked_InspectionItem, SKns08: checked_InspectionItem, SKns09: checked_InspectionItem, SKns10: checked_InspectionItem,
                GKns01: chkChecked(), GKns02: chkChecked(), GKns03: chkChecked(), GKns04: checked_InspectionItem, GKns05: chkChecked(), GKns06: chkChecked(), GKns07: checked_InspectionItem, GKns08: chkChecked(), GKns09: chkChecked(), GKns10: chkChecked(),
                KKns01: chkChecked(), KKns02: checked_InspectionItem, KKns03: chkChecked(), KKns04: chkChecked(), KKns05: chkChecked(), KKns06: checked_InspectionItem, KKns07: checked_InspectionItem,
                Yojo: checked_InspectionItem, Hantei: chkChecked(), Biko: checked_InspectionItem,           
              }}
              //columnVisibilityModel={columnVisibilityModel}
              checkboxSelection
              density="compact"
              rows={dataGridData}
              columns={columns}
              rowsPerPageOptions={[20, 50, 100]}
              filterModel={{ items: filter }}
              components={{
                Toolbar: CustomToolbar,
              }}
              //チェックボックスにチェックをつけたものをステート変数に保持
              selectionModel={selectedIds}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              onSelectionModelChange={(RowId) => {
                // 選択された行を特定するための処理
                const selectedRowId = new Set(RowId);
                const selectedRows = dataGridData.filter((dataGridRow) =>
                  selectedRowId.has(dataGridRow.id)
                );
                setSelectionModel(selectedRows);
                setSelectedIds(RowId as string[])
              }}
              processRowUpdate={handleProcessRowUpdate}
              onProcessRowUpdateError={(error) => console.log(error)}
              onRowClick={handleRowClick}
            />
          </Box>
        </div>
      </Stack>
    </GenericTemplate>
  )
}

export default P01Ins001Ase001