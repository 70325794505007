import axios from 'axios';
import DownloadIcon from '@mui/icons-material/Download';
import fileDownload from 'js-file-download';
import { Auth } from 'aws-amplify';
import Button from '@mui/material/Button';


//ファイルダウンロードボタンを追加する
const ButtonFileDownload = (props: { name: string, url: any, filename: any }) => {
  return (
    <Button size="small"
      variant="outlined"
      startIcon={<DownloadIcon />}
      onClick={() => FileDL(props.url, props.filename)}
    >
      {props.name}
    </Button>
  );
}
//ファイルダウンロード関数
const FileDL = async (url: any, filename: any) => {
  console.log("ButtonFileDownload url:" + url);
  console.log("ButtonFileDownload filename:" + filename);
  const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
  axios.get(url, {
    headers: {
      "Authorization": AuthToken || "",
    },
    responseType: 'blob',
  })
    .then((res) => {
      fileDownload(res.data, filename)
    })
};
export default ButtonFileDownload;