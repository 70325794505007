import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import SidebarAqua from "./sidebar/SidebarAqua";
import SidebarStls from "./sidebar/SidebarStls";
import SidebarMonoss from "./sidebar/SidebarMonoss";
import SidebarConfig from "./sidebar/SidebarConfig";

export const mainListItems = (
  <React.Fragment>
    {/* トップページ メニュー */}
    <Link to="/" style={{ textDecoration: 'none', color: 'black' }} >
      <ListItemButton>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="トップページ" />
      </ListItemButton>
    </Link>
  </React.Fragment>
);
export const mainListAqua = (
  <React.Fragment>
    {/*  aqua メニュー用のスライドバーを追加 */}
    <SidebarAqua />
  </React.Fragment>
);
export const mainListStls = (
  <React.Fragment>
    {/* stls メニュー用のスライドバーを追加 */}
    <SidebarStls />
  </React.Fragment>
);

export const mainListMonoss = (
  <React.Fragment>
    {/* monoss メニュー用のスライドバーを追加 */}
    <SidebarMonoss />
  </React.Fragment>
);

export const mainListConfig = (
  <React.Fragment>
    {/* Config メニュー用のスライドバーを追加 */}
    <SidebarConfig />
  </React.Fragment>
);
export const secondaryListItems = (
  <React.Fragment>
    {/* アバウトージ メニュー  */}
    <Link to="/about" style={{ textDecoration: 'none', color: 'black' }}>
      <ListItemButton >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="アバウトページ" color="primary" />
      </ListItemButton>
    </Link>
  </React.Fragment>
);