import React, { useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import requests from '../../utils/stls/Request';
import GenericTemplate from '../../templates/GenericTemplate';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import MUIDataTable from "mui-datatables";
import FromTextFieldHinmokuNo from '../../components/TextField/TextFieldHinmokuCd';
import { chkHalfWidth, chkEmpty } from '../../common/chkTextValue';
import {Auth} from 'aws-amplify';

const P01Prg001Kata005: React.FC = () => {
  //変数定義
  const [post, setPosts] = useState([])
  //品目番号
  const [hinban, setHinban] = useState('');
  const [hinbanError, setHinbanError] = useState('');

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  //requests.p01Mst001Itemにリクエストする関数。
  const fetchRequest = async() => {
    setPosts([])

    //requestを投げるURL作成
    const URL = process.env.REACT_APP_APIURL_STLS + requests.p01Mst001Itemprice + '/' + hinban
    const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    axios.get(URL,
      {
        headers: {
          "X-API-KEY": process.env.REACT_APP_APIKEY_STLS || "",
          "Authorization": AuthToken || "",
        },
      }).then((res) => {
        //成功したら Posts に result を格納。
        setPosts(res.data.result)
        if (res.data.result.length === 0) {
          alert('データはありません')
        }
        console.debug(res.data.result)
      }).catch(error => {
        console.log(error);
        alert('検索に失敗しました')
      }).finally(() => {
        setLoading(false);
      });
  };

  //検索・ボタン押下時の処理 
  const handleClick = (() => {
    let errMsg = ""
    //未入力チェック
    errMsg = chkEmpty(hinban)
    if (errMsg.length > 0) {
      setHinbanError(errMsg)
      alert('検索条件を入力してください');
      return;
    }
    //半角文字チェック 
    errMsg = chkHalfWidth(hinban)
    if (errMsg.length > 0) {
      setHinbanError(errMsg)
      alert(errMsg);
      return;
    }

    if (!loading) {
      setSuccess(false);
      setLoading(true);
      //apiコールする関数を呼び出す
      fetchRequest()
      setSuccess(true);
    }
  })

  //入力値チェック処理：品目番号
  const chkHinban = (event: any) => {
    let val = event.target.value
    setHinbanError(chkHalfWidth(val))
    setHinban(val)
  }

  const column = [
    { name: "item", label: "品目　　　　　　　",
      options: {
        setCellProps: () => ({ style: { position: "sticky", left: 0, width: 159, background: "white", zIndex: 100 } }),
        setCellHeaderProps: () => ({ style: { position: "sticky", left: 0, width: 159, background: "white", zIndex: 101 } })
      }
    },
    { name: "vend_num_user", label: "仕入先コード",
      options: {
        setCellProps: () => ({ style: { position: "sticky", left: 159, background: "white", zIndex: 102 } }),
        setCellHeaderProps: () => ({ style: { position: "sticky", left: 159, background: "white", zIndex: 103 } })
      }
    },
    { name: "vend_name_kanji", label: "取引先名" },
    { name: "estimate1_price", label: "見積１　価格", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "estimate1_start_date", label: "見積１　開始日" },
    { name: "first_order_flg", label: "初回発注フラグ" },
    { name: "estimate1_ordered_flg", label: "見積１　発注済フラグ", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },

    // { name: "designation_color", label: "指定色" },
    // { name: "estimate_lot", label: "指定ロット単位数", },
    // { name: "ratio", label: "割合", },
    // { name: "estimate1_reserve1", label: "見積１　予備１", },
    // { name: "estimate2_reserve1", label: "見積２　予備１", },
    // { name: "this_month_order_number", label: "当月発注数", },
    // { name: "effect_standard_price", label: "効果基準単価", },
    // { name: "budget_unit_price", label: "予算単価", },
    // { name: "past_achievement_qty", label: "過去実績数", },
    // { name: "item_linkage_div", label: "項目連動方式区分", },
    // { name: "estimate2_price", label: "見積２　価格", 
    //   options: {
    //     setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
    //     setCellProps: () => ({align: "right"})
    //   }
    // },
    // { name: "estimate2_start_date", label: "見積２　開始日", },
    // { name: "estimate2_ordered_flg", label: "見積２　発注済フラグ", 
    //   options: {
    //     setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
    //     setCellProps: () => ({align: "right"})
    //   }
    // },
  ];

  return (
    <GenericTemplate title="" >
      <Stack spacing={1} direction="column" >
        <Stack spacing={2} direction="row">
          {/* 品目番号 */}
          <FromTextFieldHinmokuNo onChange={chkHinban}
            helperText={hinbanError}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ m: 1, position: 'relative' }}>
              <Button variant="contained"
                sx={buttonSx}
                disabled={loading}
                onClick={() => { handleClick(); }}>
                検索
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
          </Box>
        </Stack>
        <div style={{ height: '50%', width: '100%', }}>
          <MUIDataTable
            title="購入先契約単価(stls)"
            data={post}
            columns={column}
            options={{
              selectableRows: 'none',
              rowsPerPage: 20,
              viewColumns: false,
              rowsPerPageOptions: [20, 50, 100],
              sort: false,
              print: false,
              setTableProps: () => {
                return {
                  // padding: 'none',
                  size: "small",
                  style: { whiteSpace: "nowrap" }
                };
              },
            }}
          />
        </div>
      </Stack>
    </GenericTemplate>
  )
}

export default P01Prg001Kata005