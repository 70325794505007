import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { Auth } from 'aws-amplify';

// ----------------------------------------------------
// MaterialUiのButton コンポーネント 追加ボタン
// ----------------------------------------------------
type Props = {
    apiParam: Object;
    apiUrl: string;
    apiKey: string;
    onClick?: (event: React.MouseEvent<HTMLInputElement>) => void
    result: any;
    name?: string
    message?: string
    disabled: boolean
    beforFunc?: Function    //更新前チェック処理
    afterFunc?: Function    //更新前チェック処理
    size?: any              //small,medium,large
}

const ButtonFuncInsert = (
    props: Props,
) => {

    //変数定義
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    //ボタン押下時の処理 
    const handleClick = (() => {
        //更新前処理を実行
        if(props.beforFunc !== undefined){
            if(!props.beforFunc()){
                return
            }
        }

        if ((props.message === undefined) || (window.confirm(props.message))) {
            if (!loading) {
                setSuccess(false);
                setLoading(true);
                //apiコールする関数を呼び出す
                fetchRequest()
                setSuccess(true);
            }
        }

        //更新後処理を実行
        if(props.afterFunc !== undefined){
            if(success) {
                if(!props.afterFunc()){
                    return
                }
            }
        }
    })

    //postリクエストする関数。
    const fetchRequest = async () => {
        console.debug('[ButtonFuncInsert]props.apiUrl:',props.apiUrl);
        console.debug('[ButtonFuncInsert]props.apiParam:)',props.apiParam);
        //requestを投げるURL作成
        const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
        axios.post(props.apiUrl,
            props.apiParam,
            {
                headers: {
                    "X-API-KEY": props.apiKey,
                    "Authorization": AuthToken || "",
                },
            }).then((res) => {
                console.log('[ButtonFuncInsert]res.data.result:',res.data.result);
                props.result(res.data.result);
            }).catch(error => {
                alert(error)
                console.log(error);
            }).finally(() => {
                setLoading(false);
        });
    };

    //ボタンチェンジ関数
    const buttonSx = {
        '&:hover': {
        bgcolor: green[500],
        },
        '&:active': {
        bgcolor: green[800],
        }
    };


    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ m: 1, position: 'relative' }}>
                <Button variant="contained"
                    sx={buttonSx}
                    disabled={(loading || props.disabled)}
                    onClick={() => { handleClick(); }}
                    size={(props.size === undefined ? 'medium' : props.size)}
                >
                    {(props.name === undefined ? '追加' : props.name)}
                </Button>
                {loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </Box>
        </Box>
    )
}

export default ButtonFuncInsert;
