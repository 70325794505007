import TextField from '@mui/material/TextField';

const FromTextField = ({
  onChange = (event: any) => { onChange(event); }
}) => (
  <TextField id="tfKoujimei"
    label="工事名"
    size="small"
    inputProps={{ maxLength: 11 }}
    onChange={(event: any) => { onChange(event); }}
  />
);

export default FromTextField;