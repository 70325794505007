import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import requests from '../../utils/aqua/Request';
import moment from 'moment';
import ButtonCsvDownload from '../../components/Button/ButtonCsvDownload';
import DesktopDatePickerCommon from '../../components/DesktopDatePicker/DesktopDatePickerCommon';

const P01Prg001Kata004: React.FC = () => {
  //変数定義
  //処理日(始点)
  const [startdate, setstartdate] = useState<Date | undefined>(new Date());
  //処理日(終点)
  const [enddate, setenddate] = useState<Date | undefined>(new Date());

  // api 用のパラメータを設定する
  const apiUrl = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Prg001syogodownload)
  const apiKey = String(process.env.REACT_APP_APIKEY_AP00447)
  const DLFileHeader = ['processcode', 'date', 'time', 'workcode', 'datafrom', 'param1', 'param2',];
  const DLFileName = "syogo_" + moment(startdate).format("YYYY-MM-DD") + "-" + moment(enddate).format("YYYY-MM-DD") + ".csv"
  const apiParam = {
    processcode: "AF700-21001",
    startdate: moment(startdate).format("YYYY-MM-DD"),
    enddate: moment(enddate).format("YYYY-MM-DD"),
  };
  return (
    <Stack spacing={1} direction="column" >
      <div></div>
      <Stack spacing={2} direction="row">
        <DesktopDatePickerCommon
          label="処理日(始点)"
          value={startdate}
          onChange={(newSnouki: any) => { setstartdate(newSnouki); }}
        />
        <DesktopDatePickerCommon
          label="処理日(終点))"
          value={enddate}
          onChange={(newSnouki: any) => { setenddate(newSnouki); }}
        />
        {/* CSVダウンロード・ボタン */}
        <ButtonCsvDownload
          apiParam={apiParam}
          apiUrl={apiUrl}
          apiKey={apiKey}
          DLFileHeader={DLFileHeader}
          DLFileName={DLFileName}
          buttonName="実行"
        />
      </Stack>
      <div>（っ'-')╮ =͟͟͞͞📚ﾌﾞｫﾝ.</div>
    </Stack>
  )
}

export default P01Prg001Kata004