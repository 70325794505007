import React, { useState, useRef } from 'react';
import requests from '../../utils/aqua/Request';
import GenericTemplate from '../../templates/GenericTemplate';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import FromTextFieldHinmokuNo from '../../components/TextField/TextFieldHinmokuCd';
import FromTextFieldCyumonno from '../../components/TextField/TextFieldCyumonno';
import SelectZaikobashoCd from '../../components/Select/SelectZaikobasho';
import SelectTounyukaCd from '../../components/Select/SelectTounyuka';
import ButtonSearch from '../../components/Button/ButtonSearch';
import ButtonPrint from '../../components/Button/ButtonPrint';
import DesktopDatePickerCommon from '../../components/DesktopDatePicker/DesktopDatePickerCommon';
import ReportAc02R01 from '../../components/Report/aqua/ReportAc02R01';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

type productListSummaryType = {
    ssijbi: string,
    cd_tounyuka: string,
    cd_seisanline: string,
    nonyubasho1: string,
    ProductList: productListType[],
}
type productListType = {
    bname: string,
    cd_tategu: string,
    cyumon_no: string,
    grp_no_buhin: string,
    grp_no_buzai: string,
    hinmokno: string,
    kanrino: string,
    kataban: string,
    kigoua: string,
    kikakkg2: string,
    kikakkg3: string,
    kikaksp1: string,
    kikaksp2: string,
    kikaksp3: string,
    orderList: string,
    seisnjun: string,
    suryo: string,
    tanisu: string,
    tategumei: string,
    unitren: string,
    zaikba: string,
}

type orderListType = {
    koubaiCyumonNo: string,
    nounyukanryo_mk: string,
    haccyusu: string,
    tourokubi: string,
    acceptNitiji: string,
    acceptTanaNo: string,
    payoutNitiji: string,
}

const P01Prg101Buhin002: React.FC = () => {
    //変数定義
    const [post, setPosts] = useState<productListSummaryType[]>([]);
    //作業指示日
    const [ssijbi, setSsijbi] = useState<Date | undefined>(new Date());
    //投入課生産ライン
    const [kaline, setKaline] = useState('');
    //注文番号
    const [cyumonno, setCyumonno] = useState('');
    //品目番号
    const [hinmokuno, setHinmokuno] = useState('');
    //在庫場所CD
    const [nonyubasho1, setNonyubasho1] = useState([]);

    // api 用のパラメータを設定する
    const apiUrl = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Prg101BuhinPayoutList)
    const apiKey = String(process.env.REACT_APP_APIKEY_AP00447)
    const apiParam = {
        ssijbi: moment(ssijbi).format("YYYY-MM-DD"),
        kaline: kaline,
        cyumonno: cyumonno,
        hinmokuno: hinmokuno,
        zaikobasyo: nonyubasho1,
    };

    // 帳票のタイトルを変数セット
    const DocTitle = moment(ssijbi).format("YYYY-MM-DD") + '_補強材払出指示書';

    const componentRef = useRef(null);
    return (
        <>
            <GenericTemplate title="" >
                <Stack spacing={1} direction="column" >
                    <Stack spacing={2} direction="row">
                        {/*  納期(スタート) */}
                        <DesktopDatePickerCommon
                            label="作業指示日"
                            value={ssijbi}
                            onChange={(newDate: any) => { setSsijbi(newDate); }}
                        />
                        {/*  投入課生産ライン */}
                        <SelectTounyukaCd onChange={(event) => setKaline(event.target.value)} />
                        {/* 注文番号 */}
                        <FromTextFieldCyumonno onChange={(event) => setCyumonno(event.target.value)} />
                    </Stack>
                    <Stack spacing={2} direction="row">
                        {/* 在庫場所CD */}
                        <SelectZaikobashoCd onChange={(event) => setNonyubasho1(event.target.value)} />
                        {/* 品目番号 */}
                        <FromTextFieldHinmokuNo onChange={(event) => setHinmokuno(event.target.value)} />
                        {/* 羂索・ボタン */}
                        <ButtonSearch
                            apiParam={apiParam}
                            apiUrl={apiUrl}
                            apiKey={apiKey}
                            getPost={setPosts}
                        />
                        {/* 印刷・ボタン */}
                        <div style={{ display: 'none' }}>
                            <ReportAc02R01 reportData={post} ref={componentRef} />
                        </div>
                        <ButtonPrint
                            printComponentRef={componentRef}
                            documentTitle={DocTitle}
                        />
                    </Stack>
                    {console.log(post)}

                    <Container maxWidth={false}>
                        <Card >
                            {post.map((text: productListSummaryType, i: number) =>
                                <CardContent sx={{ margin: 1, border: 1 }} key={text.cd_tounyuka + text.cd_seisanline + text.nonyubasho1}>
                                    <div style={{ textAlign: 'left' }}>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                作業指示日　 　：{text.ssijbi}
                                            </Grid>
                                            <Grid item xs={4}>
                                                投入課CD・生産ライン：{text.cd_tounyuka} - {text.cd_seisanline}
                                            </Grid>
                                            <Grid item xs={4}>
                                                納入場所：{text.nonyubasho1}
                                            </Grid>
                                        </Grid>
                                    </div>

                                    {/* 親テーブルのテーブルボディーを作成する */}
                                    <TableContainer component={Paper} >
                                        <Table size="small" aria-label="collapsible table" >
                                            {/* 親テーブルのテーブルヘッダーを作成する */}
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="right" width={10}>No</StyledTableCell >
                                                    <StyledTableCell align="right" width={100}>生産順</StyledTableCell >
                                                    <StyledTableCell width={90}>管理番号</StyledTableCell >
                                                    <StyledTableCell width={130}>品目番号</StyledTableCell >
                                                    <StyledTableCell width={190}>品名</StyledTableCell >
                                                    <StyledTableCell width={230}>記号-寸法</StyledTableCell >
                                                    <StyledTableCell align="right" width={80}>単位数</StyledTableCell >
                                                    <StyledTableCell width={160}>部材</StyledTableCell >
                                                    <StyledTableCell width={120}>注文番号</StyledTableCell >
                                                    <StyledTableCell width={90}>在庫場所</StyledTableCell >
                                                    <TableRow>
                                                        <StyledTableCell width={100}>棚番</StyledTableCell >
                                                        <StyledTableCell width={110}>発注日</StyledTableCell >
                                                        <StyledTableCell width={100}>発注番号</StyledTableCell >
                                                        <StyledTableCell align="right" width={80}>発注数</StyledTableCell >
                                                        <StyledTableCell width={80}>納完</StyledTableCell >
                                                        <StyledTableCell width={180}>受入日時</StyledTableCell >
                                                        <StyledTableCell width={180}>払出日時</StyledTableCell >
                                                    </TableRow>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {text.ProductList.map((productlist: productListType, index3: number) => {
                                                    return (
                                                        <React.Fragment>
                                                            <StyledTableRow key={productlist.seisnjun + productlist.unitren + productlist.hinmokno}>
                                                                <StyledTableCell align="right">{index3 + 1}</StyledTableCell >
                                                                <StyledTableCell align="right">{productlist.seisnjun}-{productlist.unitren}</StyledTableCell >
                                                                <StyledTableCell >{productlist.kanrino}</StyledTableCell >
                                                                <StyledTableCell >{productlist.hinmokno}</StyledTableCell >
                                                                <StyledTableCell >{productlist.bname}</StyledTableCell >
                                                                <StyledTableCell >{productlist.kigoua}-{productlist.kikaksp1}/{productlist.kikakkg2}-{productlist.kikaksp2}/{productlist.kikakkg3}-{productlist.kikaksp3}</StyledTableCell >
                                                                <StyledTableCell align="right">{productlist.tanisu}</StyledTableCell >
                                                                <StyledTableCell >{productlist.tategumei}({productlist.kataban})</StyledTableCell >
                                                                <StyledTableCell >{productlist.cyumon_no}</StyledTableCell >
                                                                <StyledTableCell >{productlist.zaikba}</StyledTableCell >
                                                                {JSON.parse(productlist.orderList).sort().map((orderRow: orderListType) => (
                                                                    <StyledTableRow key={orderRow.koubaiCyumonNo}>
                                                                        <StyledTableCell scope="row" width={100}>
                                                                            {orderRow.acceptTanaNo}
                                                                        </StyledTableCell >
                                                                        <StyledTableCell width={100}>{orderRow.tourokubi}</StyledTableCell>
                                                                        <StyledTableCell width={100}>{orderRow.koubaiCyumonNo}</StyledTableCell>
                                                                        <StyledTableCell align="right" width={80}>{orderRow.haccyusu}</StyledTableCell >
                                                                        <StyledTableCell width={80}>{orderRow.nounyukanryo_mk}</StyledTableCell >
                                                                        <StyledTableCell width={180}>{orderRow.acceptNitiji}</StyledTableCell>
                                                                        <StyledTableCell width={180}>{orderRow.payoutNitiji}</StyledTableCell>
                                                                    </StyledTableRow >
                                                                ))}
                                                            </StyledTableRow>
                                                        </React.Fragment>
                                                    )
                                                }
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            )}
                        </Card>
                    </Container>
                </Stack>
            </GenericTemplate>
        </>
    )
}

// テーブルのセルのスタイルをカスタマイズする
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: 12,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

// テーブルの行スタイルをカスタマイズする
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default P01Prg101Buhin002