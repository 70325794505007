import  {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import axios  from 'axios';
import { Auth } from 'aws-amplify';

// ----------------------------------------------------
// MaterialUiのButton コンポーネント 検索ボタン
// ----------------------------------------------------
type Props = {
    apiParam: Object;
    apiUrl: string;
    apiKey: string;
    getPost: any;
    onClick?: (event: React.MouseEvent<HTMLInputElement>) => void
    name?: string
    disabled?: boolean
}

const ButtonSearch = (
    props :Props,
) => {
    
    //変数定義
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    //検索・ボタン押下時の処理 
    const handleClick = (() => {
        if (!loading) {
            props.getPost([])

            setSuccess(false);
            setLoading(true);
            //apiコールする関数を呼び出す
            fetchRequest()

            setSuccess(true);
        }
    })

    //requests.p01Prg001Dataにリクエストする関数。
    const fetchRequest = async () => {
        console.debug('[ButtonSearch]props.apiKey:',props.apiKey)
        console.debug('[ButtonSearch]props.apiUrl:',props.apiUrl)
        //requestを投げるURL作成
        const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
        axios.get(props.apiUrl,
            {
                headers: {
                    "X-API-KEY": props.apiKey ,
                    "Authorization": AuthToken || "",
                },
                params: props.apiParam|| ""
            }).then((res) => {
                console.debug('[ButtonSearch]res.data.result:',res.data.result)
                //成功したら Posts に result を格納。
                props.getPost(res.data.result);
                if (res.data.result.length === 0) {
                    alert('データはありません')
                }
            }).catch(error => {
                console.log(props.apiParam);
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
    };

    //ボタンチェンジ関数
    const buttonSx = {
        '&:hover': {
        bgcolor: green[500],
        },
        '&:active': {
        bgcolor: green[800],
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ m: 1, position: 'relative' }}>
                <Button variant="contained"
                    sx={buttonSx}
                    disabled={(loading || props.disabled)}
                    onClick={() => { handleClick(); }}
                    >
                    {(props.name === undefined ? '検索' : props.name)}
                </Button>
                {loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </Box>
        </Box>
    )
}

export default ButtonSearch;