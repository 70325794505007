import { useState, useEffect } from 'react';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { compInterface } from '../../pages/aqua/P01Put001Konpo005';

type Props = {
    dataList: compInterface[]
}

const columns: GridColDef[] = [
    { field: 'renban', headerName: '連番', width: 70, },
    { field: 'kuraireday', headerName: '完了日', width: 200, },
    { field: 'plitenumbs', headerName: 'パレット ', width: 80, },
    { field: 'tonyday', headerName: '倉入送信', width: 100, },
    { field: 'bikou1', headerName: '備考', width: 160, },
];

const DataGridPutComp = (props: Props,) => {
    // *************
    // 変数定義
    // *************
    //検索結果を管理するuseState
    const [post, setPosts] = useState<compInterface[]>([])

    //画面初期表示
    useEffect(() => {
        // console.debug(props.dataList)
        setPosts(
            props.dataList.map((dataRow: compInterface) => {
				return {...dataRow, 
                    kuraireday: (dataRow.kuraireday === null ? '' : dataRow.kuraireday) + ' ' + (dataRow.knpkanryodaytime === null ? '' : dataRow.knpkanryodaytime),
                    };
			})
        );
        // setPosts(props.dataList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function CustomToolbar() {
        return (
          <GridToolbarContainer>
            <GridToolbarExport               
              csvOptions={{
              utf8WithBom: true,
            }}
          />
          </GridToolbarContainer>
        )
    }

    return (
        <div>
            {/* <Typography variant="h6" component="h2">　</Typography> */}
            <Box sx={{ height: 232, width: 640 }}>
                <DataGrid 
                    density="compact"
                    rows={post}
                    columns={columns}
                    rowsPerPageOptions={[20, 50, 100]}
                    components={{
                        Toolbar: CustomToolbar
                    }}
                    experimentalFeatures={{ newEditingApi: true }}
                    showCellRightBorder
                />
            </Box>
        </div>
    );
};

export default DataGridPutComp;

