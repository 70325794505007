import React from 'react';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import FactoryIcon from '@mui/icons-material/Factory';
import { Link } from "react-router-dom";
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

// グローバル変数
let reversedArr: string[] = [];

export default function SidebarStls() {
  // イベントで利用する変数
  let selectnodeid: string[] = [];

  const nodeselect = (event: React.SyntheticEvent, nodeid: Array<string>) => {
    // ゲットしたnodeidを配列の変数へpush
    selectnodeid = nodeid
    // 配列の変数をmap関数で上下逆にする
    reversedArr = selectnodeid.map((_, i, a) => a[a.length - 1 - i])
  };

  return (
    <div>
      <TreeView
        aria-label="title"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultParentIcon={<FactoryIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        onNodeToggle={nodeselect}
        defaultExpanded={reversedArr}
      >
        <TreeItem nodeId="1" label="stls">
          {/* TreeItemに追加時はnodeIdを一意にする */}
          <TreeItem nodeId="2" label="マスター">
            <TreeItem nodeId="3" label="部品">
              <List component="div" disablePadding>
                <Link to="/stls/MstItem" style={{ textDecoration: 'none', color: 'black' }}>
                  <ListItemText primary="品目" secondary="ma01" />
                </Link>
                <Link to="/stls/MstLoc" style={{ textDecoration: 'none', color: 'black' }}>
                  <ListItemText primary="保管場所" secondary="ma02" />
                </Link>
                <Link to="/stls/MstStock" style={{ textDecoration: 'none', color: 'black' }}>
                  <ListItemText primary="品目保管場所" secondary="ma03" />
                </Link>
                <Link to="/stls/MstVender" style={{ textDecoration: 'none', color: 'black' }}>
                  <ListItemText primary="購入先" secondary="ma04" />
                </Link>
                <Link to="/stls/MstItemprice" style={{ textDecoration: 'none', color: 'black' }}>
                  <ListItemText primary="購入先契約価格" secondary="ma05" />
                </Link>
              </List>
            </TreeItem>
            {process.env.REACT_APP_FEACHERFLAG_202207R119_01==="true" &&
              <TreeItem nodeId="4" label="形材">
                <List component="div" disablePadding>
                  <Link to="/stls/KataKataban" style={{ textDecoration: 'none', color: 'black' }}>
                    <ListItemText primary="形番" secondary="mb01" />
                  </Link>
                  <Link to="/stls/KataColor" style={{ textDecoration: 'none', color: 'black' }}>
                    <ListItemText primary="色コード" secondary="mb02" />
                  </Link>
                  <Link to="/stls/KataFactory" style={{ textDecoration: 'none', color: 'black' }}>
                    <ListItemText primary="工場コード" secondary="mb03" />
                  </Link>
                  <Link to="/stls/KataStock" style={{ textDecoration: 'none', color: 'black' }}>
                    <ListItemText primary="在庫" secondary="mb04" />
                  </Link>
                  <Link to="/stls/KataTransaction" style={{ textDecoration: 'none', color: 'black' }}>
                    <ListItemText primary="形材実績明細" secondary="mb05" />
                  </Link>
                </List>
              </TreeItem>
            }
          </TreeItem>
        </TreeItem>
      </TreeView>
    </div>
  );
}