import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

// ----------------------------------------------------
// MaterialUiのSelect コンポーネント InfoTypeの一覧を表示する
// ----------------------------------------------------
const SelectInfoType = (
    {
        helperText = "",
        selected="",
        readonly =false,
        //呼出元にSelect で選択した値を返すためのイベントを定義する
        onChange = (event: any) => { onChange(event); }
    }
) => {
    //変数定義
    const [InfoType, setInfoType] = React.useState(selected);

    //Select変更時のイベント
    const handleChange = (event: SelectChangeEvent) => {
        setInfoType(event.target.value);
        onChange(event);
    };

    // 作成したselect をリターンする
    return (
        <FormControl sx={{ minWidth: 180 }} size="small" error={!!helperText} >
            <InputLabel id="select-infoType">タイプ</InputLabel>
            <Select 
                labelId="select-infoType"
                id="select-infoType"
                value={InfoType}
                label="InfoType"
                onChange={handleChange}
                inputProps={{ readOnly: readonly }}
            >
                <MenuItem value={"I"}>Information</MenuItem>
                <MenuItem value={"W"}>Warning</MenuItem>
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
}

export default SelectInfoType