import { createStore } from "redux";

const initialState = {
  count: 99999,
  openListAp00092: false,
  openListAp00447: false,
  openListStls: false,
  openListMonoss: false,
};

const reducer = (state = initialState, action:any) => {
    switch (action.type) {
        case 'TOGGLE_LIST_AP00092':
            return {
               ...state, openListAp00092: !state.openListAp00092,
            }
        case 'TOGGLE_LIST_AP00447':
            return {
               ...state, openListAp00447: !state.openListAp00447,
            }
        default:
            return state;
        }
};

const store = createStore(reducer);

export default store;
