import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import GenericTemplate from '../../templates/GenericTemplate';
import requests from '../../utils/aqua/Request';
import DesktopDatePickerCommon from '../../components/DesktopDatePicker/DesktopDatePickerCommonErrDisp';
import ButtonSearch from '../../components/Button/ButtonSearch';
import { chkDate } from '../../common/chkTextValue';
import {Auth} from 'aws-amplify';
import axios from 'axios';

interface rowInterface {
  id: string;
  uplhzk: string;
  kjcode: string;
  kanflg: string;
  renban: string;
  flsize: string;
  coment: string;
  [key: string]: string
}
interface colInterface {
  lineCode: string;
  lineName: string;
}
const columnsbase: GridColDef[] = [
  { field: 'uplhzk', headerName: '受信時刻', width: 180, },
  { field: 'renban', headerName: '連番', width: 60, align: 'right', },
  { field: 'flsize', headerName: 'サイズ', width: 100, align: 'right', },
  { field: 'coment', headerName: 'コメント', width: 500, },
];

const P01Put001Konpo001: React.FC = () => {
  // *************
  // 変数定義
  // *************
  //検索結果を管理するuseState
  const [post, setPosts] = useState<rowInterface[]>([])
  const [codeLineList, setCodeLineList] = useState<colInterface[]>([])
  const [columns, setColumns] = useState<GridColDef[]>([])
  const [dataList, setDataList] = useState<rowInterface[]>([])

  // 検索条件をセットする変数
  const [checkedDate, setCheckedDate] = useState<Date | undefined>(new Date());
  const [checkedDateError, setCheckedDateError] = useState('');

  // *************
  // 定数定義
  // *************
  // api 用のパラメータを設定する
  const apiKey = String(process.env.REACT_APP_APIKEY_AP00447)
  // APIのget 時のパラメータを指定
  const apiGetUrl1 = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Put001History)
  const apiGetUrl2 = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Put001CDLineHistory)
  const apiGetParam = {};

  // 画面初期表示時
  useEffect(() => {
    const fetch = async () => {
      // console.debug(apiGetUrl2)
      //requestを投げるURL作成
      const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
      axios.get(apiGetUrl2,
      {
        headers: {
          "X-API-KEY": apiKey,
          "Authorization": AuthToken || "",
        },
      }).then((res) => {
        // 成功したら Posts に result を格納。
        setCodeLineList(res.data.result)
        console.log(res.data.result)
      }).catch(error => {
        console.log(error);
        alert('エラーが発生しました。再度検索して下さい。');
      });
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 検索データ編集
  useEffect(() => {
    // alert('検索データ編集')
    if (post.length === 0) {
      // console.debug("ゼロ件")
      setDataList([])
    } else {
      console.debug(post.length)
      console.debug(post)
      const newList: any = post.map((dataRow: rowInterface, index: number) => {
        return {...dataRow, 
          'id': index + 1,
          [dataRow.kjcode]: dataRow.kanflg === "0" ? "〇" : "◎"
        }}
      );
      // console.debug(newList)
      setDataList(newList)
      }
  }, [post]);

  // 検索データ編集
  useEffect(() => {
    if (codeLineList.length === 0) {
      setColumns(columnsbase)      
    } else {
      let newList:GridColDef[] = codeLineList.map((dataRow: colInterface) => {
        return { field: dataRow.lineCode, headerName: dataRow.lineName, width: 90, align: 'center', headerAlign: 'center', }
        // return { field: dataRow.lineCode, headerName: dataRow.lineName, width: dataRow.lineName.length * 20 + 5, align: 'center', }
      })
      newList.unshift(columnsbase[0]);
      newList.push(columnsbase[1])
      newList.push(columnsbase[2])
      newList.push(columnsbase[3])
      // console.debug(newList)
      setColumns(newList)
    }
  }, [codeLineList]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport               
          csvOptions={{
          utf8WithBom: true,
        }}
      />
      </GridToolbarContainer>
    )
  }

  return (
    <GenericTemplate title="倉入明細受信履歴" >
      <Stack spacing={1} direction="column" >
        <Stack spacing={2} direction="row">
          <DesktopDatePickerCommon
            label="倉入日"
            value={checkedDate}
            helperText={checkedDateError}
            onChange={(newDate: any) => { setCheckedDateError(chkDate(newDate));
                                          setCheckedDate(newDate); }}
          />
          {/* 検索・ボタン */}
          <ButtonSearch
            apiParam={apiGetParam}
            apiUrl={apiGetUrl1.replace('{kurairebi}', moment(checkedDate).format("YYYYMMDD"))}
            apiKey={apiKey}
            getPost={setPosts}
            disabled={checkedDate===null||checkedDateError!==''}
          />
        </Stack>

        <Stack spacing={2} direction="row">
          <div>〇：続きあり　◎：完了</div>
        </Stack>
        {/* DataGrid */}
        <div style={{ height: '100%', width: '100%', }}>
          <Box sx={{ height: 600, width: '100%' }}>
            <DataGrid
              density="compact"
              rows={dataList} 
              columns={columns}
              rowsPerPageOptions={[20, 50, 100]}
              components={{
                Toolbar: CustomToolbar
              }}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              showCellRightBorder
            />
          </Box>
        </div>
      </Stack>
    </GenericTemplate>
  )
}

export default P01Put001Konpo001