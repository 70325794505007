const Requests = {
    p01Prg001Data: 'progress/katazai/acceptance',  // GETメソッド
    p01Prg001cutcell: 'progress/katazai/cutcell',  // GETメソッド
    p01Prg001kumitatekahan: 'kumitatekahan',  // GETメソッド
    p01Prg001senbetufusoku: 'progress/katazai/senbetulacklist',  // GETメソッド
    p01Prg001lacklist: 'progress/katazai/acceptracklist',  // GETメソッド
    p01Prg001syogodownload: 'progress/download/syogo2',  // GETメソッド
    p01Prg001batchzailist: 'progress/himaku/batchzailist',  // GETメソッド
    p01Prg001batchzaitotal: 'progress/himaku/batchzaitotal',  // GETメソッド
    p01Prg001preferrddate: 'progress/himaku/preferrd_date', // POSTメソッド
    p01Prg101BuhinAcceptance: 'progress/buhin/acceptance', // GETメソッド
    p01Prg101BuhinPayoutList: 'progress/buhin/payoutList', // GETメソッド
    p01Mst001CDZaikobasho: 'zaikobasho', // GETメソッド
    p01Mst001CDTounyuka: 'tounyuka', // GETメソッド

    p01Ins001Data: 'inspection/assembly/qualityinspect/authorization/checkedDate/', // GETメソッド
    p01Ins002Data: 'inspection/assembly/qualityinspect/authorization/qaUnQualityinspect', // GETメソッド
    p01Ins001Auth: 'inspection/assembly/qualityinspect/authorization', // putメソッド
    p01Ins001unAuth: 'inspection/assembly/qualityinspect/unAuthorization', // putメソッド
    p01Ins003List: 'inspection/assembly/qualityapproval', // GETメソッド
    p01Ins003Auth: 'inspection/assembly/qualityapproval/authorization', // putメソッド
    p01Ins003unAuth: 'inspection/assembly/qualityapproval/unAuthorization', // putメソッド
    p01Ins003Change: 'inspection/assembly/qualityapproval/change', // putメソッド
    p01Ins003ManegerUnAuth: 'inspection/assembly/qualityapproval/authorization/managerUnQualityapproval', // getメソッド
    
    p01Put001History: 'kuraire/konpo/history/{kurairebi}', // GETメソッド
    p01Put001HistoryByFactory: 'kuraire/konpo/history/ditails/{cdline}', // GETメソッド
    p01Put001Delay: 'kuraire/konpo/delay', // GETメソッド
    p01Put001DelayList: 'kuraire/konpo/delay/{cdline}', // GETメソッド
    p01Put001PackingCompSearch: 'kuraire/konpo/{cyumonno}', // GETメソッド
    p01Put002KuraiCheckList: 'kuraire/stbutsryu/checklist/{cdline}', // GETメソッド
    p01Put002MikurairiList: 'kuraire/stbutsryu/pendinglist/{cdkojyo}', // GETメソッド
    p01Put001CDLineHistory: 'kuraire/cdline/history', // GETメソッド
    p01Put001CDLineDitails: 'kuraire/cdline/ditails', // GETメソッド
    p01Put001CDLineDelay: 'kuraire/cdline/delay', // GETメソッド
    p01Put001CDLineChecklist: 'kuraire/cdline/checklist', // GETメソッド
    p01Put001CDKojo: 'kuraire/cdkojyo', // GETメソッド
  };
  
  export default Requests;