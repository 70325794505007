import React from 'react';
import Grid from '@mui/material/Grid';

type productListSummaryType = {
    ssijbi: string,
    cd_tounyuka: string,
    cd_seisanline: string,
    nonyubasho1: string,
    ProductList: productListType[],
}
type productListType = {
    bname: string,
    cd_tategu: string,
    cyumon_no: string,
    grp_no_buhin: string,
    grp_no_buzai: string,
    hinmokno: string,
    kanrino: string,
    kataban: string,
    kigoua: string,
    kikakkg2: string,
    kikakkg3: string,
    kikaksp1: string,
    kikaksp2: string,
    kikaksp3: string,
    orderList: string,
    seisnjun: string,
    suryo: string,
    tanisu: string,
    tategumei: string,
    unitren: string,
    zaikba: string,
}

type orderListType = {
    koubaiCyumonNo: string,
    nounyukanryo_mk: string,
    haccyusu: string,
    tourokubi: string,
    acceptNitiji: string,
    acceptTanaNo: string,
    payoutNitiji: string,
}

interface Props {
  reportData: productListSummaryType[]
}
interface State { }
export default class ReportAc02R01 extends React.Component<Props, State> {
  public render(): JSX.Element {
    const reportdatax = this.props.reportData
    return (
      <div className='report_page'>
        {reportdatax.map((summary: productListSummaryType) =>
          <div className='pdf_page'>
            <table >
              <thead>
                <tr >
                  <th colSpan={15} align="left" className='page_header'>
                    <Grid container>
                      <Grid item xs={4}>
                        作業指示日：{summary.ssijbi}
                      </Grid>
                      <Grid item xs={4}>
                        投入課・ライン：{summary.cd_tounyuka}-{summary.cd_seisanline}
                      </Grid>
                      <Grid item xs={4}>
                        納入場所：{summary.nonyubasho1}
                      </Grid>
                    </Grid>
                  </th>
                </tr>

                {/* テーブルヘッダー を定義 */}
                <tr className='table_header' >
                  <th className='block_jun'>生産順</th>
                  <th className='block_kanrino'>管理番号</th>
                  <th className='block_hinmokumei'>品名</th>
                  <th className='block_kigousunpo'>L寸法</th>
                  <th className='block_suryo'>単位数</th>
                  <th className='block_cyumono'>注文番号</th>
                  <tr className='table_header' >
                    <th className='block_tourokubi'>発注日</th>
                    <th className='block_acceptTanano'>棚番</th>
                  </tr>
                </tr>
              </thead>
              <tbody className='table_body'>
                {summary.ProductList.map((product: productListType) => (
                  <tr className='table_rows'>
                    <td className='block_jun'>{product.seisnjun}-{product.unitren.padStart(3, ' ')}</td >
                    <td className='block_kanrino'>{product.kanrino}</td >
                    <td className='block_hinmokumei'>{product.bname.substring(10)}</td >
                    {product.kigoua==='L  ' && <td className='block_kigousunpo'>{product.kikaksp1}</td>}
                    {product.kikakkg2==='L  ' && <td className='block_kigousunpo'>{product.kikaksp2}</td>}
                    {product.kikakkg3==='L  ' && <td className='block_kigousunpo'>{product.kikaksp3}</td>}
                    {product.kigoua!=='L  '&&  product.kikakkg2!=='L  ' && product.kikakkg3!=='L  ' && <td className='block_kigousunpo'>-</td>}
                    <td className='block_suryo'>{product.tanisu}</td >
                    <td className='block_cyumono'>{product.cyumon_no}</td >
                    {JSON.parse(product.orderList).sort().map((orderRow: orderListType) => (
                      <tr className='table_rows'>
                        <td className='block_tourokubi'>{orderRow.tourokubi}</td >
                        <td className='block_acceptTanano'>{orderRow.acceptTanaNo}</td >
                      </tr>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )
        }
        <style type="text/css" media="all">
          {`
            @page { 
              size: A4;
              margin-top: 2cm; 
              margin-bottom: 1cm;
            }
            .report_page {
              zoom: 70%;
            }
            .pdf_page {
                size: A4;
                position: relative;
                font-family: MS Gothic;
                page-break-after: always;
              }
            .pdf_page:after{
                position: absolute;
                right: 0;
                bottom: 0;
            }
            table {
              margin: 10px auto 20px;
            }
            thead {
              display: table-header-group;
              break-inside: avoid;
              margin: 10mm;
              border: 1px solid;
              counter-increment: page_count;
            }
            /*最後のページは改ページを入れない*/
            .pdf_page:last-child{
                page-break-after: auto;
            }
            .page_header {
              font-size: 24px;
            }
            .table_header {
              background-color: #c0c0c0;
              padding-left: 20px;
              padding-right: 20px;
            }
            .table_rows {
              padding-left: 20px;
              padding-right: 20px;
            }
            .table_body tr:nth-child(even) {
              background: #eee;
            }
            .block_jun {
              display: table-cell;
              border-bottom: 1px solid;
              width: 100px;
              text-align: left;
              font-size: 18px;
            }
            .block_kanrino {
              display: table-cell;
              border-bottom: 1px solid;
              width: 120px;
              text-align: left;
              font-size: 18px;
            }
            .block_cyumono {
              display: table-cell;
              border-bottom: 1px solid;
              width: 160px;
              text-align: left;
              font-size: 18px;
            }
            .block_zaikba {
              display: table-cell;
              border-bottom: 1px solid;
              width: 110px;
              text-align: left;
              font-size: 18px;
            }
            .block_suryo {
              display: table-cell;
              border-bottom: 1px solid;
              width: 70px;
              text-align: right;
              font-size: 18px;
            }
            .block_acceptTanano {
              display: table-cell;
              border-bottom: 1px solid;
              width: 100px;
              text-align: left;
              font-size: 18px;
            }
            .block_hinmokumei {
              display: table-cell;
              border-bottom: 1px solid;
              width: 180px;
              text-align: left;
              font-size: 18px;
            }
            .block_kigousunpo {
              display: table-cell;
              border-bottom: 1px solid;
              width: 100px;
              text-align: left;
              font-size: 18px;
            }
            .block_tourokubi {
              display: table-cell;
              border-bottom: 1px solid;
              width: 120px;
              text-align: left;
              font-size: 18px;
            }            
          `}
        </style>
      </div >
    );
  }
}
