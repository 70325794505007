import React, { useContext, useEffect, useState } from 'react';
import { userAttributes } from '../../App';
import axios from 'axios';
import Box from '@mui/material/Box';
import requests from '../../utils/config/Request';
import GenericTemplate from '../../templates/GenericTemplate';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonUpdate from '../../components/Button/ButtonFuncUpdate';
import ButtonDelete from '../../components/Button/ButtonFuncDelete';
import ButtonAction from '../../components/Button/ButtonAction';
import { Auth } from 'aws-amplify';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowModel } from '@mui/x-data-grid';
import Checkbox from '../../components/Checkbox/CheckBoxOne';
import Alert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

interface rowInterface {
  id: number;
  enabledFlg: boolean;
  userServices: string;
  roleName: string;
  chargeOf: string;
  description: string;
  updateUser: string;
  updateUser_ja: string;
  updateAt: string;
  rowDB?:rowInterfaceDB;
}
interface rowInterfaceDB {
  enabledFlag: string;
  userServices: string;
  roleName: string;
  chargeOf: string[];
  description: string;
  updateUser: string;
  updateUser_ja: string;
  updateAt: string;
}
const P01Mst001Permission002: React.FC = () => {
  // *************
  // 変数定義
  // *************
  const [post, setPosts] = useState<rowInterface[]>([])
  const [userServices, setUserServices] = useState([])

  const [loading, setLoading] = useState(false);

  //更新・削除したidを管理するuseRef
  const selectId = React.useRef(0)
  const [gridRow, setGridRow] = useState<GridRowModel>()

  // *************
  // 定数定義
  // *************  
  // api 用のパラメータを設定する
  const apiKey = String(process.env.REACT_APP_APIKEY_CONFIG)
  // APIのput 時のパラメータを指定
  const apiPutUrl = String(process.env.REACT_APP_APIURL_CONFIG + requests.p01Mst002RoleName)
  const apiPutParam = (params: rowInterface) => {
    return {
      //enabledFlg:trueは有効のため'0' enabledFlg:falseは無効のため'1'
      'enabledFlag': params.enabledFlg ? '0' : '1',
      'chargeOf': params.chargeOf === '' ? [] : params.chargeOf.split(','),
      'description': params.description,
    }
  };
  // APIのdelete 時のパラメータを指定
  const apiDelUrl = String(process.env.REACT_APP_APIURL_CONFIG + requests.p01Mst002RoleName)

  // 更新権限をチェックする(stinqAdminFlag=1の場合、更新可能)
  const userAtt = useContext(userAttributes)
  const stinqAdminFlag = (userAtt as any).stinqAdminFlag === '1' ? true : false

  const columns: GridColDef[] = [
    { field: 'update', headerName: '', width: 80, align: 'center',
      sortable: false, disableColumnMenu: true, 
      renderCell: (params) => {
        return <RenderCellComponentUpd {...params} />
      },
    },
    { field: 'delete', headerName: '', width: 80, align: 'center',
      sortable: false, disableColumnMenu: true, 
      renderCell: (params) => {
        return <RenderCellComponentDel {...params} />
      },
    },
    { field: 'enabledFlg', headerName: '無効', width: 100, align: 'center',
      renderCell: (params) => {
        return <RenderCellComponentEnabled {...params} />
      },
    } ,
    { field: 'id', headerName: 'id', width: 150},
    { field: 'userServices', headerName: 'UserServices', width: 200, editable: true, 
      type: 'singleSelect', valueOptions: userServices,
    },
    { field: 'roleName', headerName: '権限', width: 250, editable: true, }, 
    { field: 'chargeOf', headerName: '条件', width: 200, editable: true, }, 
    { field: 'description', headerName: '説明', width: 500, editable: true, },
    { field: 'updateUser_ja', headerName: '更新者名', width: 160, editable: false, },
    { field: 'updateUser', headerName: '更新者', width: 160, editable: false, },
    { field: 'updateAt', headerName: '更新日', width: 160, editable: false, },
    { field: 'rowDB', headerName: '検索時データ', width: 180, }, 
  ];

  //更新ボタン設定
  const RenderCellComponentUpd = (params: GridRenderCellParams) => {
    return (
      <ButtonUpdate 
        apiParam={ apiPutParam(params.row) }
        apiUrl={apiPutUrl.replace('{userServices}', params.row.userServices).replace('{roleName}', params.row.roleName)}
        apiKey={apiKey}
        // 変更があった場合のみ押下可能
        disabled={(
          params.row.enabledFlg === (params.row.rowDB?.enabledFlag === '1' ? false : true) 
          && params.row.userServices?.trim() === params.row.rowDB?.userServices.trim()
          && params.row.roleName?.trim() === params.row.rowDB?.roleName.trim()
          && params.row.chargeOf?.trim() === (params.row.rowDB?.chargeOf.length === 0 ? '' : params.row.rowDB?.chargeOf.join(','))
          && params.row.description?.trim() === params.row.rowDB?.description.trim()
        ) || (params.row.userServices?.trim() === '' && params.row.roleName?.trim() === '')
        }
        result={updateResult}
        // 更新確認と上書確認でメッセージを切り替え
        message={chkDuplicate(params.row)}
        name="更新"
        beforFunc={() => selectId.current = params.row.id }   //更新前処理
        size="small"
      />
    )
  }

  //削除ボタン設定
  const RenderCellComponentDel = (params: GridRenderCellParams) => {
    if (params.row.updateAt?.trim() === '') {
      //DB未登録データの削除ボタン
      return (
        <ButtonAction 
          disabled={false}
          onAction={() => setPosts(post.filter(({ id }) => id !== params.id))}
          name="削除"
          size="small"
        />
      )
    } else {
      //DB登録済データの削除ボタン
      return (
        <ButtonDelete 
          apiUrl={apiDelUrl.replace('{userServices}', 
                  params.row.rowDB?.userServices).replace('{roleName}', 
                  params.row.rowDB?.roleName)}
          apiKey={apiKey}
          disabled={false}
          result={deleteResult}
          message="選択されたレコードを削除します"
          name="削除"
          beforFunc={() => selectId.current = params.row.id }   //更新前処理
          size="small"
        />
      )
    }
  }

  //無効チェックボックス設定
  const RenderCellComponentEnabled = (params: GridRenderCellParams) => {
    return (
      <Checkbox
        value={params.row.enabledFlg}
        onChange={() => { changeEnable(params.row); }}  //チェックボックス変更
      />
    )
  }

  //無効チェックボックスクリック時の表示変更処理 
  const changeEnable = (params:rowInterface) => {
    setPosts(
			post.map((dataRow) => {
				if (dataRow.id === params.id) {
					return { ...dataRow, enabledFlg: !params.enabledFlg };
				} else {
					return dataRow;
				}
			})
		)
  }

  // 追加ボタン押下時の空行追加
  const addRow = () => {
    // 配列内のオブジェクトidの最大値+1
    const id = (post.length === 0) ? 1 : Math.max(...post.map(v => v.id)) + 1; 
    const newValue = { id: id,
      enabledFlg: false,
      userServices: '',
      roleName: '',
      chargeOf: '', 
      description: '',
      updateUser: '',
      updateUser_ja: '',
      updateAt: '',
      rowDb: null,
    };
    setPosts([...post,newValue]);
  }

  // 更新ボタン押下時のメッセージ編集
  const chkDuplicate = (rowData:rowInterface) => {
    const dupRow = post.filter(( row ) => row.id !== rowData.id
                            && row.userServices === rowData.userServices 
                            && row.roleName === rowData.roleName)
    // console.debug(dupRow)
    if (dupRow.length === 0 ) {
      return "選択されたレコードを更新します"
      // if (rowData.userServices === rowData.rowDB?.userServices && rowData.roleName === rowData.rowDB?.roleName ) {
      //   return "選択されたレコードを更新します(キー項目変更なし)"
      // } else {
      //   return "選択されたレコードを更新します(キー項目変更あり)"
      // }
    } else {
      return "重複データが存在しますが、上書更新しますか？"
    }
  }

  // 画面初期表示の処理
  useEffect(() => {
    if (!stinqAdminFlag) return
    setLoading(true);
    // 明細部のuserServicesコンボボックス値取得
    const fetch2 = async () => {
      //requestを投げるURL作成
      const apiGetUrl2 = process.env.REACT_APP_APIURL_CONFIG + requests.p01Mst001UserServices
      const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
      axios.get(apiGetUrl2,
      {
        headers: {
          "X-API-KEY": process.env.REACT_APP_APIKEY_CONFIG || "",
          "Authorization": AuthToken || "",
        },
      }).then((res) => {
        // console.debug(res.data.result);
        setUserServices(res.data.result.map((cat: any) => (cat.userServices)))
      }).catch(error => {
        alert(error)
        console.log(error);
      }).finally(() => {
        setLoading(false);
      })
    }
    fetch2()

    // 明細部の取得
    const fetch = async () => {
      //requestを投げるURL作成
      const apiGetUrl = process.env.REACT_APP_APIURL_CONFIG + requests.p01Mst002UserServices
      const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
      axios.get(apiGetUrl,
      {
        headers: {
          "X-API-KEY": process.env.REACT_APP_APIKEY_CONFIG || "",
          "Authorization": AuthToken || "",
        },
      }).then((res) => {
        console.log(res.data.result);
        const newList: any = res.data.result.map((postx: rowInterfaceDB, index: number) => {
          return {
            "id": index + 1,
            //enabledFlag:'1'は有効のためfalse　enabledFlag:'0'は無効のためtrue
            "enabledFlg": postx.enabledFlag === '1' ? false : true,
            "userServices": postx.userServices,
            "roleName": postx.roleName,
            "chargeOf": postx.chargeOf.length === 0 ? '' : postx.chargeOf.join(','), //文字列化
            "description": postx.description,
            "updateUser": postx.updateUser, 
            "updateUser_ja": postx.updateUser_ja, 
            "updateAt": postx.updateAt, 
            "rowDB": postx
          }}
        );
        setPosts(newList)
      }).catch(error => {
        alert(error)
        console.log(error);
      }).finally(() => {
        setLoading(false);
      })
    }
    fetch()    
  }, [])

  // 更新後の再表示
  const updateResult = (result : rowInterfaceDB) => {
    // console.debug('updateList!')
    if (result == null) return

    // キー項目変更ありの場合、変更前キー項目の削除処理を実施（新規行の場合は不要）
    const delRow = post.filter(( chkRow ) => chkRow.id === selectId.current 
    && (chkRow.rowDB?.updateAt !== '')
    && (chkRow.rowDB?.userServices !== result.userServices || chkRow.rowDB?.roleName !== result.roleName))
    if (delRow.length > 0) {
      deleteDuplicate(delRow[0].rowDB)
    }
    // 変更後キー項目が重複していた場合、削除処理を実施
    const dispRow = post.filter((dataRow) => !(dataRow.id !== selectId.current 
            && (dataRow.rowDB?.userServices === result.userServices && dataRow.rowDB?.roleName === result.roleName)))

    // 更新後の再表示
    const newList: rowInterface = {
      "id": selectId.current,
      //enabledFlag:'1'は有効のためfalse　enabledFlag:'0'は無効のためtrue
      "enabledFlg": result.enabledFlag === '1' ? false : true,
      "userServices": result.userServices,
      "roleName": result.roleName,
      "chargeOf": result.chargeOf.length === 0 ? '' : result.chargeOf.join(','), //文字列化
      "description": result.description,
      "updateUser": result.updateUser, 
      "updateUser_ja": result.updateUser_ja, 
      "updateAt": result.updateAt, 
      "rowDB": result
    };
    setPosts(
			dispRow.map((dataRow) => {
				if (dataRow.id === selectId.current) {
					return newList;
				} else {
					return dataRow;
				}
			})
    )
  }
  
  // 更新処理の変更前キーのデータ削除処理
  const deleteDuplicate = (deleteRow:any) => {
    // console.debug('deleteDuplicate')
    if (deleteRow === null) return
    const delUrl = apiDelUrl.replace('{userServices}', 
                deleteRow.userServices).replace('{roleName}', 
                deleteRow.roleName)
    console.debug(delUrl)

    const fetch = async () => {
        //requestを投げるURL作成
      const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
      axios.delete(delUrl,
        {
          headers: {
              "X-API-KEY": apiKey,
              "Authorization": AuthToken || "",
          },
        }).then((res) => {
          console.log(res.data);
          // deleteResult(res.data.result);
        }).catch(error => {
          alert(error)
          console.log(error);
        }).finally(() => {
          setLoading(false);
      }); 
    }
    fetch()
  }

  // 削除後の再表示
  const deleteResult = (result : rowInterfaceDB) => {
    // console.debug('deleteResult!')
    if (result == null) return
    setPosts(post.filter(({ id }) => id !== selectId.current))
  }
 
  // 行編集の必須チェック
  const useFakeMutation = () => {
    return React.useCallback( (dataRow: Partial<rowInterface>) =>
      new Promise<Partial<rowInterface>>((resolve, reject) => {
        setTimeout(() => {
          if (dataRow.userServices?.trim() === '') {
            reject(new Error("userServicesは必須項目です"));
          } else if (dataRow.roleName?.trim() === '') {
            reject(new Error("権限は必須項目です"));
          } else {
            resolve(dataRow);
          }
        }, 200);
      })
    ,[],);
  };
  const mutateRow = useFakeMutation();
  const [snackbar, setSnackbar] = React.useState<Pick<AlertProps,'children' | 'severity'> | null>(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const processRowUpdate = React.useCallback(
    async (newRow: GridRowModel) => {
      // Make the HTTP request to save in the backend
      const response = await mutateRow(newRow);
      setSnackbar({ children: 'successfully saved', severity: 'success' });
      setGridRow(newRow)
      return response;
    }, [mutateRow],    
  );
  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);
  
  // 明細入力値が正常の場合のみ反映
  useEffect(() => {
    if (gridRow === null) return
    const newList: rowInterface = {
      "id": gridRow?.id,
      //enabledFlag:'1'は有効のためfalse　enabledFlag:'0'は無効のためtrue
      "enabledFlg": gridRow?.enabledFlag ,
      "userServices": gridRow?.userServices,
      "roleName": gridRow?.roleName,
      "chargeOf": gridRow?.chargeOf,
      "description": gridRow?.description,
      "updateUser": gridRow?.updateUser, 
      "updateUser_ja": gridRow?.updateUser_ja, 
      "updateAt": gridRow?.updateAt, 
      "rowDB": gridRow?.rowDB
    };
    setPosts(
			post.map((dataRow) => {
				if (dataRow.id === gridRow?.id) {
					return newList;
				} else {
					return dataRow;
				}
			})
    )
  }, [gridRow]);

  return (
    <GenericTemplate title="権限管理" >
      <Stack spacing={1} direction="column" >
        <Stack spacing={2} direction="row">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ m: 1, position: 'relative' }}>
            {stinqAdminFlag && 
              <Button variant="contained"
                disabled={loading}
                onClick={() => { addRow(); }}>
                追加
              </Button>}
            </Box>
          </Box>
        </Stack>
        <div>
          {process.env.REACT_APP_FEACHERFLAG_202301R081_01 === "true" &&
            <Box sx={{ height: 655, width: '100%' }}>
            <DataGrid 
                // 非表示項目設定
                columnVisibilityModel={{
                    id: false,
                    updateUser: false,
                    rowDB: false,
                }}
                density="compact" //グリッド内のボタンもsmallにする
                rows={post}
                columns={columns}
                rowsPerPageOptions={[20, 50, 100]}
                disableSelectionOnClick
                editMode="row" 
                experimentalFeatures={{ newEditingApi: true }}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
            />
            </Box>
          }
          {!!snackbar && (
            <Snackbar
              open
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              onClose={handleCloseSnackbar}
              autoHideDuration={6000}
            >
              <Alert {...snackbar} onClose={handleCloseSnackbar} />
            </Snackbar>
          )}          
        </div>
      </Stack>
    </GenericTemplate>
  )
}

export default P01Mst001Permission002