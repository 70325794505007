import React, { useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import requests from '../../utils/stls/Request';
import GenericTemplate from '../../templates/GenericTemplate';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import MUIDataTable from "mui-datatables";
import FromTextFieldKataban from '../../components/TextField/TextFieldKataban';
import { chkHalfWidth, chkEmpty } from '../../common/chkTextValue';
import {Auth} from 'aws-amplify';
import moment from 'moment';

const P01Prg002Kata001: React.FC = () => {
  //変数定義
  const [post, setPosts] = useState([])
  //形番
  const [kataban, setKataban] = useState('');
  const [katabanError, setKatabanError] = useState('');

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  //requests.p01Mst001KataKatabanにリクエストする関数。
  const fetchRequest = async() => {
    setPosts([])

    //requestを投げるURL作成
    const URL = process.env.REACT_APP_APIURL_STLS + requests.p01Mst001KataKataban + '/' + kataban
    const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    axios.get(URL,
      {
        headers: {
          "X-API-KEY": process.env.REACT_APP_APIKEY_STLS || "",
          "Authorization": AuthToken || "",
        },
      }).then((res) => {
        //成功したら Posts に result を格納。
        setPosts(res.data.result)
        if (res.data.result.length === 0) {
          alert('データはありません')
        }
        console.debug(res.data.result)
      }).catch(error => {
        console.log(error);
        alert('検索に失敗しました')
      }).finally(() => {
        setLoading(false);
      });
  };

  //検索・ボタン押下時の処理 
  const handleClick = (() => {
    let errMsg = ""
    //未入力チェック
    errMsg = chkEmpty(kataban)
    if (errMsg.length > 0) {
      setKatabanError(errMsg)
      alert('検索条件を入力してください');
      return;
    }
    //半角文字チェック 
    errMsg = chkHalfWidth(kataban)
    if (errMsg.length > 0) {
      setKatabanError(errMsg)
      alert(errMsg);
      return;
    }

    if (!loading) {
      setSuccess(false);
      setLoading(true);
      //apiコールする関数を呼び出す
      fetchRequest()
      setSuccess(true);
    }
  })

  //入力値チェック処理：形番
  const chkKataban = (event: any) => {
    let val = event.target.value
    setKatabanError(chkHalfWidth(val))
    setKataban(val)
  }

  //日付をスラッシュ編集
  function dateEditDisplay(val: string) {
    return val.length === 8 ? moment(val).format("YYYY/MM/DD") : val;
  }
  //数値をカンマ編集
  function commaEditDisplay(val: any) {
    return Number(val).toLocaleString(undefined, { maximumFractionDigits: 3 });
  }  
  
  const column = [
    { name: "mold_material", label: "形番",
      options: {
        setCellProps: () => ({ style: { position: "sticky", left: 0, background: "white", zIndex: 120 } }),
        setCellHeaderProps: () => ({ style: { position: "sticky", left: 0, background: "white", zIndex: 121 } })
      }
    },
    { name: "latest_revision", label: "最新改番", },
    { name: "mold_material_name", label: "形番名称", },
    { name: "unit_weight", label: "単重", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"}),
      }
    },
    { name: "total_perimeter_length", label: "全周長", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"}),
        customBodyRender:(cellValue: any) => {return commaEditDisplay(cellValue)}
      }
    },
    { name: "outer_perimeter_length", label: "外周長", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"}),
        customBodyRender:(cellValue: any) => {return commaEditDisplay(cellValue)}
      }
    },
    { name: "hollow_solid_div", label: "Ｈ／Ｓ区分", },
    { name: "inch", label: "インチ", },
    { name: "material", label: "材質", },
    { name: "heat_treatment", label: "熱処理", },
    { name: "management_div", label: "管理区分", },
    { name: "comment", label: "コメント", },
    { name: "circumscribed_circle", label: "外接円", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "fixed_dimensions_01", label: "定尺01", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "fixed_dimensions_02", label: "定尺02", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "fixed_dimensions_03", label: "定尺03", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "loc", label: "保管場所", },
    { name: "protective_packaging_unit", label: "養生単位", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "enactment_date", label: "制定日",  
      options: {
        customBodyRender:(cellValue: string) => {return dateEditDisplay(cellValue)}
      }
    },
    { name: "discard_date", label: "廃型日",  
      options: {
        customBodyRender:(cellValue: string) => {return dateEditDisplay(cellValue)}
      }
    },
    { name: "create_date", label: "作成日",  
      options: {
        customBodyRender:(cellValue: string) => {return dateEditDisplay(cellValue)}
      }
    },
    { name: "create_user", label: "作成者", },
    { name: "update_date", label: "更新日",  
      options: {
        customBodyRender:(cellValue: string) => {return dateEditDisplay(cellValue)}
      }
    },
    { name: "update_user", label: "更新者", },
  ];

  return (
    <GenericTemplate title="">
      <Stack spacing={1} direction="column" >
        <Stack spacing={2} direction="row">
          {/* 形番 */}
          <FromTextFieldKataban onChange={chkKataban}
            helperText={katabanError}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ m: 1, position: 'relative' }}>
              <Button variant="contained"
                sx={buttonSx}
                disabled={loading}
                onClick={() => { handleClick(); }}>
                検索
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
          </Box>
        </Stack>
        <div style={{ height: '50%', width: '100%' }}>
          <MUIDataTable
            title="形番(stls)"
            data={post}
            columns={column}
            options={{
              selectableRows: 'none',
              rowsPerPage: 20,
              viewColumns: false,
              rowsPerPageOptions: [20, 50, 100],
              sort: false,
              print: false,
              setTableProps: () => {
                return {
                  // padding: 'none',
                  size: "small",
                  style: { whiteSpace: "nowrap" },
                };
              },
            }}
          />
        </div>
      </Stack>
    </GenericTemplate>
  )
}

export default P01Prg002Kata001