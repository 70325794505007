import React from 'react';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import FactoryIcon from '@mui/icons-material/Factory';
import { Link } from "react-router-dom";
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { useContext } from 'react';
import { userAttributes } from '../../App';

// グローバル変数
let reversedArr: string[] = [];

export default function SidebarMonoss() {
  // イベントで利用する変数
  let selectnodeid: string[] = [];

  const nodeselect = (event: React.SyntheticEvent, nodeid: Array<string>) => {
    // ゲットしたnodeidを配列の変数へpush
    selectnodeid = nodeid
    // 配列の変数をmap関数で上下逆にする
    reversedArr = selectnodeid.map((_, i, a) => a[a.length - 1 - i])
  };

  // 更新権限をチェックする(stinqAdminFlag=1の場合、更新可能)
  const userAtt = useContext(userAttributes)
  const stinqAdminFlag = (userAtt as any).stinqAdminFlag === '1' ? true : false

  return (
    <div>
      {process.env.REACT_APP_FEACHERFLAG_202301R081_01 === "true" &&
        <TreeView
          aria-label="title"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultParentIcon={<FactoryIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
          onNodeToggle={nodeselect}
          defaultExpanded={reversedArr}
        >
          <TreeItem nodeId="1" label="設定">
            {/* TreeItemに追加時はnodeIdを一意にする */}
            <TreeItem nodeId="2" label="マスター">
              <TreeItem nodeId="3" label="権限管理">
                <List component="div" disablePadding>
                  <Link to="/config/MstUser" style={{ textDecoration: 'none', color: 'black' }}>
                    <ListItemText primary="ユーザ管理" secondary="ma01" />
                  </Link>
                  {stinqAdminFlag &&
                  <Link to="/config/MstRole" style={{ textDecoration: 'none', color: 'black' }}>
                    <ListItemText primary="権限管理" secondary="ma02" />
                  </Link>}
                </List>
              </TreeItem>
            </TreeItem>
          </TreeItem>
        </TreeView>
      }
    </div>
  );
}