const Requests = {
  p01Mst001Item: 'master/parts/item',  // GETメソッド
  p01Mst001Loc: 'master/parts/loc',  // GETメソッド
  p01Mst001Stock: 'master/parts/stock',  // GETメソッド
  p01Mst001Vendor: 'master/parts/vendor',  // GETメソッド
  p01Mst001Itemprice: 'master/parts/itemprice',  // GETメソッド
  p01Mst001KataKataban: 'master/katazai/kataban',  // GETメソッド
  p01Mst001KataColor: 'master/katazai/color',  // GETメソッド
  p01Mst001KataFactory: 'master/katazai/factory',  // GETメソッド
  p01Mst001KataStock: 'master/katazai/stock',  // GETメソッド
  p01Mst001KataTransaction: 'master/katazai/transaction',  // GETメソッド
  p01Mst001KataTransType: 'master/katazai/transType',  // GETメソッド
};

export default Requests;