import React, { useContext, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import requests from '../../utils/monoss/Request';
import GenericTemplate from '../../templates/GenericTemplate';
import Stack from '@mui/material/Stack';
import ButtonSearch from '../../components/Button/ButtonSearch';
import ButtonUpdate from '../../components/Button/ButtonFuncUpdate';
import ButtonDelete from '../../components/Button/ButtonFuncDelete';
import ButtonAction from '../../components/Button/ButtonAction';
import ButtonCsvDownload from '../../components/Button/ButtonCsvDownload';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import FromTextFieldSeisanKojo from '../../components/TextField/TextFieldSTPSeisanKojo';
import FromTextFieldTounyuKojo from '../../components/TextField/TextFieldTounyuKojo';
import { chkHalfWidth, chkHalfWidthKeta } from '../../common/chkTextValue';
import Dropzone from '../../components/DragAndDrop/DragAndDropLine';
import { userAttributes } from '../../App';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';

export interface rowInterface {
  id: number;
  status : string;
  gkzkojst : string;
  gkzkojstmei : string;
  gctnkojo : string;
  gctnkojomei : string;
  gcgcents : string;
  gcgcentsmei : string;
  gzbiko : string;
  insdate : string;
  insuser : string;
  upddate : string;
  upduser : string;
  errmessage : string;
}
export interface updateResultInterface {
  totalCount: number;
  success: {
    count: number;
    records?: rowInterface[];
  }
  fail: {
    count: number;
    records?: rowInterface[];
  }
}

const P01Mst101Hanbai002: React.FC = () => {
  // *************
  // 変数定義
  // *************
  //ＳＴＰ生産工場
  const [seisanKojo, setSeisanKojo] = useState('');
  const [seisanKojoError, setSeisanKojoError] = useState('');
  //投入工場
  const [tonyuKojo, setTonyuKojo] = useState('');
  const [tonyuKojoError, setTonyuKojoError] = useState('');

  const [post, setPosts] = useState<rowInterface[]>([])
  const [selectionModel, setSelectionModel] = useState<rowInterface[]>([]);
  // console.log('>>> selectionModel: ', selectionModel);

  const [loading, setLoading] = useState(false);

  //検索結果
  const searchResult = useRef<rowInterface[]>([])
  //取込結果
  const readCsv = useRef<rowInterface[]>([])
  //更新結果
  const updateResult = useRef<updateResultInterface>(
    {
      totalCount: 0,
      success: { count: 0 },
      fail: { count: 0 }
    }
  )
  //削除結果
  const deleteResult = useRef<updateResultInterface>(
    {
      totalCount: 0,
      success: { count: 0 },
      fail: { count: 0 }
    }
  )

  // 更新権限をチェックする(HanbaikanriMasterEditor ロールが付与されていれば、更新可能)
  const userAtt = useContext(userAttributes)
  let regexp1 = /HanbaikanriMasterEditor/
  const HanbaikanriMasterEditor = regexp1.test((userAtt as any).stinqRoles["STINQMONOSS"])
  
  // *************
  // 定数定義
  // *************  
  // api 用のパラメータを設定する
  const apiKey = String(process.env.REACT_APP_APIKEY_MONOSS)
  // APIの get 時のパラメータを指定
  const apiGetUrl = String(process.env.REACT_APP_APIURL_MONOSS + requests.p01Mst101CostByLine)
  const apiGetParam = {
    gkzkojst: seisanKojo,
    gctnkojo: tonyuKojo,
  };
  const apiGetParamAll = {
    gkhonkbn: '%',
    gkzkojst: '',
  };  
  // APIのput 時のパラメータを指定
  const apiPutUrl = String(process.env.REACT_APP_APIURL_MONOSS + requests.p01Mst101CostByLine)
  const apiPutParam = {
    requests: post.filter(({ status }) => (status === 'NEW'||status === 'UPD')).map((dataGridRow) => {
      return {
        gkzkojst: dataGridRow.gkzkojst,
        gctnkojo: dataGridRow.gctnkojo,
        gcgcents: dataGridRow.gcgcents,
        gzbiko: dataGridRow.gzbiko,
      }
    })
  };
  // APIのdelete 時のパラメータを指定
  const apiDelUrl = String(process.env.REACT_APP_APIURL_MONOSS + requests.p01Mst101CostByLine)
  const apiDelParam = {
    requests: selectionModel.filter(({ status }) => !status.endsWith('NEW')).map((dataGridRow) => {
      return {
        gkzkojst: dataGridRow.gkzkojst,
        gctnkojo: dataGridRow.gctnkojo,
      }
    })
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'id', width: 50, disableExport:true,},
    { field: 'status', headerName: '', width: 60, align: 'center', disableColumnMenu: true, disableExport:true, }, 
    { field: 'gkzkojst', headerName: 'ＳＴＰ生産工場', width: 120, editable: true, }, 
    { field: 'gkzkojstmei', headerName: 'ＳＴＰ生産工場名', width: 340, }, 
    { field: 'gctnkojo', headerName: '投入工場', width: 80, editable: true, }, 
    { field: 'gctnkojomei', headerName: '投入工場名', width: 360, }, 
    { field: 'gcgcents', headerName: '原価センタ（ＳＴＰ）', width: 160, editable: true, }, 
    { field: 'gcgcentsmei', headerName: '原価センタ（ＳＴＰ）名', width: 180, }, 
    { field: 'gzbiko', headerName: '備考', width: 320, editable: true, }, 
    { field: 'insdate', headerName: '登録日', width: 160, }, 
    { field: 'insuser', headerName: '登録者', width: 100, }, 
    { field: 'upddate', headerName: '更新日', width: 160, }, 
    { field: 'upduser', headerName: '更新者', width: 100, }, 
    { field: 'errmessage', headerName: 'エラーメッセージ', width: 500, disableExport:!HanbaikanriMasterEditor, }, 
  ];

  // 全件出力ファイルのヘッダ
  const DLFileHeader = [
    { label: 'ＳＴＰ生産工場', value: 'gkzkojst' }, 
    { label: '工場名', value: 'gkzkojstmei' }, 
    { label: '投入工場', value: 'gctnkojo' }, 
    { label: '投入工場名', value: 'gctnkojomei' }, 
    { label: '原価センタ（ＳＴＰ）', value: 'gcgcents' }, 
    { label: '原価名', value: 'gcgcentsmei' }, 
    { label: '備考', value: 'gzbiko' },
    { label: '登録日', value: 'insdate' }, 
    { label: '登録者', value: 'insuser' }, 
    { label: '更新日', value: 'upddate' }, 
    { label: '更新者', value: 'upduser' }, 
    { label: 'エラーメッセージ', value: 'errmessage' }, 
  ];

  function nullToBrank(inVal:any) {
    return inVal === null ? '' : inVal
  }

  //更新結果を管理するuseStateを初期化
  function resetResult() {
    updateResult.current = ({
      totalCount: 0,
      success: { count: 0 },
      fail: { count: 0 }
    });
    deleteResult.current = ({
      totalCount: 0,
      success: { count: 0 },
      fail: { count: 0 }
    });  
  }

  //検索ボタン押下後の表示明細の設定
  const selResult = ((result:rowInterface[]) => {
    resetResult()
    setPosts(result.map((dataRow: rowInterface, index: number) => {
      return {...dataRow, 
        id: index + 1,
        status: '',
        gkzkojst : nullToBrank(dataRow.gkzkojst),
        gkzkojstmei : nullToBrank(dataRow.gkzkojstmei),
        gctnkojo : nullToBrank(dataRow.gctnkojo),
        gctnkojomei : nullToBrank(dataRow.gctnkojomei),
        gcgcents : nullToBrank(dataRow.gcgcents),
        gcgcentsmei : nullToBrank(dataRow.gcgcentsmei),
        gzbiko : nullToBrank(dataRow.gzbiko),
        errmessage : nullToBrank(dataRow.errmessage)
      };
    }))
    searchResult.current = result
  });

  //更新ボタン押下後の表示明細の設定
  const updResult = ((result:updateResultInterface) => {
    // console.debug('post:', post)
    // console.debug('updResult:', result)
    resetResult()

    let newList:rowInterface[] = post
    if (result.success.count > 0) {
      newList = newList.map((dataRow: rowInterface) => {
        // 更新対象をキー(生産本部区分、ＳＴＰ生産工場)でフィルターする
        const updateTarget: any = result.success.records?.filter(updateRow => 
                                  (dataRow.gkzkojst === updateRow.gkzkojst && dataRow.gctnkojo === updateRow.gctnkojo))[0]
        if (updateTarget === undefined) {   
          return dataRow
        } else {
          //更新対象が存在する場合、変更を反映する
          return { ...updateTarget,
            id: dataRow.id,
            status: nullToBrank(updateTarget.errmessage) === '' ? '' : 'ERR',
            gkzkojst : nullToBrank(updateTarget.gkzkojst),
            gkzkojstmei : nullToBrank(updateTarget.gkzkojstmei),
            gctnkojo : nullToBrank(updateTarget.gctnkojo),
            gctnkojomei : nullToBrank(updateTarget.gctnkojomei),
            gcgcents : nullToBrank(updateTarget.gcgcents),
            gcgcentsmei : nullToBrank(updateTarget.gcgcentsmei),
            gzbiko : nullToBrank(updateTarget.gzbiko),
            errmessage : nullToBrank(updateTarget.errmessage)
          }
        }
      });
    }
    if (result.fail.count > 0) {
      newList = newList.map((dataRow: rowInterface) => {
        // 更新対象をキー(生産本部区分、ＳＴＰ生産工場)でフィルターする
        const failTarget: any = result.fail.records?.filter(updateRow => 
                                  (dataRow.gkzkojst === updateRow.gkzkojst && dataRow.gctnkojo === updateRow.gctnkojo))[0]
        if (failTarget === undefined) {   
          return dataRow
        } else {
          //更新対象が存在する場合、変更を反映する
          return { ...failTarget,
            id: dataRow.id,
            status: 'ERR' + dataRow.status,
            gkzkojst : nullToBrank(failTarget.gkzkojst),
            gkzkojstmei : nullToBrank(failTarget.gkzkojstmei),
            gctnkojo : nullToBrank(failTarget.gctnkojo),
            gctnkojomei : nullToBrank(failTarget.gctnkojomei),
            gcgcents : nullToBrank(failTarget.gcgcents),
            gcgcentsmei : nullToBrank(failTarget.gcgcentsmei),
            gzbiko : nullToBrank(failTarget.gzbiko),
            insdate : dataRow.insdate,
            insuser : dataRow.insuser,
            upddate : dataRow.upddate,
            upduser : dataRow.upduser,
            errmessage : nullToBrank(failTarget.errmessage)
          }
        }
      });
    }
    updateResult.current = result
    setPosts(newList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  //削除ボタン押下後の表示明細の削除
  const delResult = ((result:updateResultInterface) => {
    // console.debug('post:', post)
    // console.debug('delResult:', result)
    resetResult()

    //新規削除行を取得
    let addList = selectionModel.filter(({ status }) => status.endsWith('NEW'))
    // console.debug('addList:',addList)

    //削除前の画面表示行 - 新規削除行 - DB削除成功行 = 削除後の画面表示行に削除失敗行のエラーメッセージを反映
    let newList:rowInterface[] = []
    //画面表示行から新規削除行を除去
    newList = post.filter(i => addList.indexOf(i) === -1)
    // console.debug('newList:',newList)

    //画面表示行からDB削除成功行を除去
    if (result.success.count > 0) {
      // DB削除成功行以外をキー(生産本部区分、ＳＴＰ生産工場)でフィルターして取得
      newList = newList.filter((dataRow: rowInterface) => 
        result.success.records?.findIndex((deleteRow: rowInterface) => 
        (dataRow.gkzkojst === deleteRow.gkzkojst && dataRow.gctnkojo === deleteRow.gctnkojo)) === -1)
    }
    // console.debug('newList2:',newList)
    if (result.fail.count > 0) {
      newList = newList.map((dataRow: rowInterface) => {
        // 更新対象をキー(生産本部区分、ＳＴＰ生産工場)でフィルターする
        const failTarget: any = result.fail.records?.filter(deleteRow => 
                                  (dataRow.gkzkojst === deleteRow.gkzkojst && dataRow.gctnkojo === deleteRow.gctnkojo))[0]
        if (failTarget === undefined) {   
          return dataRow
        } else {
          //更新対象が存在する場合、変更を反映する
          return { ...dataRow,
            status: dataRow.status.startsWith('ERR') ? dataRow.status : 'ERR' + dataRow.status,
            errmessage : nullToBrank(failTarget.errmessage)
          }
        }
      });
    }
    //新規削除行の件数を加算
    result.totalCount = result.totalCount + addList.length
    result.success.count = result.success.count + addList.length
    deleteResult.current = result
    // console.debug('newList3:',newList)
    setPosts(newList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  //ファイル取込後の表示明細の設定
  const readCsvResult = ((result:rowInterface[]) => {
    console.debug('[readCsvResult]start:', new Date())
    setLoading(true)
    resetResult()
    readCsv.current = result

    // console.debug('post:', post)
    // console.debug('readCsv:', readCsv)
    if (readCsv === null || readCsv === undefined || readCsv.current.length === 0) return

    // 入力値チェック
    let temp:rowInterface 
    readCsv.current.forEach((newRow) => {
      newRow = processRowUpdate(newRow, temp);
    });    

    // idを連番に編集して画面上に表示
    if (post.length===0){
      // 一覧にデータなし
      setPosts(readCsv.current)
    }else{
      // 一覧にデータあり
      const addId = Math.max(...post.map(v => v.id));
      let newList:any = readCsv.current.map((dataRow) => {
        return {...dataRow, id: dataRow.id + addId}
      })
      // console.debug('newList:', newList)
      // console.debug('post.concat(newList):',post.concat(newList))
      setPosts(post.concat(newList))
    }
    setLoading(false)
    console.debug('[readCsvResult]end:', new Date())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  // 追加ボタン押下時の空行追加
  const addDataRow = () => {
    resetResult()
    // 配列内のオブジェクトidの最大値+1
    const id = (post.length === 0) ? 1 : Math.max(...post.map(v => v.id)) + 1; 
    const newValue = { id: id,
      status : 'NEW',
      gkzkojst : '',
      gkzkojstmei : '',
      gctnkojo : '',
      gctnkojomei : '',
      gcgcents : '',
      gcgcentsmei : '',
      gzbiko : '',
      insdate : '',
      insuser : '',
      upddate : '',
      upduser : '',
      errmessage : '',
    };
    setPosts([...post,newValue]);
  }

  //明細変更時の入力値チェック（ファイル読込を含む）
  const processRowUpdate = (newRow: rowInterface, oldRow: rowInterface) => {
    // console.debug('newRow:', newRow);
    // console.debug('oldRow:', oldRow);
    // 新規データ以外の場合、キー項目の変更は不可
    if (!newRow.status.endsWith('NEW')){
      newRow.gkzkojst = oldRow.gkzkojst
      newRow.gctnkojo = oldRow.gctnkojo
    }
    if (JSON.stringify(newRow)===JSON.stringify(oldRow)) {
      return newRow;
    }

    // バリデーションの処理
    newRow.errmessage=''
    //ＳＴＰ生産工場
    if (newRow.errmessage === '') {
      if (newRow.gkzkojst.trim() === ''){
        newRow.errmessage = 'ＳＴＰ生産工場は必須項目です';
      } else {
        newRow.errmessage = chkHalfWidthKeta(newRow.gkzkojst.trim(), 8, 'ＳＴＰ生産工場は')
      }
      newRow.gkzkojst = newRow.gkzkojst.trim().toUpperCase()
    } 
    //投入工場
    if (newRow.errmessage === '') {
      if (newRow.gctnkojo.trim() === ''){
        newRow.errmessage = '投入工場は必須項目です';
      } else {
        newRow.errmessage = chkHalfWidthKeta(newRow.gctnkojo.trim(), 3, '投入工場は')
      }
      newRow.gctnkojo = newRow.gctnkojo.trim().toUpperCase()
    }
    //原価センタ（ＳＴＰ）
    if (newRow.errmessage === '') {
      if (newRow.gcgcents.trim() === ''){
        newRow.errmessage = '原価センタ（ＳＴＰ）は必須項目です';
      } else {
        newRow.errmessage = chkHalfWidthKeta(newRow.gcgcents.trim(), 10, '原価センタ（ＳＴＰ）は')
      }
      newRow.gcgcents = newRow.gcgcents.trim().toUpperCase()
    } 
    //備考
    if (newRow.errmessage === '') {
      if (newRow.gzbiko.trim().length > 20){
        newRow.errmessage = '備考は20桁以下です'
      }
      newRow.gzbiko = newRow.gzbiko.trim()
    }
    //ＳＴＰ生産工場と投入工場の画面上の重複
    if (newRow.errmessage === '') {
      if (post.filter((dataRow) => 
      (dataRow.gkzkojst === newRow.gkzkojst 
        && dataRow.gctnkojo === newRow.gctnkojo)).length > 1) {
        newRow.errmessage = 'ＳＴＰ生産工場と投入工場が重複してます'
      }
    }

    if (newRow.errmessage.length>0) {
      //エラーあり
      if (newRow.status.indexOf('ERR')<0) {
        //ERR設定済の場合、設定しない
        newRow.status='ERR'+newRow.status
      }
    } else {
      //エラーなし
      if (newRow.status.indexOf('ERR')>=0) {
        //ERR設定済の場合、削除する
        newRow.status=newRow.status.replace('ERR','')
      }
      if (newRow.status.indexOf('NEW')<0) {
        //NEWではない場合、UPDを設定する
        newRow.status='UPD'
      }
    }
    if (oldRow !== undefined) {
      setPosts(
        post.map((dataRow) => {
          if (dataRow.id === newRow.id) {
            return newRow;
          } else {
            return dataRow;
          }
        })
      )   
    }
    return newRow;
  }; 

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport               
          csvOptions={{
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    )
  }

  return (
    <GenericTemplate title='ライン別原価センター' >
      <Stack spacing={1} direction='column' >
        <Stack spacing={2} direction='row' alignItems='center'>
            {/* ＳＴＰ生産工場 */}
            <FromTextFieldSeisanKojo 
              helperText={seisanKojoError}
              onChange={(event) => {
                setSeisanKojoError(chkHalfWidth(event.target.value)); 
                setSeisanKojo(event.target.value)
              }}
            />
            {/* 投入工場 */}
            <FromTextFieldTounyuKojo
              helperText={tonyuKojoError}
              onChange={(event) => {
                setTonyuKojoError(chkHalfWidth(event.target.value)); 
                setTonyuKojo(event.target.value)
              }}
            />
            {/* 検索ボタン */}
            <ButtonSearch
              apiParam={apiGetParam}
              apiUrl={apiGetUrl}
              apiKey={apiKey}
              getPost={selResult}
              disabled={(seisanKojo === '' && tonyuKojo === '') 
                      || seisanKojoError !== '' || tonyuKojoError !== ''}
            />
            {HanbaikanriMasterEditor &&
            <Box sx={{ display: 'flex'}}>
              {/* 更新ボタン */}
              <ButtonUpdate
                apiParam={apiPutParam}
                apiUrl={apiPutUrl}
                apiKey={apiKey}
                result={updResult}
                message={'更新したデータを反映しますか？'}
                name='更新'
                disabled={false}
              />
              {/* 削除ボタン */}
              <ButtonDelete
                apiParam={apiDelParam}
                apiUrl={apiDelUrl}
                apiKey={apiKey}
                result={delResult}
                message={'削除チェックされたデータを削除しますか？'}
                name='削除'
                disabled={false}
              />
              {/* 全件出力ボタン */}
              <ButtonCsvDownload
                apiParam={apiGetParamAll}
                apiUrl={apiGetUrl}
                apiKey={apiKey}
                buttonName='全件出力'
                DLFileHeader2={DLFileHeader}
                DLFileName={'ライン別原価センター全件出力_'+ moment(new Date()).format('YYYYMMDDHHmmss') + '.csv'}
              />
              {/* Dropzone */}
              <Dropzone
                setPost={readCsvResult}
              />
              {/* 追加ボタン */}
              <ButtonAction 
                onAction={addDataRow}
                name='明細追加'
              />
              {/* 更新処理の成功時のメッセージ */}
              {updateResult.current.success.count !== 0 &&
                <div>
                  <Alert severity='info'>
                    更新を完了しました({updateResult.current.success.count}件)
                  </Alert>
                </div>
              }
              {/* 更新処理の失敗時のメッセージ */}
              {updateResult.current.fail.count !== 0 &&
                <div>
                  <Alert severity='warning'>
                    更新に失敗しました({updateResult.current.fail.count}件)。結果を確認してください
                  </Alert>
                </div>
              }
              {/* 削除処理の成功時のメッセージ */}
              {deleteResult.current.success.count !== 0 &&
                <div>
                  <Alert severity='info'>
                  削除を完了しました({deleteResult.current.success.count}件)
                  </Alert>
                </div>
              }
              {/* 削除処理の失敗時のメッセージ */}
              {deleteResult.current.fail.count !== 0 &&
                <div>
                  <Alert severity='warning'>
                  削除に失敗しました({deleteResult.current.fail.count}件)。結果を確認してください
                  </Alert>
                </div>
              }
            </Box>
            }
        </Stack>
        <div>
          <Box sx={{ height: 655, width: '100%' }}>
          <DataGrid 
              // 非表示項目設定
              columnVisibilityModel={{
                  id: false,
                  status: HanbaikanriMasterEditor,
                  errmessage: HanbaikanriMasterEditor,
              }}
              components={{
                Toolbar: CustomToolbar
              }}
              density='compact' //グリッド内のボタンもsmallにする
              rows={post} 
              columns={columns}
              rowsPerPageOptions={[20, 50, 100]}
              editMode='row' 
              experimentalFeatures={{ newEditingApi: true }}
              checkboxSelection={HanbaikanriMasterEditor}
              disableSelectionOnClick
              onSelectionModelChange={(RowId) => {
                // 選択された行を特定するための処理
                const selectedRowId = new Set(RowId);
                const selectedRows = post.filter((dataGridRow) =>
                  selectedRowId.has(dataGridRow.id)
                );
                setSelectionModel(selectedRows);
              }}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={(error) => console.log(error)}
            />
            {loading && (
              <CircularProgress
                size={50}
                sx={{
                  color: green[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        </div>
      </Stack>
    </GenericTemplate>
  )
}

export default P01Mst101Hanbai002