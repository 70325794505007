import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ReactToPrint from 'react-to-print';

// ----------------------------------------------------
// MaterialUiのButton コンポーネント 印刷ボタン
// ----------------------------------------------------
type Props = {
    printComponentRef: React.MutableRefObject<null>;
    documentTitle: string;
}

const ButtonPrint = (
    props: Props,
):any => {
//    const componentRef = useRef(null);
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ m: 1, position: 'relative' }}>
                <ReactToPrint
                    trigger={() => (
                        <Button>
                            印刷
                        </Button>
                    )}
                    content={() => props.printComponentRef.current}
                    documentTitle={props.documentTitle}
                />
            </Box>
        </Box>
    )
}

export default ButtonPrint;