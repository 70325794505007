// /src/pages/AboutPage.tsx
import React from 'react';

import GenericTemplate from '../templates/GenericTemplate';

const AboutPage: React.FC = () => {
  return (
    <GenericTemplate title="サイト利用上の注意事項">
      <div>
        「三協立山データ照会システム」（以下、当サイトとする）は、三協立山株式会社（以下、当社とする）が管理、運営しています。<br />
        当サイトを利用される場合は、ここに記載した条件に準拠いただくものとします。当サイトをご利用いただいた時点で、以下利用条件の全てに同意頂いたものとさせて頂きます。<br />
        <ol>
          <li>当サイトに掲載されている情報は、当社の業務にかかわる用途にのみ利用できます。それ以外の用途での利用は認められません。</li>
          <li>第三者、または当社に損害や不利益を与える行為、第三者、または当社の信用や名誉を損なう行為は禁止とさせて頂きます。</li>
          <li>当サイトに収録・掲載されているコンテンツのレイアウト、デザインおよび構造に関する著作権は当社に帰属します。画像等ご利用の際には事前に当社の承諾が必要です。</li>
        </ol>
      </div>
    </GenericTemplate>
  );
};

export default AboutPage;