import React, { useState } from 'react';
import requests from '../../utils/aqua/Request';
import GenericTemplate from '../../templates/GenericTemplate';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import MUIDataTable from "mui-datatables";
import DesktopDatePickerCommon from '../../components/DesktopDatePicker/DesktopDatePickerCommon';
import FromTextFieldHinmokuNo from '../../components/TextField/TextFieldHinmokuCd';
import FromTextFieldCyumonno from '../../components/TextField/TextFieldKobaiCyumonno';
import FromTextFieldUketukeno from '../../components/TextField/TextFieldKobaiUketukeno';
import FromTextFieldTorihikisakicd from '../../components/TextField/TextFieldTorihikisakicd';
import SelectZaikobashoCd from '../../components/Select/SelectZaikobasho';
import RadioAcceptance from '../../components/Radio/RadioAcceptance';
import ButtonSearch from '../../components/Button/ButtonSearch';
import ButtonFileDownload from '../../components/ButtonFileDownload';
import ButtonDialog from '../../components/Button/ButtonDialog';

const P01Prg101Buhin001: React.FC = () => {
    //変数定義
    const [post, setPosts] = useState([])
    //納期日(始点)
    const [snouki, setSnouki] = useState<Date | undefined>(new Date());
    //納期日(終点)
    const [enouki, setEnouki] = useState<Date | undefined>(new Date());
    //注文番号
    const [cyumonno, setCyumonno] = useState('');
    //受付番号
    const [uketukeno, setUketukeno] = useState('');
    //品目番号
    const [hinmokuno, setHinmokuno] = useState('');
    //仕入先
    const [shiiresaki, setShiiresaki] = useState('');
    //在庫場所CD
    const [nonyubasho1, setNonyubasho1] = useState([]);
    //未受入のみ
    const [selectedValue, setSelectedValue] = React.useState('true');

    // api 用のパラメータを設定する
    const apiUrl = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Prg101BuhinAcceptance)
    const apiKey = String(process.env.REACT_APP_APIKEY_AP00447)
    const apiParam = {
        snouki: moment(snouki).format("YYYY-MM-DD"),
        enouki: moment(enouki).format("YYYY-MM-DD"),
        cyumonno: cyumonno,
        ukeirenomicheck: selectedValue,
        uketukeno: uketukeno,
        hinmokuno: hinmokuno,
        shiiresaki: shiiresaki,
        nonyubasho1: nonyubasho1,
    };

    const column = [
        { name: "kakutei_nouki", label: "確定納期", },
        { name: "shiiresaki", label: "仕入先", },
        { name: "nonyubasho1", label: "納入場所", },
        { name: "cyumon_no", label: "注文番号", },
        { name: "uketuke_no", label: "受付番号", },
        { name: "hinmoku_no", label: "品目番号", },
        { name: "hinmokumei", label: "品目名", },
        { name: "kigosunpo", label: "記号-寸法", },
        {
            name: "haccyusu", label: "発注数",
            options: {
                setCellHeaderProps: () => ({ style: { textAlign: "right" } }),
                setCellProps: () => ({ align: "right" })
            }
        },
        { name: "nounyukanryo_mk", label: "納完", },
        { name: "acceptTanaNo", label: "棚番", },
        { name: "acceptNitiji", label: "受入日時", },
        { name: "payoutNitiji", label: "払出日時", },
    ];

    return (
        <GenericTemplate title="" >
            <Stack spacing={1} direction="column" >
                <Stack spacing={2} direction="row">
                    {/*  納期(スタート) */}
                    <DesktopDatePickerCommon
                        label="納期(スタート)"
                        value={snouki}
                        onChange={(newSnouki: any) => { setSnouki(newSnouki); }}
                    />
                    {/*  ～ */}
                    <div>
                        <p style={{ textAlign: "center" }}> ～ </p>
                    </div>
                    {/*  納期(エンド) */}
                    <DesktopDatePickerCommon
                        label="納期(エンド)"
                        value={enouki}
                        onChange={(newEnouki: any) => { setEnouki(newEnouki); }}
                    />
                </Stack>
                <Stack spacing={2} direction="row">
                    {/* 注文番号 */}
                    <FromTextFieldCyumonno onChange={(event) => setCyumonno(event.target.value)} />
                    {/* 受入番号 */}
                    <FromTextFieldUketukeno onChange={(event) => setUketukeno(event.target.value)} />
                    {/* 品目番号 */}
                    <FromTextFieldHinmokuNo onChange={(event) => setHinmokuno(event.target.value)} />
                    {/* 取引先CD */}
                    <FromTextFieldTorihikisakicd onChange={(event) => setShiiresaki(event.target.value)} />
                    {/* 在庫場所CD */}
                    <SelectZaikobashoCd onChange={(event) => setNonyubasho1(event.target.value)} />
                    {/* 受入ラジオボタン */}
                    <RadioAcceptance setSelectedValue={setSelectedValue}  />

                    {/* 羂索・ボタン */}
                    <ButtonSearch
                        apiParam={apiParam}
                        apiUrl={apiUrl}
                        apiKey={apiKey}
                        getPost={setPosts}
                    />
                    <ButtonDialog
                        buttonName="CSV出力"
                        dialogUrl="/aqua/PrgBuhinHdtdataDownload2"
                        dialogTarget="hdtBuhinLogOutput"
                        dialogSize="top=100,left=100,width=500,height=400"
                    />
                </Stack>
                <div style={{ height: '50%', width: '100%', }}>
                    <MUIDataTable
                        title="補強材受入"
                        data={post}
                        columns={column}
                        options={{
                            selectableRows: 'none',
                            rowsPerPage: 20,
                            viewColumns: false,
                            rowsPerPageOptions: [20, 50, 100],
                            sort: false,
                            print: false,
                            setTableProps: () => {
                                return {
                                    size: "small",
                                    style: { whiteSpace: "nowrap" },
                                };
                            },
                        }}
                    />
                </div>
                <Stack spacing={1} direction="row">
                    <div style={{ flexGrow: 1 }}></div>
                    <ButtonFileDownload name="棚番" url="/pdf/aqua/tanaban.pdf" filename="tanaban.pdf" />
                    <ButtonFileDownload name="下敷き(受入)" url="/pdf/aqua/shogoHokyoUkeire.pdf" filename="shogoHokyoUkeire.pdf" />
                    <ButtonFileDownload name="下敷き(払出)" url="/pdf/aqua/shogoHokyoHaraidasi.pdf" filename="shogoHokyoHaraidasi.pdf" />
                </Stack>
            </Stack>
        </GenericTemplate>
    )
}
export default P01Prg101Buhin001