import React, { useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import requests from '../../utils/monoss/Request';
import GenericTemplate from '../../templates/GenericTemplate';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import MUIDataTable from "mui-datatables";
import FromTextFieldZairyoNo from '../../components/TextField/TextFieldZairyoNo';
import { chkHalfWidth, chkEmpty } from '../../common/chkTextValue';
import {Auth} from 'aws-amplify';

const P01Prg001Kata001: React.FC = () => {
  //変数定義
  const [post, setPosts] = useState([])
  //材料番号
  const [hinban, setHinban] = useState('');
  const [hinbanError, setHinbanError] = useState('');

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  //requests.p01Mst001Itemにリクエストする関数。
  const fetchRequest = async() => {
    setPosts([])

    //requestを投げるURL作成
    const URL = process.env.REACT_APP_APIURL_MONOSS + requests.p01Mst001Structurematerial + '/' + hinban
    const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()

      axios.get(URL,
      {
        headers: {
          "X-API-KEY": process.env.REACT_APP_APIKEY_MONOSS || "",
          "Authorization": AuthToken || "",
        },
      }).then((res) => {
        //成功したら Posts に result を格納。
        // console.log(res.data.result);
        setPosts(res.data.result)
        if (res.data.result.length === 0) {
          alert('データはありません')
        }
        console.debug(res.data.result)
      }).catch(error => {
        console.log(error);
        alert('検索に失敗しました')
      }).finally(() => {
        setLoading(false);
      });
  };

  //検索・ボタン押下時の処理 
  const handleClick = (() => {
    let errMsg = ""
    //未入力チェック
    errMsg = chkEmpty(hinban)
    if (errMsg.length > 0) {
      setHinbanError(errMsg)
      alert('検索条件を入力してください');
      return;
    }
    //半角文字チェック 
    errMsg = chkHalfWidth(hinban)
    if (errMsg.length > 0) {
      setHinbanError(errMsg)
      alert(errMsg);
      return;
    }

    if (!loading) {
      setSuccess(false);
      setLoading(true);
      //apiコールする関数を呼び出す
      fetchRequest()
      setSuccess(true);
    }
  })

  //入力値チェック処理：材料番号
  const chkHinban = (event: any) => {
    let val = event.target.value
    setHinbanError(chkHalfWidth(val))
    setHinban(val)
  }

  const column = [
    {
      name: "jczaiban", label: "材料番号", options: {
        setCellProps: () => ({ style: { position: "sticky", left: 0, background: "white", zIndex: 100 } }),
        setCellHeaderProps: () => ({ style: { position: "sticky", left: 0, background: "white", zIndex: 101 } })
      }
    },
    {
      name: "jczaikbn", label: "材料区分", options: {
        hint: ('Ａ：形材 Ｂ：部品')
      }
    },
    { name: "jizmeisy", label: "材料名称（カナ）", },
    { name: "jjzmeisy", label: "材料名称（漢字）", },
    {
      name: "jbbessou", label: "別送可否", options: {
        hint: ('△：工場付け １：現地付け')
      }
    },
    {
      name: "jbtanfuk", label: "単複区分", options: {
        hint: ('Ｓ：単品 Ｄ：複合部品')
      }
    },
    {
      name: "jbkatte", label: "勝手区分", options: {
        hint: ('０：無し １：有り')
      }
    },
    {
      name: "size", label: "サイズ", options: {
        hint: ('０：無し １：有り')
      }
    },
    { name: "hoho", label: "取付方法", },
    { name: "buhinirocd1", label: "部品色コード１", },
    { name: "buhinirocd2", label: "部品色コード２", },
    { name: "buhinirocd3", label: "部品色コード３", },
    { name: "buhinirocd4", label: "部品色コード４", },
    { name: "buhinirocd5", label: "部品色コード５", },
    { name: "buhinirocd6", label: "部品色コード６", },
    { name: "sikityocd1", label: "色調：SLV", },
    { name: "sikityocd2", label: "色調：UCZ", },
    { name: "sikityocd3", label: "色調：BRZ", },
    { name: "sikityocd4", label: "色調：BKZ", },
    { name: "sikityocd5", label: "色調：KGZ", },
    { name: "sikityocd6", label: "色調：SLG", },
    { name: "hyojnage", label: "標準仕上", },
    {
      name: "jbbunrui", label: "管理分類", options: {
        hint: ('１：制定品 ３：物件 ４：他社形材 ５：ダミー')
      }
    },
    { name: "jcirotbl", label: "色テーブルコード", },
    { name: "jbdoukon", label: "同梱マーク", },
    { name: "jbharai", label: "材料払出マーク", },
    { name: "jdadd", label: "登録日", },
    { name: "jdupdate", label: "変更日", },
    // { name: "jyyobi21", label: "予備", },

  ];

  return (
    <GenericTemplate title="" >
      <Stack spacing={1} direction="column" >
        <Stack spacing={2} direction="row">
          {/* 材料番号 */}
          <FromTextFieldZairyoNo onChange={chkHinban}
            helperText={hinbanError}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ m: 1, position: 'relative' }}>
              <Button variant="contained"
                sx={buttonSx}
                disabled={loading}
                onClick={() => { handleClick(); }}>
                検索
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
          </Box>
        </Stack>
        <div style={{ height: '50%', width: '100%', }}>
          <MUIDataTable
            title="構成材(monos生産)"
            data={post}
            columns={column}
            options={{
              selectableRows: 'none',
              rowsPerPage: 20,
              viewColumns: false,
              rowsPerPageOptions: [20, 50, 100],
              sort: false,
              print: false,
              setTableProps: () => {
                return {
                  // padding: 'none',
                  size: "small",
                  style: { whiteSpace: "nowrap" }
                };
              },
            }}
          />
        </div>
      </Stack>
    </GenericTemplate>
  )
}

export default P01Prg001Kata001