import { useState, useEffect } from 'react';
import { DataGrid, GridColDef, } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { buzaiInterface } from '../../pages/aqua/P01Put001Konpo005';

type Props = {
    dataList: buzaiInterface[]
}

const columns: GridColDef[] = [
    { field: 'grp_no_buzai', headerName: '番号', width: 70, },
    { field: 'tategumei', headerName: '建具名', width: 200, },
    { field: 'kataban', headerName: '型番', width: 120, },
    { field: 'iro', headerName: '色', width: 70, },
    { field: 'setudan_sunpo', headerName: '切断寸', width: 70, align: 'right', },
    { field: 'setsu', headerName: '本数', width: 70, align: 'right', },
    { field: 'cd_basho1', headerName: 'ライン', width: 70, },
];

const DataGridPutBuzai = (props: Props,) => {
    // *************
    // 変数定義
    // *************
    //検索結果を管理するuseState
    const [post, setPosts] = useState<buzaiInterface[]>([])

    //画面初期表示
    useEffect(() => {
        // console.debug(props.dataList)
        // setPosts(props.dataList);
        setPosts(
            props.dataList.map((dataRow: buzaiInterface) => {
                return {...dataRow, 
                    setsu: String(Number(dataRow.setsu) * Number(dataRow.tanisu)),
                };
            })
        )
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <Typography variant="h6" component="h2">
                【部材】
            </Typography>
            <Box sx={{ height: 200, width: 740,  }}>
                <DataGrid 
                    density="compact"
                    rows={post}
                    columns={columns}
                    rowsPerPageOptions={[20, 50, 100]}
                    experimentalFeatures={{ newEditingApi: true }}
                    showCellRightBorder
                />
            </Box>
        </div>
    );
};

export default DataGridPutBuzai;

