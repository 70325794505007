import React from 'react';

type reportType = {
  BukkenNo: string,
  Kojimei: string,
  SyusekiNo: string,
  LastCheckedDate: string,
  LastFirstQa_ja: string,
  LastSecondQa_ja: string,
  pageStr: string,
  Detail: reportDetailType[],
}
type reportDetailType = {
  id: number,
  Madoban: string,
  Buzaimei: string,
  Setsu: string,
  Kanryosu: string,
  Kamikotei: string,
  GKns01: string, GKns02: string, GKns03: string, GKns04: string, GKns05: string, GKns06: string, GKns07: string, GKns08: string, GKns09: string, GKns10: string,
  KKns01: string, KKns02: string, KKns03: string, KKns04: string, KKns05: string, KKns06: string, KKns07: string,
  SKns01: string, SKns02: string, SKns03: string, SKns04: string, SKns05: string, SKns06: string, SKns07: string, SKns08: string, SKns09: string, SKns10: string,
  Yojo: string, Hantei: string, Biko: string
}

interface Props {
  reportData: any[]
}
interface State { }

function toDateYMD (inString: string) {
  if (inString === '') {
    return '　　　年　　月　　日'
  }
  else if (inString === undefined) {
    return '　　　年　　月　　日'
  }
  else if (inString === null) {
    return '　　　年　　月　　日'
  }
  let toDate = new Date(inString)
  return '　' + toDate.getFullYear() + '年　' 
  + (toDate.getMonth() + 1 ).toString().padStart(2, "0") + '月　' 
  + toDate.getDate().toString().padStart(2, "0") + '日'
}

// 最大値取得
const aryMax = function (a:any, b:any) { return a > b ? a : b; }

function reportRows(rows: any[]) {
  const maxPage = 18  //1ページ上限明細数

  let reportData: reportType[] = []
  let Detail: reportDetailType[] = []

  let preBukkenNo = rows.length === 0 ? '' : rows[0].BukkenNo
  let preSyusekiNo = rows.length === 0 ? '' : rows[0].SyusekiNo
  let preKojimei = rows.length === 0 ? '' : rows[0].Kojimei
  let LastCheckedDate = ''
  let LastFirstQa_ja = ''
  let LastSecondQa_ja = ''
  let pageNum = 1

  rows.map((row, index) => {
    // 帳票ページブレイクキー(物件番号+集積番号)したことを判定しデータをpushする
    if (!(preBukkenNo === row.BukkenNo && preSyusekiNo === row.SyusekiNo) && Detail.length > 0) {
      // Detail.length が1頁上限になるまでDetailに空行を追加
      for (let i = Detail.length; i % maxPage !== 0; i++) {
        Detail.push({
          "id": Math.max(...Detail.map(v => v.id)) + 1,
          "Madoban": '',
          "Buzaimei": '',
          "Setsu": '',
          "Kanryosu": '',
          "Kamikotei": '',
          "GKns01": '', "GKns02": '', "GKns03": '', "GKns04": '', "GKns05": '', "GKns06": '', "GKns07": '', "GKns08": '', "GKns09": '', "GKns10": '',
          "KKns01": '', "KKns02": '', "KKns03": '', "KKns04": '', "KKns05": '', "KKns06": '', "KKns07": '',
          "SKns01": '', "SKns02": '', "SKns03": '', "SKns04": '', "SKns05": '', "SKns06": '', "SKns07": '', "SKns08": '', "SKns09": '', "SKns10": '',
          "Yojo": '', "Hantei": '', "Biko": ''
        })
      }

      // 最終検査日 を取得
      LastCheckedDate = toDateYMD(rows.filter(value => {
        // 帳票ページブレイクキー(物件番号+集積番号)の明細取得
        return value.BukkenNo === preBukkenNo && value.SyusekiNo === preSyusekiNo
      }).map(value => {
        // 検査日 を取得
        return value.CheckedDate
      }).reduce(aryMax))
      // 最終第一承認者 を取得
      let tantoList = rows.filter(value => {
        // 帳票ページブレイクキー(物件番号+集積番号) かつ 第一承認済みの明細取得
        return value.BukkenNo === preBukkenNo && value.SyusekiNo === preSyusekiNo && value.FirstQaDate !== ''
      }).map(value => {
        return {'FirstQaDate': value.FirstQaDate, 'FirstQa_ja':value.FirstQa_ja
      }})
      if (tantoList.length > 0) {
        tantoList.sort((a, b) =>a.FirstQaDate > b.FirstQaDate ? -1 : 1);
        LastFirstQa_ja = tantoList[0].FirstQa_ja
      }
      // 最終第二承認者 を取得
      let sekininList = rows.filter(value => {
        // 帳票ページブレイクキー(物件番号+集積番号) かつ 第二承認済みの明細取得
        return value.BukkenNo === preBukkenNo && value.SyusekiNo === preSyusekiNo && value.SecondQaDate !== ''
      }).map(value => {
        return {'SecondQaDate': value.SecondQaDate, 'SecondQa_ja': value.SecondQa_ja
      }})
      if (sekininList.length > 0) {
        sekininList.sort((a, b) =>a.SecondQaDate > b.SecondQaDate ? -1 : 1);
        LastSecondQa_ja = sekininList[0].SecondQa_ja
      }
      
      reportData.push({
        "BukkenNo": preBukkenNo,
        "Kojimei": preKojimei,
        "SyusekiNo": preSyusekiNo,
        "LastCheckedDate": LastCheckedDate,
        "LastFirstQa_ja": LastFirstQa_ja,
        "LastSecondQa_ja": LastSecondQa_ja,
        "pageStr": String(pageNum++).padStart(3, ' '),
        "Detail": Detail,
      })
      //明細(窓番、組立ユニット)を初期化する
      Detail = []
      LastCheckedDate = ""
      LastFirstQa_ja = ""
      LastSecondQa_ja = ""
    }

    Detail.push({
      "id": row.id,
      "Madoban": row.Madoban,
      "Buzaimei": row.Buzaimei,
      "Setsu": row.Setsu,
      "Kanryosu": row.Kanryosu,
      "Kamikotei": row.Kamikotei,
      "GKns01": row.GKns01, "GKns02": row.GKns02, "GKns03": row.GKns03, "GKns04": row.GKns04, "GKns05": row.GKns05, "GKns06": row.GKns06, "GKns07": row.GKns07, "GKns08": row.GKns08, "GKns09": row.GKns09, "GKns10": row.GKns10,
      "KKns01": row.KKns01, "KKns02": row.KKns02, "KKns03": row.KKns03, "KKns04": row.KKns04, "KKns05": row.KKns05, "KKns06": row.KKns06, "KKns07": row.KKns07,
      "SKns01": row.SKns01, "SKns02": row.SKns02, "SKns03": row.SKns03, "SKns04": row.SKns04, "SKns05": row.SKns05, "SKns06": row.SKns06, "SKns07": row.SKns07, "SKns08": row.SKns08, "SKns09": row.SKns09, "SKns10": row.SKns10,
      "Yojo": row.Yojo, "Hantei": row.Hantei === '〇' ? '合格':'不合格', "Biko": row.Biko
    })

    // 最後にレコードを追加する または Detail.length が1頁上限の場合、改ページを追加
    if ((rows.length === index + 1 && Detail.length > 0) || Detail.length === maxPage) {
      // Detail.length が1頁上限になるまでDetailに空行を追加
      for (let i = Detail.length; i % maxPage !== 0; i++) {
        Detail.push({
          "id": Math.max(...Detail.map(v => v.id)) + 1,
          "Madoban": '',
          "Buzaimei": '',
          "Setsu": '',
          "Kanryosu": '',
          "Kamikotei": '',
          "GKns01": '', "GKns02": '', "GKns03": '', "GKns04": '', "GKns05": '', "GKns06": '', "GKns07": '', "GKns08": '', "GKns09": '', "GKns10": '',
          "KKns01": '', "KKns02": '', "KKns03": '', "KKns04": '', "KKns05": '', "KKns06": '', "KKns07": '',
          "SKns01": '', "SKns02": '', "SKns03": '', "SKns04": '', "SKns05": '', "SKns06": '', "SKns07": '', "SKns08": '', "SKns09": '', "SKns10": '',
          "Yojo": '', "Hantei": '', "Biko": ''
        })
      }

      // 最終検査日 を取得
      LastCheckedDate = toDateYMD(rows.filter(value => {
        // 帳票ページブレイクキー(物件番号+集積番号)の明細取得
        return value.BukkenNo === row.BukkenNo && value.SyusekiNo === row.SyusekiNo
      }).map(value => {
        // 検査日 を取得
        return value.CheckedDate
      }).reduce(aryMax))
      // 最終第一承認者 を取得
      let tantoList = rows.filter(value => {
        // 帳票ページブレイクキー(物件番号+集積番号) かつ 第一承認済みの明細取得
        return value.BukkenNo === row.BukkenNo && value.SyusekiNo === row.SyusekiNo && value.FirstQaDate !== ''
      }).map(value => {
        return {'FirstQaDate': value.FirstQaDate, 'FirstQa_ja':value.FirstQa_ja
      }})
      if (tantoList.length > 0) {
        tantoList.sort((a, b) =>a.FirstQaDate > b.FirstQaDate ? -1 : 1);
        LastFirstQa_ja = tantoList[0].FirstQa_ja
      }
      // 最終第二承認者 を取得
      let sekininList = rows.filter(value => {
        // 帳票ページブレイクキー(物件番号+集積番号) かつ 第二承認済みの明細取得
        return value.BukkenNo === row.BukkenNo && value.SyusekiNo === row.SyusekiNo && value.SecondQaDate !== ''
      }).map(value => {
        return {'SecondQaDate': value.SecondQaDate, 'SecondQa_ja': value.SecondQa_ja
      }})
      if (sekininList.length > 0) {
        sekininList.sort((a, b) =>a.SecondQaDate > b.SecondQaDate ? -1 : 1);
        LastSecondQa_ja = sekininList[0].SecondQa_ja
      }

      reportData.push({
        "BukkenNo": row.BukkenNo,
        "Kojimei": row.Kojimei,
        "SyusekiNo": row.SyusekiNo,
        "LastCheckedDate": LastCheckedDate,
        "LastFirstQa_ja": LastFirstQa_ja,
        "LastSecondQa_ja": LastSecondQa_ja,
        "pageStr": String(pageNum++).padStart(3, ' '),
        "Detail": Detail,
      })
      //明細(窓番、組立ユニット)を初期化する
      Detail = []
    }
    preBukkenNo = row.BukkenNo
    preSyusekiNo = row.SyusekiNo
    preKojimei = row.Kojimei
  })
  return (
    reportData
  )
};
export default class ReportBa01R02 extends React.Component<Props, State> {
  public render(): JSX.Element {
    const reportdatax = reportRows(this.props.reportData)

    return (
      <div className='report_page'>
        {reportdatax.map((summary: reportType, index:Number) =>
          <div className='pdf_page'>
            <table >
              <colgroup>
                <col className='colgroup_col01'/>
                <col className='colgroup_col04'/>
                <col className='colgroup_col02'/>
                <col className='colgroup_col02'/>
                <col className='colgroup_col02'/>
                <col className='colgroup_col02'/>
                <col className='colgroup_col02'/>
                <col className='colgroup_col02'/>
                <col className='colgroup_col02'/>
                <col className='colgroup_col02'/>
                <col className='colgroup_col03'/>
                <col className='colgroup_col03'/>
                <col className='colgroup_col02'/>
                <col className='colgroup_col02'/>
                <col className='colgroup_col02'/>
                <col className='colgroup_col02'/>
                <col className='colgroup_col02'/>
                <col className='colgroup_col02'/>
                <col className='colgroup_col02'/>
                <col className='colgroup_col03'/>
              </colgroup>
              <thead className='thead'>
                <tr className='header_height20'>
                  <th className='block_none_r' colSpan={20}>三協立山株式会社 三協アルミ社</th>
                </tr>
                <tr className='header_height20'>
                  <th className='block_none_r_under' colSpan={20}>No.&emsp;{summary.pageStr}</th>
                </tr>
                <tr className='header_height20'>
                  <th className='block_TopLeft_l' colSpan={13}>工事名</th>
                  <th className='block_Top_l' colSpan={6}>物件番号</th>
                  <th className='block_TopRight_l'>{summary.SyusekiNo}</th>
                </tr>
                <tr className='header_height40'>
                  <th className='block_Left_l report_font_medium' colSpan={13}>&emsp;{summary.Kojimei}</th>
                  <th className='block_Right_l report_font_medium' colSpan={7}>&emsp;{summary.BukkenNo}</th>
                </tr>
                <tr className='header_height20'>
                  <th className='block_TopLeft_cb report_font_title' colSpan={10} rowSpan={2}>完成品検査成績書</th>
                  <th className='block_TopLeft_l' colSpan={5}>&emsp;検査日</th>
                  <th className='block_TopLeft_c' colSpan={3}>責任者</th>
                  <th className='block_TopRightLeft_c' colSpan={2}>担当</th>
                </tr>
                <tr className='header_height80'>
                  <th className='block_Left_l' colSpan={5} rowSpan={2}>&emsp;{summary.LastCheckedDate}</th>
                  <th className='block_TopLeft_c' colSpan={3} rowSpan={2}>{summary.LastSecondQa_ja}</th>
                  <th className='block_TopRightLeft_c' colSpan={2} rowSpan={2}>{summary.LastFirstQa_ja}</th>
                </tr>
                <tr className='header_height20'>
                  <th className='block_Left_c report_font_small' colSpan={10}>（ノックダウン部材、ＣＷ）</th>
                </tr>
                <tr className='header_height40'>
                  <th className='block_TopLeft_c' colSpan={2} rowSpan={3}>部材符号<br/>(部材名称)</th>
                  <th className='block_TopLeft_c' colSpan={2}>項目</th>
                  <th className='block_TopLeft_c' colSpan={6}>外観</th>
                  <th className='block_TopLeft_c' colSpan={4}>寸法</th>
                  <th className='block_TopLeft_c' colSpan={5}>機能</th>
                  <th className='block_TopRightLeft_c report_font_medium' rowSpan={3}>判<br/>定</th>
                </tr>
                <tr className='header_height40'>
                  <th className='block_TopLeft_c report_font_small' colSpan={2}>判定<br/>基準</th>
                  <th className='block_TopLeft_c' colSpan={6}>標準限度見本通り</th>
                  <th className='block_TopLeft_c report_font_small' colSpan={4}>許容差<br/>（下記表①に示す）</th>
                  <th className='block_TopLeft_c' colSpan={5}>異常がないこと</th>
                </tr>
                <tr className='header_height40'>
                  <th className='block_TopLeft_c'><span className='block_TopLeft_P'>箇所数</span></th>
                  <th className='block_TopLeft_c'><span className='block_TopLeft_P'>試料数</span></th>
                  <th className='block_TopLeft_c report_font_small10'><span className='block_TopLeft_P'>きず・割れ・カエリ・欠け</span></th>
                  <th className='block_TopLeft_c'><span className='block_TopLeft_P'>加工状態</span></th>
                  <th className='block_TopLeft_c'><span className='block_TopLeft_P'>部品状態</span></th>
                  <th className='block_TopLeft_c'><span className='block_TopLeft_P'>色調</span></th>
                  <th className='block_TopLeft_c'></th>
                  <th className='block_TopLeft_c'></th>
                  <th className='block_TopLeft_c'><span className='block_TopLeft_P'></span></th>
                  <th className='block_TopLeft_c'><span className='block_TopLeft_P'>長さ寸法</span></th>
                  <th className='block_TopLeft_c'><span className='block_TopLeft_P'>ブラケット位置</span></th>
                  <th className='block_TopLeft_c'><span className='block_TopLeft_P'>ピース取付位置</span></th>
                  <th className='block_TopLeft_c'><span className='block_TopLeft_P'>水密気密材状態</span></th>
                  <th className='block_TopLeft_c'><span className='block_TopLeft_P'>シール充填状態</span></th>
                  <th className='block_TopLeft_c'><span className='block_TopLeft_P'>部品取付け状態</span></th>
                  <th className='block_TopLeft_c'></th>
                  <th className='block_TopLeft_c'></th>
                </tr>
              </thead>
              <tbody >
                {summary.Detail.map((ditails: reportDetailType) => (
                  <tr className='header_height40 report_font_medium'>
                    <td className='block_TopLeft_l'>{ditails.Madoban}</td >
                    <td className='block_Top_l'>{ditails.Buzaimei}</td >

                    <td className='block_TopLeft_c'>{ditails.Setsu}</td >
                    <td className='block_TopLeft_c'>{ditails.Kanryosu}</td >

                    <td className='block_TopLeft_c'>{ditails.GKns02}</td >
                    <td className='block_TopLeft_c'>{ditails.GKns02}</td >
                    <td className='block_TopLeft_c'>{ditails.KKns05}</td >
                    <td className='block_TopLeft_c'>{ditails.GKns01}</td >
                    <td className='block_TopLeft_c'></td >
                    <td className='block_TopLeft_c'></td >

                    <td className='block_TopLeft_c'></td >
                    <td className='block_TopLeft_c'>{ditails.SKns01}</td >
                    <td className='block_TopLeft_c'>{ditails.KKns05}</td >
                    <td className='block_TopLeft_c'>{ditails.KKns05}</td >

                    <td className='block_TopLeft_c'>{ditails.KKns03}</td >
                    <td className='block_TopLeft_c'>{ditails.GKns06}</td >
                    <td className='block_TopLeft_c'>{ditails.KKns05}</td >
                    <td className='block_TopLeft_c'></td >
                    <td className='block_TopLeft_c'></td >

                    <td className='block_TopRightLeft_c'>{ditails.Hantei}</td >
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr className='header_height40'>
                  <th className='block_TopRightLeft_l report_font_medium' colSpan={20}>備考</th>
                </tr>
                <tr className='header_height40'>
                  <th className='block_TopLeft_l' colSpan={12} rowSpan={3}>【記入方法】<br/>
                  &emsp;&emsp;&emsp;&emsp;&emsp;＊寸法検査欄では実測差異を記入する。<br/>
                  &emsp;&emsp;&emsp;&emsp;&emsp;＊外観、機能検査欄には（レ）を記入する。<br/>
                  &emsp;&emsp;&emsp;&emsp;&emsp;＊不合格の場合は（×）を記入する。<br/>
                  &emsp;&emsp;&emsp;&emsp;&emsp;＊記入欄に該当がない場合は、（／）斜線を記入する。
                  </th>
                  <th className='block_TopLeft_c' rowSpan={2}>表<br/>①</th>
                  <th className='block_TopLeft_c' colSpan={2}>寸法区分</th>
                  <th className='block_TopLeft_c' colSpan={3}>2000 mm未満</th>
                  <th className='block_TopRightLeft_c' colSpan={2}>2000 mm以上</th>
                </tr>
                <tr className='header_height40'>
                  <th className='block_TopLeft_c' colSpan={2}>長さ寸法</th>
                  <th className='block_TopLeft_c' colSpan={3}>± 1.0mm</th>
                  <th className='block_TopRightLeft_c' colSpan={2}>± 1.0mm</th>
                </tr>
                <tr className='header_height40'>
                  <th className='block_TopRight_l' colSpan={8}></th>
                </tr>
                <tr>
                  <th className='block_Top_l' colSpan={20}></th>
                </tr>

              </tfoot>
            </table>
          </div>
        )
        }
        <style type="text/css" media="print">
          {`
            @page { 
              size: A4 ;
              margin-top: 1cm; 
              margin-bottom: 1cm;
              margin-left: 1cm; 
              margin-right: 1cm;
              font-size: 11pt;
            }
             .report_page {
               zoom: 70%;
             }
            .pdf_page {
                size: A4;
                position: relative;
                font-family: MS Gothic;
                page-break-after: always;
              }
            .pdf_page:after{
                position: absolute;
                right: 0;
                bottom: 0;
            }
            table {
              margin: 0px auto 0px;
              width: 100%;
              border-spacing:0;
            }
            thead {
              display: table-header-group;
              break-inside: avoid;
              counter-increment: page_count;
            }
            .pdf_page:last-child{
                page-break-after: auto;
            }

            .report_font_title {
              font-size: 42px;
            }
            .report_font_medium {
              font-size: 18px;
            }
            .report_font_small {
              font-size: 14px;
            }
            .report_font_small10 {
              font-size: 10px;
            }

            .colgroup_col01 {
              width: 60px;
            }
            .colgroup_col02 {
              width: 25px;
            }
            .colgroup_col03 {
              width: 48px;
            }
            .colgroup_col04 {
              width: 76px;
            }
            .header_height20 {
              height: 20px;
            }
            .header_height30 {
              height: 30px;
            }
            .header_height40 {
              height: 40px;
            }
            .header_height80 {
              height: 80px;
            }
            .block_TopLeft_l {
              display: table-cell;
              border-top: 1px solid;
              border-left: 1px solid;
              text-align: left;
            }
            .block_Top_l {
              display: table-cell;
              border-top: 1px solid;
              text-align: left;
            }
            .block_Top_r {
              display: table-cell;
              border-top: 1px solid;
              text-align: right;
            }
            .block_Left_l {
              display: table-cell;
              border-left: 1px solid;
              text-align: left;
            }
            .block_Left_c {
              display: table-cell;
              border-left: 1px solid;
              text-align: center;
            }
            .block_TopLeft_c {
              display: table-cell;
              border-top: 1px solid;
              border-left: 1px solid;
              text-align: center;
            }
            .block_TopLeft_cb {
              display: table-cell;
              border-top: 1px solid;
              border-left: 1px solid;
              text-align: center;
              vertical-align: bottom;
            }
            .block_TopRightLeft_c {
              display: table-cell;
              border-top: 1px solid;
              border-right: 1px solid;
              border-left: 1px solid;
              text-align: center;
            }
            .block_TopRightLeft_l {
              display: table-cell;
              border-top: 1px solid;
              border-right: 1px solid;
              border-left: 1px solid;
              text-align: left;
            }
            .block_TopRight_l {
              display: table-cell;
              border-top: 1px solid;
              border-right: 1px solid;
              text-align: left;
            }
            .block_Right_l {
              display: table-cell;
              border-right: 1px solid;
              text-align: left;
            }
            .block_none_r {
              display: table-cell;
              text-align: right;
            }
            .block_none_r_under {
              display: table-cell;
              text-align: right;
              text-decoration: underline;
            }
            .block_TopLeft_P {
              height: 200px;
              text-align: left;
              padding-top: 3px;
              letter-spacing: 5px;
              display: inline-block;
              writing-mode: vertical-lr;
            }

            .box-content{
              width:-moz-fit-content; /* Firefox */
              width:fit-content; /* other browsers */
            }
          `}
        </style>
      </div >
      );
  }
}
