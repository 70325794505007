import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { readString } from 'react-papaparse';
import { rowInterface } from '../../pages/monoss/P01Mst101Hanbai001';

const style = {
  border: '1px dotted #888'
};

type Props = {
    setPost: any;
    disabled?: boolean;
}

const Dropzone = (
    props :Props,
) => {

  const HandleFileRead = (binaryStr: any) => {
    readString(binaryStr, {
      worker: true,
      complete: (results: any) => {
        let rowList:rowInterface[] = []
        for (let i=1; i<results.data.length; i++) {
          // 空行チェック
          if (results.data[i].length === 1){
            continue
          }
          const newRow = { id: i,
            status : 'NEW',
            gkhonkbn : results.data[i][0] ? results.data[i][0] : '',
            gkzkojst : results.data[i][1] ? results.data[i][1] : '',
            gkzkojstmei : '',
            gkgenka : results.data[i][3] ? results.data[i][3] : '',
            gcgcents : results.data[i][4] ? results.data[i][4] : '',
            gcgcentsmei : '',
            gzbiko : results.data[i][6] ? results.data[i][6] : '',
            insdate : '',
            insuser : '',
            upddate : '',
            upduser : '',
            errmessage : ''
          };
          rowList = [...rowList, newRow];
          // console.debug('newRow:', newRow)
        }
        // console.debug('newList_by_file:', rowList);
        props.setPost(rowList);
      }
    });
  }  
  
  const onDrop = useCallback((files: File[]) => {
    props.setPost([])
    files.forEach((file: any) => {
        console.debug('files:', file);
        const reader = new FileReader();
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          const binaryStr: any = reader.result;
          // console.debug('binaryStr',binaryStr);
          HandleFileRead(binaryStr);
        }
        reader.readAsText(file);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} style={style}>
      <input disabled={props.disabled} {...getInputProps()} />
      {
        isDragActive ?
          <p>ここにファイルをロップしてください　</p> :
          <p>ファイルをドロップまたはクリック選択　</p>
      }
    </div>
  );
}

export default Dropzone;