import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import GenericTemplate from '../../templates/GenericTemplate';
import requests from '../../utils/aqua/Request';
import CommonDialog from '../aqua/P01Put001Konpo004';
import {Auth} from 'aws-amplify';
import axios from 'axios';
import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';

interface infoInterface {
  sijibi: string;
  tmstmp: string;
  kjcode: string;
  dataList: rowInterface[];
}
interface rowInterface {
  id: number;
  kojoname: string;
  linecode: string;
  linename: string;
  day1: string;
  day2: string;
  day3: string;
  day4: string;
  day5: string;
  day6: string;
  day7: string;
  day8: string;
  day9: string;
  daySum: string;
}
const columns: GridColDef[] = [
  { field: 'kojoname', headerName: '工場名', width: 120, },
  { field: 'linecode', headerName: '投入ライン', width: 120, },
  { field: 'linename', headerName: 'ライン名', width: 300, },
  { field: 'day1', headerName: '１日', width: 100, align: 'right', },
  { field: 'day2', headerName: '２日', width: 100, align: 'right', },
  { field: 'day3', headerName: '３日', width: 100, align: 'right', },
  { field: 'day4', headerName: '４日', width: 100, align: 'right', },
  { field: 'day5', headerName: '５日', width: 100, align: 'right', },
  { field: 'day6', headerName: '６日', width: 100, align: 'right', },
  { field: 'day7', headerName: '７日', width: 100, align: 'right', },
  { field: 'day8', headerName: '８日', width: 100, align: 'right', },
  { field: 'day9', headerName: '９日以上', width: 100, align: 'right', },
  { field: 'daySum', headerName: '計', width: 100, align: 'right', },
];

const P01Put001Konpo003: React.FC = () => {
  // *************
  // 変数定義
  // *************
  //検索結果を管理するuseState
  const [post, setPosts] = useState<infoInterface[]>([])
  const [sijibi, setSijibi] = useState('');   
  const [tmstmp, setTmstmp] = useState('');   
  const [kjcode, setKjcode] = useState(''); 
  const [totalCnt, setTotalCnt] = useState(0); 
  const [dataList, setDataList] = useState<rowInterface[]>([])
  // ダイアログ用のstate
  const [digOpen, setDigOpen] = useState(false);
  const [lineCode, setLineCode] = useState('');
  
  const [loading, setLoading] = useState(false);

  // *************
  // 定数定義
  // *************
  // api 用のパラメータを設定する
  const apiKey = String(process.env.REACT_APP_APIKEY_AP00447)
  // APIのget 時のパラメータを指定
  const apiGetUrl = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Put001Delay)

  // 画面初期表示時
  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      // console.debug(apiGetUrl)
      //requestを投げるURL作成
      const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
      axios.get(apiGetUrl,
      {
        headers: {
          "X-API-KEY": apiKey,
          "Authorization": AuthToken || "",
        },
      }).then((res) => {
        // 成功したら result を格納。
        setPosts(res.data.result)
        console.log(res.data.result)
      }).catch(error => {
        console.log(error);
        alert('エラーが発生しました。再度検索して下さい。');
      }).finally(() => {
        setLoading(false);
      });
    }
    fetch()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (post.length > 0) {
      setSijibi(post[0].sijibi)
      setTmstmp(post[0].tmstmp)
      setKjcode(post[0].kjcode)
      if (post[0].dataList === undefined || post[0].dataList.length === 0){
        setDataList([])
        setTotalCnt(0)
      } else {
        setDataList(post[0].dataList.map((dataRow: rowInterface, index: number) => {
          return {...dataRow, 
            id: index + 1,
          };
        }))
        setTotalCnt(post[0].dataList.reduce(function(sum, element){
          return sum + Number(element.daySum === undefined ? 0 : element.daySum);
        }, 0))
      }
    }
  }, [post]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport               
          csvOptions={{
          utf8WithBom: true,
        }}
      />
      </GridToolbarContainer>
    )
  }

  return (
    <GenericTemplate title="ＫＤ遅延件数" >
      <Stack spacing={1} direction="column" >
        <Stack spacing={2} direction="row"></Stack>
        <Stack spacing={2} direction="row">
          <div>{sijibi}　単位：注文番号件数　最終更新：{tmstmp}　{kjcode}</div>
          <div style={{ flexGrow: 1 }}></div>
          <div>総計：{totalCnt}　</div>
        </Stack>
        {/* DataGrid */}
        <div style={{ height: '100%', width: '100%', }}>
          <Box sx={{ height: 600, width: '100%' }}>
            <DataGrid
              density="compact"
              rows={dataList} 
              columns={columns}
              rowsPerPageOptions={[20, 50, 100]}
              components={{
                Toolbar: CustomToolbar
              }}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              onRowClick={(selRow) => {
                // console.log(selRow)
                setLineCode(selRow.row.linecode)
                setDigOpen(true)                
              }}
              showCellRightBorder
            />
            {loading && (
              <CircularProgress
                  size={50}
                  sx={{
                      color: green[500],
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                  }}
              />
            )}
          </Box>
        </div>
      </Stack>
      <CommonDialog
          open={digOpen}
          rowParam={lineCode}
          onClose={() => setDigOpen(false)}
      />
  </GenericTemplate>
  )
}

export default P01Put001Konpo003