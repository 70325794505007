// /src/pages/TopPage.tsx
import React, { useContext, useState } from 'react';
import { userAttributes } from '../App';
import Stack from '@mui/material/Stack';
import { FormControlLabel, Switch } from '@mui/material';

import GenericTemplate from '../templates/GenericTemplate';
import ButtonFileDownload from '../components/ButtonFileDownload';
import InformationDataGrid from '../components/DataGrid/InformationDataGrid';
import ButtonAction from '../components/Button/ButtonAction';

// AppContext が保持する値の型
export interface AppContextType {
  isCheckedPeriod: boolean;
  digOpen: boolean;
  setDigOpen: (setDigOpen: boolean) => void;
  loading: boolean;
  setLoading: (setLoading: boolean) => void;
}

// AppContext の生成
export const AppContext = React.createContext<AppContextType>({
  isCheckedPeriod: false,  // デフォルト値
  digOpen: false,  // デフォルト値
  setDigOpen: (setDigOpen: boolean) => {},  // ダミー関数
  loading: false,  // デフォルト値
  setLoading: (setLoading: boolean) => {},  // ダミー関数
});

const TopPage: React.FC = () => {
  //全件表示・チェッカーのuseState
  const [isCheckedPeriod, setChecked] = React.useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  // ダイアログ用のstate
  const [digOpen, setDigOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // 更新権限をチェックする(stinqAdminFlag=1の場合、更新可能)
  const userAtt = useContext(userAttributes)
  const stinqAdminFlag = (userAtt as any).stinqAdminFlag === '1' ? true : false

  // 下位コンポーネントへ渡す Context
  const newContext: AppContextType = {
    isCheckedPeriod, digOpen, setDigOpen, loading, setLoading
  };

  return (
    <GenericTemplate title=""> 
      <AppContext.Provider value={newContext}>
      <Stack spacing={2} direction="column">
        {process.env.REACT_APP_FEACHERFLAG_202301R081_01 === "true" &&
        <div>
          <Stack spacing={2} direction="row">
            {/* 追加・ボタン */}
            {stinqAdminFlag && 
              <ButtonAction
                name="追加"
                disabled={loading}
                onAction={() => setDigOpen(true)}
              />}
            <FormControlLabel 
              control={<Switch 
                checked={isCheckedPeriod}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={loading}
              />
              } label="全期間表示" />                
          </Stack>
          <InformationDataGrid />
        </div>
        }
        <Stack spacing={2} direction="column">
          <div>
            <ButtonFileDownload name="申請手順" url="/pdf/common/ShinseiManual.pdf" filename="ShinseiManual.pdf" />
            このサイトの利用申請手順をダウンロードします
          </div>
          <div>
            <ButtonFileDownload name="操作マニュアル" url="/pdf/common/OperationManual.pdf" filename="OperationManual.pdf" />
            このサイトの操作マニュアルをダウンロードします
          </div>
        </Stack>
      </Stack>
      </AppContext.Provider>
    </GenericTemplate>
  );
};

export default TopPage;