import React, { useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import axios from 'axios';
import requests from '../../utils/config/Request';
import { Auth } from 'aws-amplify';

// ----------------------------------------------------
// MaterialUiのSelect コンポーネント UserServicesの一覧を表示する
// ----------------------------------------------------
const SelectUserServices = (
    {
        helperText = "",
        selected="",
        //呼出元にSelect で選択した値を返すためのイベントを定義する
        onChange = (event: any) => { onChange(event); }
    }
) => {
    //変数定義
    const [UserServices, setUserServices] = React.useState(selected);
    const [UserServicesList, setUserServicesList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    //Select のMenuItem に追加するトラン区分の一覧を作成する関数
    const getUserServicesList = UserServicesList.map((cat: any) => (
        <MenuItem key={cat.userServices} value={cat.userServices}>
            {cat.userServices} 
        </MenuItem>
    ))

    //Select変更時のイベント
    const handleChange = (event: SelectChangeEvent) => {
        setUserServices(event.target.value);
        onChange(event);
    };

    //WEBApiをコールして、UserServicesの一覧を取得する
    //初回だけ実行したいので第二引数は[]を追加
    useEffect(() => {
        setLoading(true);
        const fetch = async () => {
            //requestを投げるURL作成return data[key];
            const URLcell = process.env.REACT_APP_APIURL_CONFIG + requests.p01Mst001UserServices;
            const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
            axios.get(URLcell,
                {
                    headers: {
                        "X-API-KEY": process.env.REACT_APP_APIKEY_CONFIG || "",
                        "Authorization": AuthToken || "",
                    },
                }).then((res) => {
                    // console.debug(res.data.result)
                    setUserServicesList(res.data.result)
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    setLoading(false);
                })
        }
        fetch()
    }, [])

    // 作成したselect をリターンする
    return (
        <FormControl sx={{ minWidth: 180 }} size="small" error={!!helperText}>
            <InputLabel id="select-userServices">ユーザサービス</InputLabel>
            <Select 
                labelId="select-userServices"
                id="select-userServices"
                value={UserServices}
                label="UserServices"
                onChange={handleChange}
                disabled={loading}
            >
                <MenuItem value=""><em>None</em></MenuItem>
                {getUserServicesList}

            </Select>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
}

export default SelectUserServices