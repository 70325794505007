import { useEffect, useState,createContext} from 'react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { CognitoUser } from "@aws-amplify/auth";
import CustomRouter from './components/CustomRouter';
import {
  AuthState,
  UI_AUTH_CHANNEL,
  AUTH_STATE_CHANGE_EVENT,
  AuthStateHandler,
} from '@aws-amplify/ui-components';
export const userAttributes = createContext({})

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_AUTH_DOMAIN,
      scope: ['openid','aws.cognito.signin.user.admin'],
      redirectSignIn:  process.env.REACT_APP_AUTH_REDIRECTSIGNIN,
      redirectSignOut: process.env.REACT_APP_AUTH_REDIRECTSIGNOUT,
      clientId: process.env.REACT_APP_AUTH_CLIENTID,
      responseType: process.env.REACT_APP_AUTH_RESPONSETYPE
    }
  }
})

const App = () => {
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [authInProgress, setAuthInProgress] = useState(false);
  const getCurrentUserInfo = async () => {
    const currentUserInfo = await Auth.currentUserInfo();
    return currentUserInfo;
  }
  const [userServices, setUserServices] = useState("");
  const [UserNameJa, setUserNameJa] = useState("");
  const [CompanyNameJa, setCompanyNameJa] = useState("");

  const [stinqAdminFlag, setStinqAdminFlag] = useState("0");
  const [stinqRoles, setStinqRoles] = useState([]);

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          setUser(data);
          setAuthInProgress(false);
          break;
        case 'signOut':
          setUser(null);
          setAuthInProgress(false);
          if (process.env.REACT_APP_SIGNOUT_URL === undefined) {
            return
          }
          window.location.href = process.env.REACT_APP_SIGNOUT_URL
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          break;
        case 'oAuthSignOut':
          setUser(null);
          setAuthInProgress(false);
          Hub.dispatch(UI_AUTH_CHANNEL, {
            event: AUTH_STATE_CHANGE_EVENT,
            message: AuthState.SignedOut,
            data,
          });
          if (process.env.REACT_APP_SIGNOUT_URL === undefined) {
            return
          }
          window.location.href = process.env.REACT_APP_SIGNOUT_URL
          break;  
        default:
      }
    });

    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
        Auth.userAttributes(user).then((promise) =>{
          promise.map((result) =>{
           // console.log("auth:"+ result.Name + "-" + result.Value)
            if(result.Name === "custom:userServices"){
              setUserServices(result.Value)
            }
            if(result.Name === "custom:username_ja"){
              setUserNameJa(result.Value)
            }
            if(result.Name === "custom:companyName_ja"){
              setCompanyNameJa(result.Value)
            }
          })
        })

        if(user["signInUserSession"]["idToken"]["payload"]["stinqAdminFlag"]){
           setStinqAdminFlag(user["signInUserSession"]["idToken"]["payload"]["stinqAdminFlag"])
        }
        if(user["signInUserSession"]["idToken"]["payload"]["stinqRoles"]){
          setStinqRoles(JSON.parse(user["signInUserSession"]["idToken"]["payload"]["stinqRoles"]))
       }



      })
      .catch(() => {
        if (process.env.REACT_APP_SIGNIN_URL === undefined) {
          return
        }
        window.location.href = process.env.REACT_APP_SIGNIN_URL
        setAuthInProgress(true);
      });
    return unsubscribe;
  }, []);

  return user ? (
    <div>
      <userAttributes.Provider value={{"userServices":userServices, "companyNameJa":CompanyNameJa, "userNameJa":UserNameJa,"stinqAdminFlag":stinqAdminFlag,"stinqRoles":stinqRoles}}>
        <CustomRouter />
      </userAttributes.Provider>
    </div>
  ) : (
    <div>
      <p>
        ログイン画面に遷移します。
      </p>
    </div>
  );
}

export default App