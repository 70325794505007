import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';

const FromDesktopDatePicker = ({
  value = new Date(),
  onChange = (event: any) => { onChange(event); }
}) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DesktopDatePicker
      label="投入日(終点)"
      inputFormat="yyyy-MM-dd"
      value={value}
      mask=""
      onChange={v => {
        onChange(v);
      }}
      renderInput={(params) => <TextField {...params} size="small" />}
    />
  </LocalizationProvider>
);

export default FromDesktopDatePicker;