import TextField from '@mui/material/TextField';

const FromTextField = ({
  helperText = "",
  onChange = (event: any) => { onChange(event); }
}) => (
  <TextField id="tfHinmokuNo"
    label="品目番号" 
    size="small"
    inputProps={{ maxLength: 14 }}
    onChange={(event: any) => { onChange(event); }}
    error={!!helperText}
    helperText={helperText}
  />
);

export default FromTextField;