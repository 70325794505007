import { useEffect, useState, useContext } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Stack from '@mui/material/Stack';
import requests from '../../utils/config/Request';
import ButtonInsert from '../../components/Button/ButtonFuncInsert';
import ButtonUpdate from '../../components/Button/ButtonFuncUpdate';
import ButtonDelete from '../../components/Button/ButtonFuncDelete';
import ButtonCancel from '../../components/Button/ButtonAction';
import { userAttributes } from '../../App';
import DesktopDatePickerCommon from '../../components/DesktopDatePicker/DesktopDatePickerCommonErrDisp';
import CheckBoxAllUserFlg from '../../components/Checkbox/CheckBoxOne';
import CheckBoxDisplayFlg from '../../components/Checkbox/CheckBoxOne';
import SelectInfoType from '../../components/Select/SelectInfoType';
import SelectUserServices from '../../components/Select/SelectUserServices';
import FromTextFieldTitle from '../../components/TextField/TextFieldTitle';
import FromTextFieldMessage from '../../components/TextField/TextFieldMessage';
import { chkDateNull } from '../../common/chkTextValue';

function P01Mst001Permission003(props: any) {
  // *************
  // プロパティの受け取り
  // *************
  const {
    onInsert,
    onUpdate,
    onDelete,
    onClose,
    open,
    rowParam,
  } = props;

  // *************
  // 変数定義
  // *************
  const [dialogOpen, setDialogOpen] = useState(false);
  //コード
  const [code, setCode] = useState(''); 
  //タイプ
  const [infoType, setInfoType] = useState(''); 
  //タイトル
  const [infoTitle, setInfoTitle] = useState(''); 
  //メッセージ
  const [infoMessage, setInfoMessage] = useState(''); 
  //全ユーザ表示チェックボックス
  const [isCheckedAllUser, setIsCheckedAllUser] = useState(true);
  //ユーザサービス
  const [userServices, setUserServices] = useState(''); 
  //表示フラグチェックボックス
  const [isCheckedDisplay, setIsCheckedDisplay] = useState(true);
  //表示開始日
  const [startDate, setStartDate] = useState<Date | undefined>(new Date());
  const [startDateError, setStartDateError] = useState('');
  //表示終了日(表示開始日の１か月後を初期値とする)
  const [endDate, setEndDate] = useState<Date | undefined>(new Date());
  const [endDateError, setEndDateError] = useState('');
  const [updateAt, setUpdateAt] = useState();
  const [updateUser_ja, setUpdateUser_ja] = useState('');
  //添付ファイル
  // TODO

  // 更新権限をチェックする(stinqAdminFlag=1の場合、更新可能)
  const userAtt = useContext(userAttributes)
  const stinqAdminFlag = (userAtt as any).stinqAdminFlag === '1' ? true : false

  // *************
  // 定数定義
  // *************
  // api 用のパラメータを設定する
  const apiKey = String(process.env.REACT_APP_APIKEY_CONFIG)
  // APIのpost 時のパラメータを指定
  const apiPostUrl = String(process.env.REACT_APP_APIURL_CONFIG + requests.p01Mst003Information)
  const apiPostParam = {
      "Title": infoTitle,
      "InfoType": infoType, 
      "Message": infoMessage,
      "AllUserFlag": isCheckedAllUser ? '1' : '0',
      "TargetUserServices": userServices,
      "VisibleFlag": isCheckedDisplay ? '1' : '0',
      "StartDate": startDate === null ? '' : startDate?.toLocaleDateString('sv-SE'), 
      "EndDate": endDate === null ? '' : endDate?.toLocaleDateString('sv-SE'),
    };
  // APIのput 時のパラメータを指定
  const apiPutUrl = String(process.env.REACT_APP_APIURL_CONFIG + requests.p01Mst003Information + '/' + code)
  const apiPutParam = {
      "Code": code,
      "Title": infoTitle,
      "InfoType": infoType, 
      "Message": infoMessage,
      "AllUserFlag": isCheckedAllUser ? '1' : '0',
      "TargetUserServices": userServices,
      "VisibleFlag": isCheckedDisplay ? '1' : '0',
      "StartDate": startDate === null ? '' : startDate?.toLocaleDateString('sv-SE'), 
      "EndDate": endDate === null ? '' : endDate?.toLocaleDateString('sv-SE'),
    };
  // APIのdelete 時のパラメータを指定
  const apidDeleteUrl = String(process.env.REACT_APP_APIURL_CONFIG + requests.p01Mst003Information + '/' + code)

  // ダイアログクローズ
  const handleClose = () => {
    setDialogOpen(false);
    onClose();
  };
  // openの値が変化した時
  useEffect(() => {
    
    if (rowParam == null) {
      const dt1 = new Date();
      dt1.setMonth(dt1.getMonth() + 1);
    
      setCode('')
      setInfoType('I')
      setInfoTitle('')
      setInfoMessage('')
      setIsCheckedAllUser(true)
      setUserServices('')
      setIsCheckedDisplay(true)
      setStartDate(new Date())
      setEndDate(dt1)
    } else {
      const pParams = rowParam != null ? JSON.parse(rowParam) : ''

      setCode(pParams.Code)
      setInfoType(pParams.InfoType)
      setInfoTitle(pParams.Title)
      setInfoMessage(pParams.Message)
      setIsCheckedAllUser(pParams.AllUserFlag === '1' ? true : false)
      setUserServices(pParams.TargetUserServices)
      setIsCheckedDisplay(pParams.VisibleFlag === '1' ? true : false)
      setStartDate(new Date(pParams.StartDate))
      setEndDate(new Date(pParams.EndDate))
      setUpdateAt(pParams.UpdateAt)
      setUpdateUser_ja(pParams.UpdateUser_ja)
    }
    setStartDateError('')
    setEndDateError('')
      
    setDialogOpen(open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      open={dialogOpen}
      maxWidth="md">
      <DialogTitle>
        <span>インフォメーション</span>
      </DialogTitle>
      <DialogContent >
        <Box >
        <Stack spacing={2} direction="column" >
          <div></div>
          <Stack spacing={2} direction="row">
            <FromTextFieldTitle
              onChange={(event) => setInfoTitle(event.target.value)}
              value={infoTitle}
              readonly={!stinqAdminFlag}
            />
            <SelectInfoType 
              onChange={(event) => setInfoType(event.target.value)} 
              selected={infoType}
              readonly={!stinqAdminFlag}
            />
          </Stack>
          <Stack spacing={2} direction="row">
            <FromTextFieldMessage
              onChange={(event) => setInfoMessage(event.target.value)}
              value={infoMessage}
              readonly={!stinqAdminFlag}
            />
          </Stack>
          {stinqAdminFlag && 
          <Stack spacing={2} direction="row">
            <CheckBoxAllUserFlg 
              label="全ユーザ表示" 
              value={isCheckedAllUser}
              onChange={() => setIsCheckedAllUser(prevState => !prevState)}
            />
            <SelectUserServices 
              onChange={(event) => setUserServices(event.target.value)}
              selected={userServices}
            />
          </Stack>}
          <Stack spacing={2} direction="row">
            {stinqAdminFlag && 
            <CheckBoxDisplayFlg 
              label="表示フラグ" 
              value={isCheckedDisplay}
              onChange={() => setIsCheckedDisplay(prevState => !prevState)}
            />}
            <DesktopDatePickerCommon
              label="表示開始日"
              value={startDate}
              helperText={startDateError}
              readonly={!stinqAdminFlag}
              onChange={(newDate: any) => { setStartDateError(chkDateNull(newDate)); 
                                            setStartDate(newDate); }}
              />
            <div>
              <p style={{ textAlign: "center" }}> ～ </p>
            </div>
            <DesktopDatePickerCommon
              label="表示終了日"
              value={endDate}
              helperText={endDateError}
              readonly={!stinqAdminFlag}
              onChange={(newDate: any) => { setEndDateError(chkDateNull(newDate)); 
                                            setEndDate(newDate); }}
            />
          </Stack>
          {rowParam != null && <Stack spacing={2} direction="row">
            <div>更新日：{updateAt}</div>
            <div>更新者：{updateUser_ja}</div>
          </Stack>}
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        {stinqAdminFlag && <Stack spacing={2} direction="row" > 
          {rowParam == null && <Stack direction="row" >
            <ButtonInsert
              apiParam={{ ...apiPostParam }}
              apiUrl={apiPostUrl}
              apiKey={apiKey}
              disabled={!stinqAdminFlag}
              result={onInsert}
              message="入力された情報を追加します"
              name="追加"
            />
          </Stack>}
          {rowParam != null && <Stack direction="row" >
            <ButtonUpdate
              apiParam={{ ...apiPutParam }}
              apiUrl={apiPutUrl}
              apiKey={apiKey}
              disabled={!stinqAdminFlag}
              result={onUpdate}
              message="変更された情報を更新します"
              name="更新"
              />
            <ButtonDelete
              apiUrl={apidDeleteUrl}
              apiKey={apiKey}
              disabled={!stinqAdminFlag}
              result={onDelete}
              message="表示された情報を削除しますか？"
              name="削除"
              />
          </Stack>}
        </Stack>}
        <ButtonCancel
            onAction={handleClose}
            name="閉じる"
            />

      </DialogActions>
    </Dialog>
  );
}

export default P01Mst001Permission003;