import React, { ChangeEvent } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, } from '@mui/material';

// ----------------------------------------------------
// MaterialUiの Checkbox コンポーネント 
// ----------------------------------------------------
const CheckBoxOne = ({
    label="",
    value=false,
    onChange = (event: any) => { onChange(event); }
}) => {   
    //初期チェックなし
    const [isChecked, setIsChecked] = React.useState(value);

    //Checkbox変更時のイベント
    const handleChange = (event: ChangeEvent) => {
        setIsChecked(prevState => !prevState);
        onChange(event);
    };

    //チェックボックスが１つ　後ろにラベル表示　
    return (
        <FormControlLabel control={
            <Checkbox 
                checked={isChecked}
                onChange={handleChange}
            />} 
            label={label}
          />
    );
};

export default CheckBoxOne;

