import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import GenericTemplate from '../../templates/GenericTemplate';
import requests from '../../utils/aqua/Request';
import { userAttributes } from '../../App';
import DesktopDatePickerCommon from '../../components/DesktopDatePicker/DesktopDatePickerCommon';
import FromTextFieldBukkenNo from '../../components/TextField/TextBukkenNo';
import FromTextFieldSyusekiNo from '../../components/TextField/TextFieldSyusekiNo';
import ButtonSearch from '../../components/Button/ButtonSearch';
import ButtonUpdate from '../../components/Button/ButtonUpdate';
import ButtonPrint from '../../components/Button/ButtonPrint';
import ReportBa01R01 from '../../components/Report/aqua/ReportBa01R01';

interface rowInterface {
  id: string;
  rownum: number;
  BukkenNo: string;
  Kojimei: string;
  SyusekiNo: string;
  Iro: string;
  KumiKahan: string;
  Tonyubi: string;
  Madoban: string;
  KumitateUnit: string;
  Setsu: string;
  Kanryosu: string;
  CheckedDate: string;
  QualityChecker_ja: string;
  FirstAuthorizedDate: string;
  FirstAuthorizer_ja: string;
  SecondAuthorizedDate: string;
  SecondAuthorizer_ja: string;
  ThirdAuthorizedDate: string;
  ThirdAuthorizer_ja: string;
  Kamikotei: string;
  GKns01: string; GKns02: string; GKns03: string; GKns04: string; GKns05: string; GKns06: string; GKns07: string; GKns08: string; GKns09: string; GKns10: string;
  KKns01: string; KKns02: string; KKns03: string; KKns04: string; KKns05: string; KKns06: string; KKns07: string;
  SKns01: string; SKns02: string; SKns03: string; SKns04: string; SKns05: string; SKns06: string; SKns07: string; SKns08: string; SKns09: string; SKns10: string;
  Yojo: string; Hantei: string; Biko: string | null;
}

interface authResultInterface {
  authorizeType: string;
  statusCode: number;
  totalCount: number;
  success: {
    count: number;
    records?: {
      id: string;
      rownum: number;
      authorizedDate: string;
      authorizer_ja: string;
    }[];
  }
  fail: {
    count: number;
    records?: {
      id: string;
      rownum: number;
      authorizedDate: string;
      authorizer_ja: string;
    }[];
  }
}

const columns: GridColDef[] = [
  { field: 'BukkenNo', headerName: '物件番号', width: 150, },
  { field: 'Kojimei', headerName: '工事名', width: 200, },
  { field: 'SyusekiNo', headerName: '集積番号', width: 100, },
  { field: 'Madoban', headerName: '窓番', width: 100, },
  { field: 'KumitateUnit', headerName: '組立ユニット', width: 100, },
  { field: 'KumiKahan', headerName: '課班', width: 60, },
  { field: 'Setsu', headerName: '数量', width: 60, },
  { field: 'Kanryosu', headerName: '完了数', width: 60, },
  { field: 'CheckedDate', headerName: '検査完了日', width: 120, },
  { field: 'QualityChecker_ja', headerName: '検査員', width: 110, },
  { field: 'FirstAuthorizedDate', headerName: '第一承認日', width: 120, },
  { field: 'FirstAuthorizer_ja', headerName: '第一承認者', width: 110, },
  { field: 'SecondAuthorizedDate', headerName: '第二承認日', width: 120, },
  { field: 'SecondAuthorizer_ja', headerName: '第二承認者', width: 110, },
  { field: 'ThirdAuthorizedDate', headerName: '品質承認日', width: 120, },
  { field: 'ThirdAuthorizer_ja', headerName: '品質承認者', width: 110, },
  { field: 'Kamikotei', headerName: '上工程品質確認', width: 110, },
  { field: 'SKns01', headerName: '幅上（枠の内のり）', width: 110, },
  { field: 'SKns02', headerName: '幅下（枠の内のり）', width: 110, },
  { field: 'SKns03', headerName: '高さ左（枠の内のり）', width: 110, },
  { field: 'SKns04', headerName: '高さ右（枠の内のり）', width: 110, },
  { field: 'SKns05', headerName: '寸法の差（枠対辺内のり）', width: 110, },
  { field: 'SKns06', headerName: '枠見込み', width: 110, },
  { field: 'SKns07', headerName: '取付位置（無目・中骨）', width: 110, },
  { field: 'SKns08', headerName: '隙間寸法幅（障子と枠）', width: 110, },
  { field: 'SKns09', headerName: '隙間寸法高さ（障子と枠）', width: 110, },
  { field: 'SKns10', headerName: '取付位置（施錠部品）', width: 110, },
  { field: 'GKns01', headerName: '色調', width: 110, },
  { field: 'GKns02', headerName: 'カエリ（キズ）', width: 110, },
  { field: 'GKns03', headerName: '隙間（目違い）', width: 110, },
  { field: 'GKns04', headerName: '取付状態（補助補強材）', width: 110, },
  { field: 'GKns05', headerName: 'かみ合せ（シーラー）', width: 110, },
  { field: 'GKns06', headerName: '状態（シーリング）', width: 110, },
  { field: 'GKns07', headerName: '裏打ち状態', width: 110, },
  { field: 'GKns08', headerName: '防火戸証紙', width: 110, },
  { field: 'GKns09', headerName: 'BL証紙', width: 110, },
  { field: 'GKns10', headerName: 'その他ラベル', width: 110, },
  { field: 'KKns01', headerName: '施錠開閉状態', width: 110, },
  { field: 'KKns02', headerName: '勝手確認', width: 110, },
  { field: 'KKns03', headerName: 'タイト状態', width: 110, },
  { field: 'KKns04', headerName: 'かかりしろ（建具状態）', width: 110, },
  { field: 'KKns05', headerName: '機能状態（金具・部品）', width: 110, },
  { field: 'KKns06', headerName: '確認（水抜状態）', width: 110, },
  { field: 'KKns07', headerName: '角度・斜辺（曲げ）', width: 110, },
  { field: 'Yojo', headerName: '養生', width: 110, },
  { field: 'Hantei', headerName: '判定', width: 110, },
  { field: 'Biko', headerName: '処置', width: 110, },
];

const P01Ins001Ase001: React.FC = () => {
  // *************
  // 変数定義
  // *************
  //検索結果を管理するuseState
  const [post, setPosts] = useState<rowInterface[]>([])
  //検索結果よりdatagridに表示しているデータを管理するuseState
  const [dataGridData, setDataGridData] = useState<rowInterface[]>([])
  //MUIDatagridのフィルタを管理するuseState
  const [selectionModel, setSelectionModel] = React.useState<rowInterface[]>([]);
  //更新結果を管理するuseState
  const [authResult, setAuthResult] = useState<authResultInterface>(
    {
      authorizeType: "",
      statusCode: 0,
      totalCount: 0,
      success: { count: 0 },
      fail: { count: 0 }
    }
  )
  //datagridのチェックボックスを管理するuseState
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  //datagridのチェックボックスのチェックしたかどうか管理するuseState
  const [selectCheck, setSelectCheck] = React.useState(false);
  // 更新権限をチェックする(Sagyoshien1stApprover, Sagyoshien2ndApprover, SagyoshienQaApprover ロールが付与されていれば、更新可能)
  const userAtt = useContext(userAttributes)
  let regexp1 = /Sagyoshien1stApprover/
  const Sagyoshien1stAuthorizer = regexp1.test((userAtt as any).stinqRoles["STINQAQUA"])
  let regexp2 = /Sagyoshien2ndApprover/
  const Sagyoshien2ndAuthorizer = regexp2.test((userAtt as any).stinqRoles["STINQAQUA"])
  let regexp3 = /SagyoshienQaApprover/
  const SagyoshienQaAuthorizer = regexp3.test((userAtt as any).stinqRoles["STINQAQUA"])
  // 検索条件をセットする変数
  const [CheckedDate, setCheckedDate] = useState<Date | undefined>(new Date()); //検査完了日
  const [BukkenNo, setBukkenNo] = useState(""); //物件番号
  const [SyusekiNo, setSyusekiNo] = useState(""); //集積番号

  // *************
  // 定数定義
  // *************
  //承認対象のみ表示・チェッカーのuseState
  const [checked2, setChecked2] = React.useState(true);
  const handleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked2(event.target.checked);
  };

  // api 用のパラメータを設定する
  const apiKey = String(process.env.REACT_APP_APIKEY_AP00447)
  // APIのget 時のパラメータを指定
  const apiGetUrl = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Ins001Data) + moment(CheckedDate).format("YYYY-MM-DD")
  const apiGetParam = {
    BukkenNo: BukkenNo,
    SyusekiNo: SyusekiNo,
    OnlyApprovable: ((Sagyoshien1stAuthorizer || Sagyoshien2ndAuthorizer) && checked2) ? "1": "0",
    OnlyQaable: (SagyoshienQaAuthorizer) ? "1": "0",
  };
  const apiPutUrl3 = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Ins002Data)

  // APIのput 時のパラメータを指定
  const apiPutUrl1 = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Ins001Auth)
  const apiPutUrl2 = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Ins001unAuth)
  const apiPutParam = {
    requests: selectionModel.map((dataGridRow) => {
      return {
        "BukkenNo": dataGridRow.BukkenNo,
        "SyusekiNo": dataGridRow.SyusekiNo,
        "Madoban": dataGridRow.Madoban,
        "KumitateUnit": dataGridRow.KumitateUnit,
      }
    })
  };

  const [filter, setFilter] = React.useState<any>([
    //[{ columnField: '', operatorValue: '', value: '' }]
  ]);
  //表示対象・セレクトのuseState
  const [selected, setSelected] = React.useState("1");
  const selectChange = (SelectChangeEvent: any) => {
    setSelected(SelectChangeEvent.target.value);
    if (SelectChangeEvent.target.value === 1) {
      setFilter([])
    } else if (SelectChangeEvent.target.value === 2) {
      setFilter([{ columnField: 'FirstAuthorizedDate', operatorValue: 'isEmpty' }])
    } else if (SelectChangeEvent.target.value === 3) {
      setFilter([{ columnField: 'FirstAuthorizedDate', operatorValue: 'isNotEmpty' }])
    } else if (SelectChangeEvent.target.value === 4) {
      setFilter([{ columnField: 'SecondAuthorizedDate', operatorValue: 'isEmpty' }])
    } else {
      setFilter([{ columnField: 'SecondAuthorizedDate', operatorValue: 'isNotEmpty' }])
    }
  };

  //datagridのfilter機能をセットする
  const handleFilterModelChange = (newFilterModel: any) => {
    setFilter(newFilterModel.items)
  };

  //filterの変数が変わった際に表示対象も変化させる
  useEffect(() => {
    if (filter[0] !== undefined) {
      if (filter[0].columnField === 'FirstAuthorizedDate' && filter[0].operatorValue === 'isEmpty') {
        setSelected('2')
      }
      else if (filter[0].columnField === 'FirstAuthorizedDate' && filter[0].operatorValue === 'isNotEmpty') {
        setSelected('3')
      }
      else if (filter[0].columnField === 'SecondAuthorizedDate' && filter[0].operatorValue === 'isEmpty') {
        setSelected('4')
      }
      else if (filter[0].columnField === 'SecondAuthorizedDate' && filter[0].operatorValue === 'isNotEmpty') {
        setSelected('5')
      }
      else {
        setSelected('1')
      }
  }
  }, [filter]);

  //検査項目表示・チェッカーのuseState
  const [checked, setChecked] = React.useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
      </GridToolbarContainer>
    )
  }
  // 帳票のタイトルを変数セット
  const DocTitle = moment(CheckedDate).format("YYYY-MM-DD") + '_組立作業指示書(報告書)';
  const componentRef = useRef(null);

  // authResult Stateが更新された際にDOMの書き換え処理を実行する
  useEffect(() => {
    //datagridのチェックボックス用のuseStateを初期化
    setSelectedIds(['']);
    //console.log(JSON.stringify(authResult));
    if (authResult.success.count > 0) {
      const newList: any = dataGridData.map((postx: rowInterface) => {
        // 更新対象をキー(物件番号#集積番号#窓番#組み立てユニット)でフィルターする
        const updateTarget: any = authResult.success.records !== undefined && authResult.success.records.filter(value => {
          return value.id === postx.id
        })
        //更新対象が存在する場合、承認日、承認者日本語を更新する
        if (updateTarget.length) {    
          return {
            "id": postx.id,
            "rownum": postx.rownum,
            "BukkenNo": postx.BukkenNo, "Kojimei": postx.Kojimei, "SyusekiNo": postx.SyusekiNo,
            "Iro": postx.Iro,
            "KumiKahan": postx.KumiKahan,
            "Tonyubi": postx.Tonyubi,
            "Madoban": postx.Madoban,
            "KumitateUnit": postx.KumitateUnit,
            "Setsu": postx.Setsu, "Kanryosu": postx.Kanryosu,
            "CheckedDate": postx.CheckedDate, "QualityChecker_ja": postx.QualityChecker_ja,
            "FirstAuthorizedDate": authResult.authorizeType === 'FirstAuthorize' ? updateTarget[0].authorizedDate : postx.FirstAuthorizedDate,
            "FirstAuthorizer_ja": authResult.authorizeType === 'FirstAuthorize' ? updateTarget[0].authorizer_ja : postx.FirstAuthorizer_ja,
            "SecondAuthorizedDate": authResult.authorizeType === 'SecondAuthorize' ? updateTarget[0].authorizedDate : postx.SecondAuthorizedDate,
            "SecondAuthorizer_ja": authResult.authorizeType === 'SecondAuthorize' ? updateTarget[0].authorizer_ja : postx.SecondAuthorizer_ja,
            "ThirdAuthorizedDate": authResult.authorizeType === 'ThirdAuthorize' ? updateTarget[0].authorizedDate : postx.ThirdAuthorizedDate,
            "ThirdAuthorizer_ja": authResult.authorizeType === 'ThirdAuthorize' ? updateTarget[0].authorizer_ja : postx.ThirdAuthorizer_ja,
            "Kamikotei": postx.Kamikotei,
            "GKns01": postx.GKns01, "GKns02": postx.GKns02, "GKns03": postx.GKns03, "GKns04": postx.GKns04, "GKns05": postx.GKns05, "GKns06": postx.GKns06, "GKns07": postx.GKns07, "GKns08": postx.GKns08, "GKns09": postx.GKns09, "GKns10": postx.GKns10,
            "KKns01": postx.KKns01, "KKns02": postx.KKns02, "KKns03": postx.KKns03, "KKns04": postx.KKns04, "KKns05": postx.KKns05, "KKns06": postx.KKns06, "KKns07": postx.KKns07,
            "SKns01": postx.SKns01, "SKns02": postx.SKns02, "SKns03": postx.SKns03, "SKns04": postx.SKns04, "SKns05": postx.SKns05, "SKns06": postx.SKns06, "SKns07": postx.SKns07, "SKns08": postx.SKns08, "SKns09": postx.SKns09, "SKns10": postx.SKns10,
            "Yojo": postx.Yojo,
            "Hantei": postx.Hantei,
            "Biko": postx.Biko
          }
        } else {
          return postx
        }
      }
      );
      setDataGridData(newList)
    }
  }, [authResult]);

  //postが更新
  //datagridのrowsを表示、更新結果を管理するuseStateを初期化
  useEffect(() => {
    //datagridのrows用のuseStateをpostで書き換え
    setDataGridData(post);
    //更新結果を管理するuseStateを初期化
    setAuthResult({
      authorizeType: "",
      statusCode: 0,
      totalCount: 0,
      success: { count: 0 },
      fail: { count: 0 }
    })
  }, [post]);

  //datagridのチェックが更新
  //チェックされたデータがない場合にselectCheckをfalse、ある場合にtrueにする
  useEffect(() => {
    selectionModel.length === 0 ? setSelectCheck(false) : setSelectCheck(true)
  }, [selectionModel]);

  return (
    <GenericTemplate title="組立検査承認" >
      <Stack spacing={1} direction="column" >
        <Stack spacing={2} direction="row">
          <DesktopDatePickerCommon
            label="検査完了日"
            value={CheckedDate}
            onChange={(newDate: any) => { setCheckedDate(newDate); }}
          />
          <FromTextFieldBukkenNo
            onChange={(event) => setBukkenNo(event.target.value)}
          />
          <FromTextFieldSyusekiNo
            onChange={(event) => setSyusekiNo(event.target.value)}
          />
          {/* 検索・ボタン */}
          <ButtonSearch
            apiParam={apiGetParam}
            apiUrl={apiGetUrl}
            apiKey={apiKey}
            getPost={setPosts}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="select-label">表示対象</InputLabel>
            <Select
              labelId='select-label'
              id="simple-select-helper"
              value={selected}
              onChange={selectChange}
            >
              <MenuItem value={1}>全て</MenuItem>
              <MenuItem value={2}>第一承認-未</MenuItem>
              <MenuItem value={3}>第一承認-済</MenuItem>
              <MenuItem value={4}>第二承認-未</MenuItem>
              <MenuItem value={5}>第二承認-済</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={<Switch defaultChecked
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            } label="検査項目表示" />
          {/* 印刷・ボタン */}
          <div style={{ display: 'none' }}>
            {selectCheck ? (
              <ReportBa01R01 reportData={selectionModel} ref={componentRef} />
            ) : (
              <ReportBa01R01 reportData={dataGridData} ref={componentRef} />
            )}
          </div>
          <ButtonPrint
            printComponentRef={componentRef}
            documentTitle={DocTitle}
          />
        </Stack>

        {/* 承認者権限を保持しているユーザのみ表示されるstack */}
        {(Sagyoshien1stAuthorizer || Sagyoshien2ndAuthorizer || SagyoshienQaAuthorizer) &&
          <Stack spacing={2} direction="row">
            {Sagyoshien1stAuthorizer &&
              <Card sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ fontSize: 14 }} gutterBottom>
                    第一承認
                  </Typography>
                  {/* 第一承認・ボタン */}
                  <ButtonUpdate
                    apiParam={{ ...apiPutParam, authorizeType: 'FirstAuthorize' }}
                    apiUrl={apiPutUrl1}
                    apiKey={apiKey}
                    disabled={!Sagyoshien1stAuthorizer}
                    result={setAuthResult}
                    message="選択されたレコードを承認します"
                    name="承認"
                  />
                  {/* 第一承認取消・ボタン */}
                  <ButtonUpdate
                    apiParam={{ ...apiPutParam, authorizeType: 'FirstAuthorize' }}
                    apiUrl={apiPutUrl2}
                    apiKey={apiKey}
                    disabled={!Sagyoshien1stAuthorizer}
                    result={setAuthResult}
                    message="選択されたレコードの承認を取消します"
                    name="取消"
                  />
                </Box>
              </Card>
            }
            {Sagyoshien2ndAuthorizer &&
              <Card sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ fontSize: 14 }} gutterBottom>
                    第二承認
                  </Typography>
                  {/* 第二承認・ボタン */}
                  <ButtonUpdate
                    apiParam={{ ...apiPutParam, authorizeType: 'SecondAuthorize' }}
                    apiUrl={apiPutUrl1}
                    apiKey={apiKey}
                    disabled={!Sagyoshien2ndAuthorizer}
                    result={setAuthResult}
                    message="選択されたレコードを承認します"
                    name="承認"
                  />
                  {/* 第二承認取消・ボタン */}
                  <ButtonUpdate
                    apiParam={{ ...apiPutParam, authorizeType: 'SecondAuthorize' }}
                    apiUrl={apiPutUrl2}
                    apiKey={apiKey}
                    disabled={!Sagyoshien2ndAuthorizer}
                    result={setAuthResult}
                    message="選択されたレコードの承認を取消します"
                    name="取消"
                  />
                </Box>
              </Card>
            }
            {SagyoshienQaAuthorizer &&
              <Card sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ fontSize: 14 }} gutterBottom>
                    品質承認
                  </Typography>
                  {/* 品質承認検索・ボタン */}
                  <ButtonSearch
                    apiParam={apiGetParam}
                    apiUrl={apiPutUrl3}
                    apiKey={apiKey}
                    getPost={setPosts}
                  />
                  {/* 品質承認・ボタン */}
                  <ButtonUpdate
                    apiParam={{ ...apiPutParam, authorizeType: 'ThirdAuthorize' }}
                    apiUrl={apiPutUrl1}
                    apiKey={apiKey}
                    disabled={!SagyoshienQaAuthorizer}
                    result={setAuthResult}
                    message="選択されたレコードを承認します"
                    name="承認"
                  />
                  {/* 品質承認取消・ボタン */}
                  <ButtonUpdate
                    apiParam={{ ...apiPutParam, authorizeType: 'ThirdAuthorize' }}
                    apiUrl={apiPutUrl2}
                    apiKey={apiKey}
                    disabled={!SagyoshienQaAuthorizer}
                    result={setAuthResult}
                    message="選択されたレコードの承認を取消します"
                    name="取消"
                  />
                </Box>
              </Card>
            }

            <FormControlLabel
             control={<Switch defaultChecked
                checked={checked2}
                onChange={handleChange2}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              } label="承認対象のみ表示"
            />

            {/* 更新処理の成功時の場合 */}
            {authResult.statusCode === 200 && (
              <div>
                <Alert severity="info">
                  更新を完了しました({authResult.success.count}件)。
                </Alert>
              </div>
            )}
            {/* 更新処理が前の担当者(第二承認者なら第一承認者)が承認していない場合 */}
            {authResult.statusCode === 206 && (
              <div>
                <Alert severity="warning">
                  前の承認が未完了 or 承認済のレコードがあります。({authResult.fail.count}件)。結果を確認してください。
                </Alert>
              </div>
            )}
            {/* 更新処理の失敗時(400とか返ってきた場合)の場合 */}
            {authResult.statusCode !== 206 && authResult.statusCode !== 200 && authResult.statusCode !== 0 && (
              <div>
                <Alert severity="error">
                  更新に失敗しました。
                </Alert>
              </div>
            )}

          </Stack>
        }
        {/* DataGrid */}
        <div style={{ height: '100%', width: '100%', }}>
          <Box sx={{ height: 600, width: '100%' }}>
            <DataGrid
              columnVisibilityModel={{
                // Hide columns status and traderName, the other columns will remain visible
                Kamikotei: checked,
                SKns01: checked, SKns02: checked, SKns03: checked, SKns04: checked, SKns05: checked, SKns06: checked, SKns07: checked, SKns08: checked, SKns09: checked, SKns10: checked,
                GKns01: checked, GKns02: checked, GKns03: checked, GKns04: checked, GKns05: checked, GKns06: checked, GKns07: checked, GKns08: checked, GKns09: checked, GKns10: checked,
                KKns01: checked, KKns02: checked, KKns03: checked, KKns04: checked, KKns05: checked, KKns06: checked, KKns07: checked,
                Yojo: checked, Hantei: checked, Biko: checked,
              }}
              checkboxSelection
              density="compact"
              rows={dataGridData}
              columns={columns}
              rowsPerPageOptions={[20, 50, 100]}
              filterModel={{ items: filter }}
              onFilterModelChange={handleFilterModelChange}
              //onFilterModelChange={(newFilterModel) => setFilter(newFilterModel)}
              components={{
                Toolbar: CustomToolbar
              }}
              //チェックボックスにチェックをつけたものをステート変数に保持
              selectionModel={selectedIds}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              onSelectionModelChange={(RowId) => {
                // 選択された行を特定するための処理
                const selectedRowId = new Set(RowId);
                const selectedRows = dataGridData.filter((dataGridRow) =>
                  selectedRowId.has(dataGridRow.id)
                );
                setSelectionModel(selectedRows);
                setSelectedIds(RowId as string[])
              }}
            />
          </Box>
        </div>
      </Stack>
    </GenericTemplate>
  )
}

export default P01Ins001Ase001