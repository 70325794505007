import { Route, Routes } from 'react-router-dom';
import PrgKatazaiUkeire from '../../pages/aqua/P01Prg001Kata002';
import PrgSenbetuDaisyaSyuseki from '../../pages/aqua/P01Prg001Kata003';
import PrgSenbetuFusoku from '../../pages/aqua/P01Prg001Kata004';
import PrgHimakuSintyoku from '../../pages/aqua/P01Prg001Himaku001';
import PrgHdtdataDownload from '../../pages/aqua/P01Prg001Kata005';
import PrgBuhinAcceptance from '../../pages/aqua/P01Prg101Buhin001';
import PrgBuhinPayoutList from '../../pages/aqua/P01Prg101Buhin002';
import PrgBuhinHdtdataDownload2 from '../../pages/aqua/P01Prg101Buhin003';
import InsAseList from '../../pages/aqua/P01Ins001Ase001';
import InsAseQaList from '../../pages/aqua/P01Ins001Ase002';
import PutKuraiDetailsHistory from '../../pages/aqua/P01Put001Konpo001';
import PutKuraiDetailsByFactory from '../../pages/aqua/P01Put001Konpo002';
import PutNumberOfKDDelays from '../../pages/aqua/P01Put001Konpo003';
import PutPackingCompletionSearch from '../../pages/aqua/P01Put001Konpo005';
import PutKuraiCheckList from '../../pages/aqua/P01Put001St001';
import PutMikurairiList from '../../pages/aqua/P01Put001St002';

export default function RoutesAquq() {
  return (
    <Routes>
        <Route path="/aqua/PrgKatazaiUkeire" element={<PrgKatazaiUkeire/>} />
        <Route path="/aqua/PrgSenbetuDaisyaSyuseki" element={<PrgSenbetuDaisyaSyuseki/>} />
        <Route path="/aqua/PrgSenbetuFusoku" element={<PrgSenbetuFusoku/>} />
        <Route path="/aqua/PrgHimakuSintyoku" element={<PrgHimakuSintyoku/>} />
        <Route path="/aqua/PrgHdtdataDownload" element={<PrgHdtdataDownload/>} />
        <Route path="/aqua/PrgBuhinAcceptance" element={<PrgBuhinAcceptance/>} />
        <Route path="/aqua/PrgBuhinPayoutList" element={<PrgBuhinPayoutList/>} />
        <Route path="/aqua/PrgBuhinHdtdataDownload2" element={<PrgBuhinHdtdataDownload2/>} />
        <Route path="/aqua/InsAseQualityInspectionList" element={<InsAseList/>} />
        <Route path="/aqua/InsAseQaQualityInspectionList" element={<InsAseQaList/>} />
        <Route path="/aqua/PutKuraiDetailsHistory" element={<PutKuraiDetailsHistory/>} />
        <Route path="/aqua/PutKuraiDetailsByFactory" element={<PutKuraiDetailsByFactory/>} />
        <Route path="/aqua/PutNumberOfKDDelays" element={<PutNumberOfKDDelays/>} />
        <Route path="/aqua/PutPackingCompletionSearch" element={<PutPackingCompletionSearch/>} />
        <Route path="/aqua/PutKuraiCheckList" element={<PutKuraiCheckList/>} />
        <Route path="/aqua/PutMikurairiList" element={<PutMikurairiList/>} />
    </Routes>
    );
};