import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';

// ----------------------------------------------------
// MaterialUiのButton コンポーネント キャンセルボタン
// ----------------------------------------------------
type Props = {
    onClick?: (event: React.MouseEvent<HTMLInputElement>) => void
    name?: string
    message?: string
    disabled?: boolean
    onAction: Function    
    size?: any              //small,medium,large
}

const ButtonAction = (
    props: Props,
) => {

    //変数定義
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    //ボタン押下時の処理 
    const handleClick = (() => {
        if ((props.message === undefined) || (window.confirm(props.message))) {
            if (!loading) {
                setSuccess(false);
                setLoading(true);
                props.onAction();              
                setSuccess(true);
                setLoading(false);
            }
        }

    })

    //ボタンチェンジ関数
    const buttonSx = {
        '&:hover': {
        bgcolor: green[500],
        },
        '&:active': {
        bgcolor: green[800],
        }
    };


    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ m: 1, position: 'relative' }}>
                <Button variant="contained"
                    sx={buttonSx}
                    disabled={(loading || props.disabled)}
                    onClick={() => { handleClick(); }}
                    size={(props.size === undefined ? 'medium' : props.size)}
                >
                    {props.name}
                </Button>
                {loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </Box>
        </Box>
    )
}

export default ButtonAction;
