import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import Grid from '@mui/material/Grid';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { mainListItems, mainListAqua,mainListStls,mainListMonoss,mainListConfig,secondaryListItems } from "../components/ListSidebar";
import { Auth, Hub } from "aws-amplify";
import {
  AuthState,
  UI_AUTH_CHANNEL,
  AUTH_STATE_CHANGE_EVENT,
  AuthStateHandler,
} from '@aws-amplify/ui-components';
import {userAttributes} from '../App'
import {useContext} from 'react'

const drawerWidth = 240;

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" to="/">
        三協立山データ照会システム
      </Link>{" "}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

export interface GenericTemplateProps {
  children: React.ReactNode;
  title: string;
}

const mdTheme = createTheme();

const GenericTemplate: React.FC<GenericTemplateProps> = ({
  children,
  title,
}) => {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const userAtt = useContext(userAttributes)
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar 
            variant="dense"
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ 
                flexGrow: 1 }}
              style={{fontSize: '1em'}} 
            >
              三協立山データ照会システム
            </Typography>
            {(userAtt as any).companyNameJa}
            {" "}
            {(userAtt as any).userNameJa}
            {" "}
            <button onClick={() => Auth.signOut()}>サインアウト</button>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            variant="dense"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            {(userAtt as any).userServices !== undefined && 
             (userAtt as any).userServices.indexOf('STINQAQUA') > -1 &&
              <div>
                {mainListAqua}
              </div>
            }
            {(userAtt as any).userServices !== undefined && 
             (userAtt as any).userServices.indexOf('STINQSTLS') > -1 &&
              <div>
                {mainListStls}
              </div>
            }
            {(userAtt as any).userServices !== undefined && 
             (userAtt as any).userServices.indexOf('STINQMONOSS') > -1 &&
              <div>
                {mainListMonoss}
              </div>
            }

            <Divider sx={{ my: 1 }} />
            {mainListConfig}
            {secondaryListItems}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth={false} sx={{ mt: 1, mb: 1 }}>
            <Grid container spacing={1}>
            <Typography
              component="h2"
              variant="h5"
              color="inherit"
              noWrap
            >
              {title}
            </Typography>
            </Grid>
            {children}
            <Box sx={{ pt: 1 }} >
              <Copyright />
            </Box>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default GenericTemplate;

export interface GenericTemplateProps {
  children: React.ReactNode;
  title: string;
}

export const dispatchAuthStateChangeEvent: AuthStateHandler = (
  nextAuthState: AuthState,
  data?: object
) => {
  Hub.dispatch(UI_AUTH_CHANNEL, {
    event: AUTH_STATE_CHANGE_EVENT,
    message: nextAuthState,
    data,
  });
};

