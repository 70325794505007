import React, { useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import axios from 'axios';
import requests from '../../utils/aqua/Request';
import { Auth } from 'aws-amplify';

// ----------------------------------------------------
// MaterialUiのSelect コンポーネント 在庫場所CDの一覧を表示する
// ----------------------------------------------------
const SelectZaikobasho = (
    {
        //呼出元にSelect で選択した値を返すためのイベントを定義する
        onChange = (event: any) => { onChange(event); }
    }
) => {
    //変数定義
    const [Zaikobasho, setZaikobasho] = React.useState('');
    const [ZaikobashoList, setZaikobashoList] = React.useState([]);

    //Select のMenuItem に追加する在庫場所CDの一覧を作成する関数
    const getZaikobashoList = ZaikobashoList.map((cat: any) => (
        <MenuItem value={cat.cdZaikobasho}>
            {cat.cdZaikobasho}
        </MenuItem>
    ))

    //Select変更時のイベント
    const handleChange = (event: SelectChangeEvent) => {
        setZaikobasho(event.target.value);
        onChange(event);
    };

    //WEBApiをコールして、在庫場所CDの一覧を取得する
    //初回だけ実行したいので第二引数は[]を追加
    useEffect(() => {
        const fetch = async () => {
            //requestを投げるURL作成
            const URLcell = process.env.REACT_APP_APIURL_AP00447 + requests.p01Mst001CDZaikobasho;
            const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
            axios.get(URLcell,
                {
                    headers: {
                        "X-API-KEY": process.env.REACT_APP_APIKEY_AP00447 || "",
                        "Authorization": AuthToken || "",
                    },
                }).then((res) => {
                    setZaikobashoList(res.data.result)
                }).catch(error => {
                    console.log(error);
                })
        }
        fetch()
    }, [])

    // 作成したselect をリターンする
    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="select-zaikobasyo">在庫場所</InputLabel>
            <Select
                labelId="select-zaikobasyo"
                id="select-zaikobasyo"
                value={Zaikobasho}
                label="Zaikobasyo"
                onChange={handleChange}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {getZaikobashoList}
            </Select>
        </FormControl>
    );
}

export default SelectZaikobasho