import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TopPage from '../pages/TopPage';
import AboutPage from '../pages/AboutPage';
import RotesAqua from './routes/RoutesAqua'
import RotesStal from './routes/RoutesStls'
import RotesMonoss from './routes/RoutesMonoss'
import RotesConfig from './routes/RoutesConfig'

export default function CustomRouter() {
  return (
        <div>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<TopPage/>} />
              <Route path="/about" element={<AboutPage/>} />
            </Routes>
            {/*  AQUA の Routerコンポーネントを呼び出し */}
            <RotesAqua />
            {/* Stls の Routerコンポーネントを呼び出し */}
            <RotesStal />
            {/* monos生産 の Routerコンポーネントを呼び出し */}
            <RotesMonoss />
            {/* 設定 の Routerコンポーネントを呼び出し */}
            <RotesConfig />
          </BrowserRouter>
        </div>
    );
};
