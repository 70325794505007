import React, { useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import requests from '../../utils/stls/Request';
import GenericTemplate from '../../templates/GenericTemplate';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import MUIDataTable from "mui-datatables";
import FromTextFieldHinmokuNo from '../../components/TextField/TextFieldHinmokuCd';
import { chkHalfWidth, chkEmpty } from '../../common/chkTextValue';
import {Auth} from 'aws-amplify';

const P01Prg001Kata001: React.FC = () => {
  //変数定義
  const [post, setPosts] = useState([])
  //品目番号
  const [hinban, setHinban] = useState('');
  const [hinbanError, setHinbanError] = useState('');

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  //requests.p01Mst001Itemにリクエストする関数。
  const fetchRequest = async() => {
    setPosts([])

    //requestを投げるURL作成
    const URL = process.env.REACT_APP_APIURL_STLS + requests.p01Mst001Item + '/' + hinban
    const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    axios.get(URL,
      {
        headers: {
          "X-API-KEY": process.env.REACT_APP_APIKEY_STLS || "",
          "Authorization": AuthToken || "",
        },
      }).then((res) => {
        //成功したら Posts に result を格納。
        setPosts(res.data.result)
        if (res.data.result.length === 0) {
          alert('データはありません')
        }
        console.debug(res.data.result)
      }).catch(error => {
        console.log(error);
        alert('検索に失敗しました')
      }).finally(() => {
        setLoading(false);
      });
  };

  //検索・ボタン押下時の処理 
  const handleClick = (() => {
    let errMsg = ""
    //未入力チェック
    errMsg = chkEmpty(hinban)
    if (errMsg.length > 0) {
      setHinbanError(errMsg)
      alert('検索条件を入力してください');
      return;
    }
    //半角文字チェック 
    errMsg = chkHalfWidth(hinban)
    if (errMsg.length > 0) {
      setHinbanError(errMsg)
      alert(errMsg);
      return;
    }

    if (!loading) {
      setSuccess(false);
      setLoading(true);
      //apiコールする関数を呼び出す
      fetchRequest()
      setSuccess(true);
    }
  })

  //入力値チェック処理：品目番号
  const chkHinban = (event: any) => {
    let val = event.target.value
    setHinbanError(chkHalfWidth(val))
    setHinban(val)
  }

  const column = [
    { name: "item", label: "品目",
      options: {
        setCellProps: () => ({ style: { position: "sticky", left: 0, background: "white", zIndex: 100 } }),
        setCellHeaderProps: () => ({ style: { position: "sticky", left: 0, background: "white", zIndex: 101 } })
      }
    },
    { name: "item_description", label: "品名", },
    { name: "ways", label: "勝手", },
    { name: "color", label: "色", },
    { name: "finish", label: "仕上げ", },
    { name: "material", label: "材質", },
    { name: "cost_cla", label: "原価分類", },
    { name: "item_cla", label: "品目分類", },
    { name: "qty_per_carton", label: "入数",
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "order_min", label: "最低発注数",       
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "lead_time", label: "リードタイム",       
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "register_date", label: "登録日", },
    { name: "update_date", label: "更新日", },
    { name: "abolish_date", label: "廃止日", },
    { name: "last_set_date", label: "最終設定日", },
    { name: "commodity_code", label: "商品コード", },
    { name: "old_parts", label: "旧部品番号", },
    { name: "stock_process_mark", label: "在庫処理マーク", },
    { name: "stock_div", label: "在庫区分",
      options: {
        hint: ('０：工場在庫品 １：センタ在庫品 ２：特注品 ３：組立品 ９：その他')
      }
    },
    {name: "attachment_div", label: "取付区分",
      options: {
        hint: ('１：取取 ２：取同 ８：取別 ９：別別')
      }
    },
    { name: "management_cla", label: "管理分類", },
    { name: "specification_div", label: "仕様区分",
      options: {
        hint: ('△：固定 １：変動')
      }
    },
    { name: "price_calc_div", label: "価格計算区分",
      options: {
        hint: ('△：固定 １：変動')
      }
    },
    { name: "configuration_data", label: "構成データ区分", },
    { name: "price_specification", label: "価格明細区分" },
    { name: "main_series", label: "主シリーズ", },
    { name: "strength", label: "強度", },
    { name: "window_type", label: "窓種", },
    { name: "product_cla", label: "製品分類", },
    { name: "item_kanji", label: "品名（漢字）", },
    { name: "weight", label: "単重",       
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "weight_units", label: "重量単位", },
    { name: "weight_formula", label: "単重式", },
    { name: "supply_data1_price", label: "支給データ１　価格",
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "supply_data1_start_date", label: "支給データ１　開始日", },
    { name: "dimension_LW", label: "寸法　Ｗ　ｏｒ　Ｌ", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "dimension_H", label: "寸法　Ｈ", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "dimension_T", label: "厚さ　Ｔ", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "dimension_LL", label: "コイル長　ＬＬ", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "standard_symbol_1", label: "規格１ 記号", },
    { name: "standard_value_1", label: "規格１ 値", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "standard_symbol_2", label: "規格２ 記号", },
    { name: "standard_value_2", label: "規格２ 値", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "standard_symbol_3", label: "規格３ 記号", },
    { name: "standard_value_3", label: "規格３ 値", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "standard_symbol_4", label: "規格４ 記号", },
    { name: "standard_value_4", label: "規格４ 値", 
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },
    { name: "standard_symbol_5", label: "規格５ 記号", },
    { name: "standard_value_5", label: "規格５ 値",       
      options: {
        setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
        setCellProps: () => ({align: "right"})
      }
    },

    // { name: "item_type", label: "品目タイプ", },
    // { name: "classification_code", label: "分類コード", },
    // { name: "stock_valuation_div", label: "在庫評価区分",
    //   options: {
    //     hint: ('△：最終仕入単価 １：在庫評価単価')
    //   }
    // },
    // { name: "payment_div", label: "支払区分", },
    // { name: "item_specification", label: "品目仕様", },
    // { name: "mold", label: "金型", },
    // { name: "price_calc_cla", label: "価格計算分類", },
    // { name: "estimated_purchase_qty", label: "仕入見込数", },
    // { name: "vendor_count", label: "仕入先数", },
    // { name: "safety_stock_number", label: "安全在庫", },
    // { name: "reserve1", label: "予備１", },
    // { name: "reserve2", label: "予備２", },
    // { name: "reserve3", label: "予備３", },
    // { name: "reserve4", label: "予備４", },
    // { name: "automatic_order", label: "自動発注", },
    // { name: "order_method", label: "発注方式", },
    // { name: "consumption_per_day", label: "日／消費", },
    // { name: "maximum_order", label: "最大発注量", },
    // { name: "natural_decrease_rate", label: "減耗率", },
    // { name: "calc_data_calc_date", label: "計算データ　計算日", },
    // { name: "calc_data_price", label: "計算データ　価格", },
    // { name: "standard_set_date", label: "基準　設定日", },
    // { name: "standard_price", label: "基準　価格", },
    // { name: "item_master_auto_generation", label: "品目マスタ自動作成", },
    // { name: "responsible_dept", label: "主管部門", },
    // { name: "stock_management_rank", label: "在庫管理ランク", },
    // { name: "maintenance_end_date", label: "メンテ終了日", },
    // { name: "terminal_id", label: "端末ＩＤ", },
    // { name: "reserve5", label: "予備５", },
    // { name: "text_code", label: "テキストコード", },
    // { name: "reserve0", label: "予備", },
    // { name: "bag_packing_factor", label: "袋詰係数", },
    // { name: "surface_proc_div", label: "表面処理区分", },
    // { name: "surface_area", label: "表面積", },
    // { name: "surface_proc_fee", label: "表面処理費", },
    // { name: "other_cost", label: "その他費用", },
    // { name: "other_cost2", label: "その他費用２", },
    // { name: "reserve", label: "予備", },
    // { name: "item_linkage_div", label: "項目連動方式区分", },
    // { name: "supply_data2_price", label: "支給データ２　価格",       
    //   options: {
    //     setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
    //     setCellProps: () => ({align: "right"})
    //   }
    // },
    // { name: "supply_data2_start_date", label: "支給データ２　開始日", },
    // { name: "lst_u_cost", label: "最終単価",      
    //   options: {
    //     setCellHeaderProps: () => ({ style: {textAlign:"right" } }),
    //     setCellProps: () => ({align: "right"})
    //   }
    // },
  ];

  return (
    <GenericTemplate title="">
      <Stack spacing={1} direction="column" >
        <Stack spacing={2} direction="row">
          {/* 品目番号 */}
          <FromTextFieldHinmokuNo onChange={chkHinban}
            helperText={hinbanError}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ m: 1, position: 'relative' }}>
              <Button variant="contained"
                sx={buttonSx}
                disabled={loading}
                onClick={() => { handleClick(); }}>
                検索
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
          </Box>
        </Stack>
        <div style={{ height: '50%', width: '100%' }}>
          <MUIDataTable
            title="品目(stls)"
            data={post}
            columns={column}
            options={{
              selectableRows: 'none',
              rowsPerPage: 20,
              viewColumns: false,
              rowsPerPageOptions: [20, 50, 100],
              sort: false,
              print: false,
              setTableProps: () => {
                return {
                  // padding: 'none',
                  size: "small",
                  style: { whiteSpace: "nowrap" },
                };
              },
            }}
          />
        </div>
      </Stack>
    </GenericTemplate>
  )
}

export default P01Prg001Kata001