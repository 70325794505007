import React, { useState, useRef } from 'react';
import axios from 'axios';
import GenericTemplate from '../../templates/GenericTemplate';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import requests from '../../utils/aqua/Request';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import FromTextFieldIraino from '../../components/TextField/TextFieldIraino';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { DataGrid, GridColDef, GridToolbar, GridRowParams, MuiEvent, GridPreProcessEditCellProps, } from '@mui/x-data-grid';
import {Auth} from 'aws-amplify';
//react-chartjs-2だけでは動かない
//フロントからモジュールの設定を別途入れる
import { Chart, registerables } from "chart.js";

const P01Prg001Himaku001: React.FC = () => {
  Chart.register(...registerables)
  //完了希望日更新用変数
  let preferrddate: string[][] = [];

  //変数定義
  //投入日(始点)
  const [starttndate, setstarttndate] = useState<Date | null>(null);
  //投入日(終点)
  const [endtndate, setendtndate] = useState<Date | null>(null);
  //納期(始点)
  const [startnouki, setstartnouki] = useState<Date | null>(null);
  //納期(終点)
  const [endnouki, setendnouki] = useState<Date | null>(null);
  //仕掛と完了を変更の管理用変数
  const [selectedValue, setSelectedValue] = React.useState('0');
  //依頼元を変更した際の管理用変数
  const [iraimotox, setiraimotox] = useState('');
  //依頼番号
  const [iraino, setiraino] = useState('');
  //検索ボタンを押下時のステータス管理用変数
  const [kensakuloading, setkensakuloading] = React.useState(false);
  //更新ボタンを押下時のステータス管理用変数
  const [kousinloading, setkousinloading] = React.useState(false);
  //getメソッドより受け取る変数(table用)
  const [post, setPosts] = useState<postArr[]>([]);
  //getgetメソッドより受け取る変数(graph用)
  const [postgraph, setPostgraph] = useState([]);
  //依頼元
  const iraimoto: string[] = ['G4', 'GG', 'UZ']
  //API_param用変数
  let stndate: any
  let etndate: any
  let snouki: any
  let enouki: any
  //グラフ依頼日用
  let graphiraibi: string = '';
  //DataGridフィルター
  const [filter, setFilter] = useState<any>([]);
  //完了希望日を1回の更新で2度更新した場合のフラグ用変数
  let preferrddateflg: string = '0';
  //積み上げグラフ用配列
  let datasets: string[] = [];
  //更新用APIへ渡すjson用変数
  let postdata: string ='';

  //getメソッドより受け取る変数の型定義
  type postArr = {
    renban: string,
    graphdate: string,
    sijiren: string,
    edaban: string,
    tndate: string,
    tntime: string,
    tnno: string,
    tykdate: string,
    irocode: string,
    nhimaku: string,
    ntomaku: string,
    tuya: string,
    ksample: string,
    teilot: string,
    lteisk: string,
    njytst: string,
    hmen: string,
    hmen2: string,
    kataban: string,
    wjyryo: string,
    atkotei: string,
    orack: string,
    firoaw: string,
    fsaisetu: string,
    cdat: string,
    kita: string,
    kitanai: string,
    koujimei: string,
    atuzan: string,
    ahazan: string,
    asaikbn: string,
    asaisuu: string,
    ttuzan: string,
    thazan: string,
    tsaikbn: string,
    tsaisuu: string,
    toroku: string,
    kousin: string,
    preferrddate: string,
    user: string,
    iraimoto: string
  };

  //検索ボタン押下時の関数 
  const handleClick = (() => {
    //依頼番号に何も入っていない場合
    if (iraino === '') {
      //依頼日と納期の始点が選択されていない場合
      if (starttndate === null) {
        if (startnouki === null) {
          alert('依頼日か納期の始点を選択して下さい。')
          return
        }
      }
      //依頼日終点が選択されていない場合
      if (starttndate !== null) {
        if (endtndate === null) {
          alert('依頼日の終点を選択して下さい。')
          return
        }
      }
      //納期終点が選択されていない場合
      if (startnouki !== null) {
        if (endnouki === null) {
          alert('納期の終点を選択して下さい。')
          return
        }
      }
    }

    if (!kensakuloading) {
      setkensakuloading(true);
      //apiコールする関数を呼出(table用)
      fetchRequest()
      //apiコールする関数を呼出(graph用)
      fetchRequest2()

    }
  })

  //requests.p01Prg001batchzailistにリクエストする関数。
  const fetchRequest = async () => {
    setPosts([])
    //requestを投げるURL作成
    const URL = process.env.REACT_APP_APIURL_AP00447 + requests.p01Prg001batchzailist
    const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
    if (starttndate === null) {
      stndate = null
    }　else {
      stndate = moment(starttndate).format("YYYY-MM-DD")
    }
    if (endtndate === null) {
      etndate = null
    }　else {
      etndate = moment(endtndate).format("YYYY-MM-DD")
    }
    if (startnouki === null) {
      snouki = null
    }　else {
      snouki = moment(startnouki).format("YYYY-MM-DD")
    }
    if (endnouki === null) {
      enouki = null
    }　else {
      enouki = moment(endnouki).format("YYYY-MM-DD")
    }
    axios.get(URL,
    {
      headers: {
        "X-API-KEY": process.env.REACT_APP_APIKEY_AP00447 || "",
        "Authorization": AuthToken || "",
      },
      params: {
        starttndate: stndate,
        endtndate: etndate,
        atkotei: iraimotox,
        startnouki: snouki,
        endnouki: enouki,
        iraino: iraino,
        sikakariflg: selectedValue,
      }
    }).then((res) => {
      // 成功したら Posts に result を格納。
      setPosts(res.data.result)
      console.log(res.data.result)
      //完了希望日更新用変数を初期化
      preferrddate = new Array()
    }).catch(error => {
      console.log(error);
      alert('エラーが発生しました。再度検索して下さい。');
    });
  };

  //requests.p01Prg001batchzaitotalにリクエストする関数。
  const fetchRequest2 = async() => {
    setPostgraph([])
    //requestを投げるURL作成
    const URL = process.env.REACT_APP_APIURL_AP00447 + requests.p01Prg001batchzaitotal
    const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()

    if (starttndate === null) {
      stndate = null
    } else {
      stndate = moment(starttndate).format("YYYY-MM-DD")
    }
    if (endtndate === null) {
      etndate = null
    } else {
      etndate = moment(endtndate).format("YYYY-MM-DD")
    }
    if (startnouki === null) {
      snouki = null
    } else {
      snouki = moment(startnouki).format("YYYY-MM-DD")
    }
    if (endnouki === null) {
      enouki = null
    } else {
      enouki = moment(endnouki).format("YYYY-MM-DD")
    }
    axios.get(URL,
    {
      headers: {
        "X-API-KEY": process.env.REACT_APP_APIKEY_AP00447 || "",
        "Authorization": AuthToken || "",
      },
      params: {
        starttndate: stndate,
        endtndate: etndate,
        atkotei: iraimotox,
        startnouki: snouki,
        endnouki: enouki,
        iraino: iraino,
        sikakariflg: selectedValue,
      }
    }).then((res) => {
      // 成功したら postgraph に result を格納。
      setPostgraph(res.data.result)
      console.log(res.data.result)
      if (res.data.result.length === 0) {
        alert('データはありません')
      }
    }).catch(error => {
      console.log(error);
      alert('エラーが発生しました。再度検索して下さい。');
    }).finally(() => {
      setkensakuloading(false);  
    });
  };

  //更新ボタン押下時の関数
  const handleClickPost =(() => {
    //チェックボックスにチェックがついていない場合
    if (preferrddate.length === 0) {
      alert('更新できるデータが存在しません。')
      return
    }

    //現在時刻を設定
    const now = new Date();
    const date = (now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate() + "-" + now.getHours() + "-" + now.getMinutes() + "-" + now.getSeconds());
    // 完了希望日更新用変数をjson形式にしてAPIに渡す
    preferrddate.map((cat: any, index) =>{
      //indexの0番目の時に[を追加
      if (index === 0) {
        postdata = '['
      }
      postdata += '{'
      postdata += '"sijiren"'
      postdata += ':'
      postdata += '"' + cat[0] + '",'
      postdata += '"edaban"'
      postdata += ':'
      postdata += '"' + cat[1] + '",'
      postdata += '"nouki"'
      postdata += ':'
      postdata += '"' + cat[2] + '",'
      postdata += '"updatedate"'
      postdata += ':'
      postdata += '"' + date + '"'
      postdata += '},'

      //indexの最後に]を追加
      if (preferrddate.length - 1 === index) {
        //末尾がカンマなので削除
        postdata = postdata.slice(0, -1)
        //カンマを削除後に]を追加
        postdata += ']'
      }
    })

    console.log(postdata)

    if (!kousinloading) {
      setkousinloading(true);
      //apiコールする関数を呼び出す
      fetchRequestpost()
    }
  })

  //requests.p01Prg001preferrddateにリクエストする関数。
  const fetchRequestpost = async() => {
    //requestを投げるURL作成
    const URL = process.env.REACT_APP_APIURL_AP00447 + requests.p01Prg001preferrddate
    const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
      //console.log(JSON.parse(postdata))
      axios.post(URL,
        {nouki: JSON.parse(postdata)},
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded" || "" ,
            "X-API-KEY": process.env.REACT_APP_APIKEY_AP00447 || "",
            "Authorization": AuthToken || "",
          },
        }).then((res) => {
          console.log(res)
          //完了希望日更新用変数を初期化
          preferrddate = new Array()
          alert('更新しました')
        }).catch(error => {
          console.log(error);
          console.log('エラー');
          alert('エラーが発生しました。再度更新して下さい。')
        }).finally(() => {
          setkousinloading(false);
        });
      };

  //依頼元一覧を取得
  const iraimotoList = iraimoto.map((value: any) => (
    <MenuItem key={value} value={value}>
      {value}
    </MenuItem>
  ))

  //依頼元内のセレクト値を取得する関数
  const kumitatekahanchange = (SelectChangeEvent: any) => {
    setiraimotox(SelectChangeEvent.target.value);
  };

  //ボタンチェンジ関数(検索用)
  const buttonchengekensaku = {
    '&:hover': {
      bgcolor: green[500],
    },
    '&:active': {
      bgcolor: green[800],
    }
  };

  //ボタンチェンジ関数(更新用)
  const buttonchengekousin = {
    '&:hover': {
      bgcolor: green[500],
    },
    '&:active': {
      bgcolor: green[800],
    }
  };

  //仕掛と完了を変更する関数
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  //グラフの日付を取得する関数
  const labels = post.map((cat) => {
    if (graphiraibi !== cat.graphdate) {
      graphiraibi = cat.graphdate
      return cat.graphdate
    }
    graphiraibi = cat.graphdate
    return
  }).filter(cat => cat)

  //グラフのデータを取得
  if (postgraph.length !== 0) {
    postgraph.map((cat: any) => {
      if(cat.atkotei === 'G4') {
        datasets[0] = cat.suryo
      } else if (cat.atkotei === 'GG') {
        datasets[1] = cat.suryo
      } else if (cat.atkotei === 'UZ') {
        datasets[2] = cat.suryo
      }
    })
  }

  const graphData = {
    labels,
    datasets: [
      {
        label:'安達工業',
        data: datasets[0],
        backgroundColor: "rgba(255,0,0,0.5)"
      },
      {
        label:'タケシタ',
        data: datasets[1],
        backgroundColor: "rgba(0,255,0,0.5)"
      },
      {
        label:'和宏金属',
        data: datasets[2],
        backgroundColor: "rgba(0,0,255,0.5)"
      },
    ],
  };

  const chartOption: {} = {
    scales: {
      xAxes: {
        stacked: true
      },
      yAxes: {
        stacked: true
      }
    },
  };

  const column: GridColDef[] = [
    { field: 'tndate', headerName: '依頼日', width: 95, sortable: false, disableColumnMenu: true },
    { field: 'graphdate', headerName: '納期', width: 105, sortable: false, disableColumnMenu: true },
    { field: 'tykdate', headerName: '納期(皮膜課)', width: 105, sortable: false, disableColumnMenu: true, },
    { field: '', headerName: '完了希望日(管理課)', editable: true, width: 150, sortable: false, disableColumnMenu: true,
      //日付を入力した際のバリデーション処理
      //形式:YYYY-MM-DD
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        let hasError = true
        //正規表現(YYYY-MM-DD)
        let pattern = /[12]\d{3}[-](0?[1-9]|1[0-2])[-](0?[1-9]|[12][0-9]|3[01])日?$/;
        if (pattern.test(params.props.value)) {
          hasError = false
        }
        if (params.props.value === '') {
          hasError = false
        }
        return { ...params.props, error: hasError };
      },
    },
    { field: 'iraimoto', headerName: '依頼元', width: 90, sortable: false, disableColumnMenu: true },
    { field: 'orack', headerName: '依頼番号', width: 105, sortable: false, disableColumnMenu: true },
    { field: 'koujimei', headerName: '工事名', width: 150, sortable: false, disableColumnMenu: true },
    { field: 'irocode', headerName: '色コード', width: 105, sortable: false, disableColumnMenu: true },
    { field: 'kitanai', headerName: '形/板', width: 60, sortable: false, disableColumnMenu: true },
    { field: 'njytst', headerName: '数量', width: 60, sortable: false, disableColumnMenu: true },
    { field: 'wjyryo', headerName: '重量', width: 90, sortable: false, disableColumnMenu: true },
    { field: 'hmen', headerName: '㎠', width: 90, sortable: false, disableColumnMenu: true },
    { field: 'atuzan', headerName: 'ア吊', width: 65, sortable: false, disableColumnMenu: true },
    { field: 'ahazan', headerName: 'ア外', width: 65, sortable: false, disableColumnMenu: true },
    { field: 'asaisuu', headerName: 'ア再生', width: 65, sortable: false, disableColumnMenu: true },
    { field: 'ttuzan', headerName: '塗吊', width: 65, sortable: false, disableColumnMenu: true },
    { field: 'thazan', headerName: '塗外', width: 65, sortable: false, disableColumnMenu: true },
    { field: 'tsaisuu', headerName: '塗再生', width: 65, sortable: false, disableColumnMenu: true },
    { field: 'kousin', headerName: '作業日', width: 170, sortable: false, disableColumnMenu: true },
  ];

  const chartRef = useRef();

  //グラフクリック時のイベント
  const tablefilter = (event: any) => {
    if (chartRef.current) {
      const chart = Chart.getChart(chartRef.current)
      if (chart) {
        //クリックした場所の日付を取得し、DataGridにフィルターをかける
        if (getElementAtEvent(chartRef.current, event)[0] === undefined) {
          setFilter([{
            columnField: '',
            operatorValue: '', 
            value: ''
          }])
        } else {
          setFilter([{
            columnField: 'graphdate',
            operatorValue: 'equals', 
            value: labels[getElementAtEvent(chartRef.current, event)[0].index]
          }])
        }
      }
    }
  }

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
  ) => {
    event.defaultMuiPrevented = true;
  };

  //完了希望日を更新した際の関数
  const changecell = (event: any) =>{
    //フラグを0にする
    preferrddateflg = '0'

    //完了希望日をコミットしたセルのレコードを取得
    const selectedRowId = new Set(event.id)
    const selectedRows = post.filter((dataGridRow) =>
            selectedRowId.has(dataGridRow.renban)
          );

    //完了希望日がある場合(複数回変更した場合と新規に更新した場合)
    if (event.value !== '') {
      //新規に更新した場合(そのまま更新日付管理用配列にpush)
      //一番初めの更新(全体で1回目)の場合
      if (preferrddate.length === 0) {
        preferrddate.push([selectedRows[0].sijiren, selectedRows[0].edaban, event.value])
      } else {
        preferrddate.map((cat: any, index) => {
          //指示連と枝番が同じ場合(同じ場合はevent.valueを更新、異なる場合は新規にpush)
          if (cat[0] === selectedRows[0].sijiren && cat[1] === selectedRows[0].edaban) {
            preferrddate[index][2] = event.value
            //map内で更新できた場合はフラグ
            preferrddateflg = '1'
          }
        })
        //map内で更新されなかった場合(新規に完了希望日を入力した場合)
        if (preferrddateflg === '0') {
          preferrddate.push([selectedRows[0].sijiren, selectedRows[0].edaban, event.value])
        }
      }
    //完了希望日が無い場合
    } else {
      preferrddate.map((cat: any, index) => {
        //指示連と枝番が同じ場合(同じ場合は日付を削除したのでレコードを削除、異なる場合は何もしなかった場合)
        if (cat[0] === selectedRows[0].sijiren && cat[1] === selectedRows[0].edaban) {
          preferrddate.splice(index, 1)
        }
      })
    }
    //console.log(preferrddate)
  }

  return (
    <GenericTemplate title="" >
      <Stack spacing={0} direction="column" >
        <Stack spacing={0.5} direction="row">
          {/* 依頼日(始点) */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="依頼日(始点)"
              inputFormat="yyyy-MM-dd"
              value={starttndate}
              mask=""
              onChange={(tndate) => {
                setstarttndate(tndate);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
          {/* - */}
          <div>
            <p style={{ textAlign: "center" }}> - </p>
          </div>
          {/* 依頼日(終点) */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="依頼日(終点)"
              inputFormat="yyyy-MM-dd"
              value={endtndate}
              mask=""
              onChange={(tndate) => {
                setendtndate(tndate);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
          {/* 納期(始点) */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="納期(始点)"
              inputFormat="yyyy-MM-dd"
              value={startnouki}
              mask=""
              onChange={(nouki) => {
                setstartnouki(nouki);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
          {/* - */}
          <div>
            <p style={{ textAlign: "center" }}> - </p>
          </div>
          {/* 納期(終点) */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="納期(終点)"
              inputFormat="yyyy-MM-dd"
              value={endnouki}
              mask=""
              onChange={(nouki) => {
                setendnouki(nouki);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </Stack>
        <Stack spacing={0.5} direction="row">
          <text style={{ fontSize: 11 }}>
            <Radio
              checked={selectedValue === '0'}
              onChange={handleChange}
              value="0"
              name="仕掛"
              inputProps={{ 'aria-label': '0' }}
            />
            仕掛
          </text>
          <text style={{ fontSize: 11 }}>
            <Radio
              checked={selectedValue === '1'}
              onChange={handleChange}
              value="1"
              name="完了"
              inputProps={{ 'aria-label': '1' }}
            />
            完了
          </text>
          {/* 依頼元 */}
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="select-label">依頼元</InputLabel>
            <Select
              labelId='select-label'
              id="demo-simple-select-helper"
              value={iraimotox}
              onChange={kumitatekahanchange}
            >
              <MenuItem value="">
                <em> &emsp; </em>
              </MenuItem>
              {iraimotoList}
            </Select>
          </FormControl>
          <FromTextFieldIraino onChange={(event) => setiraino(event.target.value)} />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ m: 1, position: 'relative' }}>
              <Button variant="contained"
                sx={buttonchengekensaku}
                disabled={kensakuloading || kousinloading}
                onClick={() => { handleClick(); }}>
                検索
              </Button>
              {kensakuloading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
            <Box sx={{ m: 1, position: 'relative' }}>
              <Button variant="contained"
                sx={buttonchengekousin}
                disabled={kensakuloading || kousinloading}
                onClick={() => { handleClickPost(); }}>
                更新
              </Button>
              {kousinloading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
          </Box>
        </Stack>
      </Stack>
      <div>
        <Bar 
          data={graphData}
          ref={chartRef}
          options={chartOption}
          height={66}
          onClick={(e) => { tablefilter(e); }}
        />
      </div>
      <div style={{ height: 280, width: '100%' }}>
        <DataGrid 
          rows={post}
          columns={column}
          getRowId={(row) => row.renban}
          density="compact"
          rowsPerPageOptions={[5]}
          filterModel={{items: filter}}
          components={{ Toolbar: GridToolbar }}
          onRowEditStart={handleRowEditStart}
          disableSelectionOnClick={true}
          onCellEditCommit={changecell}
        />
      </div>
    </GenericTemplate>
  )
}

export default P01Prg001Himaku001