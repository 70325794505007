import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import GenericTemplate from '../../templates/GenericTemplate';
import requests from '../../utils/aqua/Request';
import SelectLineCode from '../../components/Select/SelectLineCode';
import ButtonSearch from '../../components/Button/ButtonSearch';

interface infoInterface {
  kurabi: string;
  chuken: string;
  konpsu: string;
  tmstmp: string;
  dataList: rowInterface[];
}
interface rowInterface {
  id: number;
  chuban: string;
  kansua: string;
  kansu: string;
  konpsu: string;
  kanhzk: string;
  chokus: string;
  bskonpsu: string;
  bskurabi: string;
}
const columns: GridColDef[] = [
  { field: 'chuban', headerName: '注文番号', width: 120, },
  { field: 'kansua', headerName: '出荷済梱包数', width: 120, align: 'right', },
  { field: 'kansu', headerName: '今回出荷梱包数', width: 120, align: 'right', },
  { field: 'konpsu', headerName: '梱包数', width: 120, align: 'right', },
  { field: 'kanhzk', headerName: '梱包完了日', width: 110, },
  { field: 'chokus', headerName: '直送フラグ', width: 100, },
  { field: 'bskonpsu', headerName: 'bs梱包数', width: 120, align: 'right', },
  { field: 'bskurabi', headerName: 'bs指示日', width: 110, },
];

const P01Put001Konpo002: React.FC = () => {
  // *************
  // 変数定義
  // *************
  //検索結果を管理するuseState
  const [post, setPosts] = useState<infoInterface[]>([])

  // 検索条件をセットする変数
  const [lineCode, setLineCode] = useState(''); 
  const [kurabi, setKurabi] = useState(''); //倉入日
  const [chuken, setChuken] = useState(''); //件数
  const [konpsu, setKonpsu] = useState(''); //梱包数
  const [tmstmp, setTmstmp] = useState(''); //最終更新
  const [dataList, setDataList] = useState<rowInterface[]>([])

  // *************
  // 定数定義
  // *************
  // api 用のパラメータを設定する
  const apiKey = String(process.env.REACT_APP_APIKEY_AP00447)
  // APIのget 時のパラメータを指定
  const apiGetUrl = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Put001HistoryByFactory)
  const apiGetParam = {};

  // 検索データ編集
  useEffect(() => {
    if (post.length === 0) {
      setKurabi('')
      setChuken('')
      setKonpsu('')
      setTmstmp('')
      setDataList([])
    } else {
      setKurabi(post[0].kurabi)
      setChuken(post[0].chuken)
      setKonpsu(post[0].konpsu)
      setTmstmp(post[0].tmstmp)
      if (post[0].dataList === undefined || post[0].dataList.length === 0){
        setDataList([])
        alert('データはありません')
      } else {
        setDataList(post[0].dataList.map((dataRow: rowInterface, index: number) => {
          return {...dataRow, 
            id: index + 1,
          };
        }))
      }
    }
  }, [post]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport               
          csvOptions={{
          utf8WithBom: true,
        }}
      />
      </GridToolbarContainer>
    )
  }

  return (
    <GenericTemplate title="工場別倉入明細" >
      <Stack spacing={1} direction="column" >
        <Stack spacing={2} direction="row">
          <SelectLineCode 
            callerPath={requests.p01Put001CDLineDitails}
            onChange={(event) => setLineCode(event.target.value)} 
          />
          {/* 検索・ボタン */}
          <ButtonSearch
            apiParam={apiGetParam}
            apiUrl={apiGetUrl.replace('{cdline}', lineCode)}
            apiKey={apiKey}
            getPost={setPosts}
            disabled={lineCode===""}
          />
        </Stack>

        <Stack spacing={2} direction="row">
          <div>倉入日：{kurabi}</div>
          <div>件数：{chuken}</div>
          <div>梱包数：{konpsu}</div>
          <div>最終更新：{tmstmp}</div>
        </Stack>
        {/* DataGrid */}
        <div style={{ height: '100%', width: '100%', }}>
          <Box sx={{ height: 600, width: '100%' }}>
            <DataGrid
              density="compact"
              rows={dataList} 
              columns={columns}
              rowsPerPageOptions={[20, 50, 100]}
              components={{
                Toolbar: CustomToolbar
              }}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              showCellRightBorder
            />
          </Box>
        </div>
      </Stack>
    </GenericTemplate>
  )
}

export default P01Put001Konpo002