import React from 'react';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import FactoryIcon from '@mui/icons-material/Factory';
import { Link } from "react-router-dom";
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

// グローバル変数
let reversedArr: string[] = [];

export default function SidebarAp00447() {
  // イベントで利用する変数
  let selectnodeid: string[] = [];
  
  const nodeselect = (event: React.SyntheticEvent, nodeid: Array<string>) => {
    // ゲットしたnodeidを配列の変数へpush
    selectnodeid= nodeid
    // 配列の変数をmap関数で上下逆にする
    reversedArr = selectnodeid.map((_, i, a) => a[a.length - 1 - i])
  };

  return (
    <div>
    <TreeView
      aria-label="title"
      defaultCollapseIcon={<ExpandMoreIcon/>}
      defaultExpandIcon={<ChevronRightIcon/>}
      defaultParentIcon={<FactoryIcon/>}
      defaultEndIcon={<div style={{ width: 24 }} />}
      onNodeToggle={nodeselect}
      defaultExpanded={reversedArr}
    >
      <TreeItem nodeId="1" label="AQUA">
        {/* TreeItemに追加時はnodeIdを一意にする */}
        <TreeItem nodeId="2" label="進捗管理">
          <TreeItem nodeId="3" label="形材進捗">
            <List component="div" disablePadding>
              <Link to="/aqua/PrgKatazaiUkeire" style={{textDecoration: 'none' , color:'black' }}>
                <ListItemText primary="形材受入"  secondary="aa01"/>
              </Link>
              <Link to="/aqua/PrgSenbetuDaisyaSyuseki" style={{textDecoration: 'none' , color:'black' }}>
                <ListItemText primary="選別台車集計"  secondary="aa02"/>
              </Link>
              <Link to="/aqua/PrgSenbetuFusoku"　 style={{textDecoration: 'none' , color:'black' }}>
                <ListItemText primary="選別不足"  secondary="aa03"/>
              </Link>
            </List>
          </TreeItem>
          <TreeItem nodeId="4" label="皮膜進捗">
            <List component="div" disablePadding>
              <Link to="/aqua/PrgHimakuSintyoku" style={{textDecoration: 'none' , color:'black' }}>
                <ListItemText primary="皮膜進捗"  secondary="ab01"/>
              </Link>
            </List>
          </TreeItem>
          <TreeItem nodeId="5" label="部品進捗">
            <List component="div" disablePadding>
              <Link to="/aqua/PrgBuhinAcceptance" style={{textDecoration: 'none' , color:'black' }}>
                <ListItemText primary="補強材受入"  secondary="ac01"/>
              </Link>
              <Link to="/aqua/PrgBuhinPayoutList" style={{textDecoration: 'none' , color:'black' }}>
                <ListItemText primary="補強材払出"  secondary="ac02"/>
              </Link>
            </List>
          </TreeItem>
        </TreeItem>
        <TreeItem nodeId="10" label="品質管理">
          <TreeItem nodeId="11" label="組立">
            <List component="div" disablePadding>
              <Link to="/aqua/InsAseQualityInspectionList" style={{textDecoration: 'none' , color:'black' }}>
                <ListItemText primary="検査承認"  secondary="ba01"/>
              </Link>
              {/* REACT_APP_FEACHERFLAG_202309R091_01  */}
              {process.env.REACT_APP_FEACHERFLAG_202309R091_01 === "true" &&
              <Link to="/aqua/InsAseQaQualityInspectionList" style={{textDecoration: 'none' , color:'black' }}>
                <ListItemText primary="品質承認"  secondary="ba02"/>
              </Link>
              }
              </List>
          </TreeItem>
        </TreeItem>
        {/* REACT_APP_FEACHERFLAG_202308R046_01 */}
        {process.env.REACT_APP_FEACHERFLAG_202308R046_01 === "true" &&
        <TreeItem nodeId="20" label="倉入管理">
          <TreeItem nodeId="21" label="梱包倉入">
            <List component="div" disablePadding>
              <Link to="/aqua/PutKuraiDetailsHistory" style={{textDecoration: 'none' , color:'black' }}>
                <ListItemText primary="倉入明細受信履歴"  secondary="ca01"/>
              </Link>
              <Link to="/aqua/PutKuraiDetailsByFactory" style={{textDecoration: 'none' , color:'black' }}>
                <ListItemText primary="工場別倉入明細"  secondary="ca02"/>
              </Link>
              <Link to="/aqua/PutNumberOfKDDelays" style={{textDecoration: 'none' , color:'black' }}>
                <ListItemText primary="ＫＤ遅延件数"  secondary="ca03"/>
              </Link>
              <Link to="/aqua/PutPackingCompletionSearch" style={{textDecoration: 'none' , color:'black' }}>
                <ListItemText primary="梱包完了検索"  secondary="ca04"/>
              </Link>
            </List>
          </TreeItem>
          <TreeItem nodeId="22" label="ＳＴ物流">
            <List component="div" disablePadding>
              <Link to="/aqua/PutKuraiCheckList" style={{textDecoration: 'none' , color:'black' }}>
                <ListItemText primary="倉入チェックリスト"  secondary="cb01"/>
              </Link>
              <Link to="/aqua/PutMikurairiList" style={{textDecoration: 'none' , color:'black' }}>
                <ListItemText primary="未倉入一覧"  secondary="cb02"/>
              </Link>
            </List>
          </TreeItem>
        </TreeItem>
      }
      </TreeItem>
    </TreeView>
    </div>
  );
}