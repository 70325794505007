import React, { useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import axios from 'axios';
import { Auth } from 'aws-amplify';

// ----------------------------------------------------
// MaterialUiのSelect コンポーネント 生産ラインコードの一覧を表示する
// ----------------------------------------------------
const SelectLineCode = (
    {
        allFlg = false,
        callerPath = "",
        helperText = "",
        //呼出元にSelect で選択した値を返すためのイベントを定義する
        onChange = (event: any) => { onChange(event); }
    }
) => {
    //変数定義
    const [LineCode, setLineCode] = React.useState('');
    const [LineCodeList, setLineCodeList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    //Select のMenuItem に追加するコードの一覧を作成する関数
    const getLineCodeList = LineCodeList.map((cat: any) => (
        <MenuItem value={cat.lineCode}>
            {cat.lineCode} {cat.lineName}
        </MenuItem>
    ))

    //Select変更時のイベント
    const handleChange = (event: SelectChangeEvent) => {
        setLineCode(event.target.value);
        onChange(event);
    };

    //WEBApiをコールしてコードの一覧を取得する
    //初回だけ実行したいので第二引数は[]を追加
    useEffect(() => {
        console.debug(callerPath)

        setLoading(true);
        const fetch = async () => {
            //requestを投げるURL作成
            const URLcell = process.env.REACT_APP_APIURL_AP00447 + callerPath;
            console.debug('URLcell')
            console.debug(URLcell)
            const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
            axios.get(URLcell,
                {
                    headers: {
                        "X-API-KEY": process.env.REACT_APP_APIKEY_AP00447 || "",
                        "Authorization": AuthToken || "",
                    },
                }).then((res) => {
                    console.debug(res.data.result)
                    setLineCodeList(res.data.result)
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    setLoading(false);
                })
        }
        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // 作成したselect をリターンする
    return (
        <FormControl sx={{ minWidth: 200 }} size="small" error={!!helperText}>
            <InputLabel id="select-lineCode">ラインコード</InputLabel>
            <Select
                labelId="select-lineCode"
                id="select-lineCode"
                value={LineCode}
                label="LineCode"
                onChange={handleChange}
                disabled={loading}
            >
                {getLineCodeList}
                {allFlg && <MenuItem value={"A"}>A すべて</MenuItem>}
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
}

export default SelectLineCode