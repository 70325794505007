import { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import requests from '../../utils/aqua/Request';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ButtonCancel from '../../components/Button/ButtonAction';
import {Auth} from 'aws-amplify';
import axios from 'axios';
import { green, grey } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';

interface rowInterface {
  id: number;
  linecode: string;
  delaydays: string;
  chuban: string;
  tyonyubi: string;
  sijibi: string;
  cyclebi: string;
  kanrino: string;
  konposu: string;
  compsu: string;
  itemname: string;
  rank: string;
  chgdate: string;
  comment: string;
  commentupd: string;
}
const columns: GridColDef[] = [
  { field: 'linecode', headerName: '工場', width: 60, },
  { field: 'delaydays', headerName: '遅日数', width: 60, align: 'right', },
  { field: 'chuban', headerName: '注文番号', width: 120, },
  { field: 'tyonyubi', headerName: '投入日', width: 100, },
  { field: 'sijibi', headerName: '出荷指示日', width: 110, },
  { field: 'cyclebi', headerName: 'サイクル', width: 100, },
  { field: 'kanrino', headerName: '管理番号', width: 100, },
  { field: 'comp_pack', headerName: '完了／梱包', width: 110, align: 'right', },
  { field: 'itemname', headerName: '商品名', width: 220, },
  { field: 'rank', headerName: 'ランク', width: 80, },
  { field: 'chgdate', headerName: '変更日', width: 80, },
  { field: 'comment', headerName: 'コメント', width: 120, },
  { field: 'commentupd', headerName: 'コメント更新', width: 180, },
];

function P01Put001Konpo004(props: any){
  // *************
  // プロパティの受け取り
  // *************
  const {
    onClose,
    open,
    rowParam,
  } = props;

  // *************
  // 変数定義
  // *************
  //検索結果を管理するuseState
  const [post, setPosts] = useState<rowInterface[]>([])
  const [dataList, setDataList] = useState<rowInterface[]>([])
  // ダイアログ用のstate
  const [lineCode, setLineCode] = useState('');
  
  const [loading, setLoading] = useState(false);

  // *************
  // 定数定義
  // *************
  // api 用のパラメータを設定する
  const apiKey = String(process.env.REACT_APP_APIKEY_AP00447)
  // APIのget 時のパラメータを指定
  const apiGetUrl = String(process.env.REACT_APP_APIURL_AP00447 + requests.p01Put001DelayList)

  // 画面初期表示時
  useEffect(() => {
    setLineCode(rowParam)
    setDataList([])
    if (open === true) {
      // fetchRequest()
      const fetch = async () => {
        setLoading(true)
        // console.debug(apiGetUrl)
        //requestを投げるURL作成
        const AuthToken = (await Auth.currentSession()).getIdToken().getJwtToken()
        axios.get(apiGetUrl.replace('{cdline}', rowParam),
        {
          headers: {
            "X-API-KEY": apiKey,
            "Authorization": AuthToken || "",
          },
        }).then((res) => {
          // 成功したら result を格納。
          setPosts(res.data.result)
          console.log(res.data.result)
        }).catch(error => {
          console.log(error);
          alert('エラーが発生しました。再度検索して下さい。');
        }).finally(() => {
          setLoading(false);
        });
      }
      fetch()
  
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    setDataList(post.map((dataRow: rowInterface, index: number) => {
      return {...dataRow, 
        id: index + 1,
        comp_pack: (dataRow.compsu === null ? '0' : dataRow.compsu) + '/' + (dataRow.konposu === null ? '0' : dataRow.konposu),
      };
    }))
  }, [post]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport               
          csvOptions={{
          utf8WithBom: true,
        }}
      />
      </GridToolbarContainer>
    )
  }

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      fullWidth={true}>
      <DialogTitle>
          {<div>ＫＤ遅延リスト {lineCode}</div>}
      </DialogTitle>
      <DialogContent sx={{ bgcolor: loading?grey[300]:"-moz-initial" }} >
        <Box>
          <Stack spacing={1} direction="column" >
            <Stack spacing={2} direction="row">
              <Typography sx={{ fontSize: 14 }}>
                <div>ランク欄の凡例）Ａ：出荷指示日通り　Ｂ：投入日先行　Ｄ：工程遅れ　Ｈ：形材　Ｉ：部品　Ｋ：工図処理あり　Ｍ：路線変更（新湊）　Ｑ：その他</div>
              </Typography>
            </Stack>
            <div style={{ height: 600, width: '100%', }}>
              <DataGrid
                density="compact"
                rows={dataList}
                columns={columns}
                rowsPerPageOptions={[20, 50, 100]}
                components={{
                  Toolbar: CustomToolbar
                }}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                showCellRightBorder
                />
              {loading && (
                <CircularProgress
                  size={50}
                  sx={{
                    color: green[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </div>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <ButtonCancel
            onAction={onClose}
            name="閉じる"
            />
      </DialogActions>
  </Dialog>
  )
}

export default P01Put001Konpo004